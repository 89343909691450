import * as React from "react";
import s from "./CorsoInfo.module.less";
import { Corso } from "../../API";
import CachedImage from "../CachedImage/CachedImage";
import { Button } from "antd";

interface IProps {
  corso: Corso;
  onEdit: () => void;
}

const CorsoInfo = ({ corso, onEdit }: IProps) => {
  const renderItem = (title: string, value: string) => {
    return (
      <div className={s.wrap}>
        <label>{title}</label>
        <span>{value}</span>
      </div>
    );
  };
  return (
    <div>
      <div
        className={"formRow"}
        style={{ overflow: "hidden", marginBottom: "3rem" }}
      >
        <div style={{ textAlign: "left", flex: 3.8 }}>
          <div className={"formRow"}>
            <div style={{ flex: 1 }}>{renderItem("ID", corso?.index + "")}</div>
            <div style={{ flex: 1 }}>
              {renderItem("TITOLO CORSO", corso?.titolo)}
            </div>
          </div>
          <div style={{ height: 20 }} />
          <div className={"formRow"}>
            <div style={{ flex: 1 }}>
              {renderItem("DESCRIZIONE", corso?.descrizione + "")}
            </div>
          </div>
        </div>
        <div style={{ flex: 2 }}>
          <CachedImage height={250} src={corso?.imgUrl!} />
        </div>
      </div>
      <div className={"formRow"}>
        <Button
          style={{ marginLeft: "auto" }}
          size={"large"}
          className={"ctaSolid"}
          type={"primary"}
          onClick={onEdit}
        >
          Modifica
        </Button>
      </div>
    </div>
  );
};

export default CorsoInfo;
