import * as React from "react";
import { PageHeader, Select } from "antd";
import BackIcon from "../../components/BackIcon/BackIcon";
import useCachedQuery from "../../hooks/useCachedQuery";
import useQuery from "../../hooks/useQuery";
import { Azienda, Corso, Utente, UtenteAttivita } from "../../API";
import { useState } from "react";
import AttivitaForm2 from "../../components/Forms/AttivitaForm/AttivitaForm2";
import AttivitaForm3 from "../../components/Forms/AttivitaForm/AttivitaForm3";
import AttivitaForm4 from "../../components/Forms/AttivitaForm/AttivitaForm4";

const AddAttivita4 = ({}) => {
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [azienda, setAzienda] = useState<string | undefined>(undefined);
  const { data } = useCachedQuery<Utente[]>("listUtentes", `get-utentes`);
  const corsi = useQuery<Corso[]>("listCorsos")?.data;
  const aziende = useQuery("listAziendas")?.data;

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={"Inserisci attività utente"}
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
      />

      <AttivitaForm4
        aziende={aziende}
        setUsername={setUsername}
        data={data}
        azienda={azienda}
        setAzienda={setAzienda}
        userId={username}
        corsi={corsi}
      />
    </div>
  );
};

export default AddAttivita4;
