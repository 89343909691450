import * as React from "react";
import { Button, Upload, UploadProps } from "antd";
import { UploadFile, UploadType } from "antd/es/upload/interface";
import s from "./ImageUploader.module.less";
import { UploadChangeParam } from "antd/lib/upload";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

interface IProps extends UploadProps {
  onFileUploaded: (f: UploadFile) => void;
  text?: string;
  extraStyles?: React.CSSProperties | undefined;
  type?: UploadType;
  initialImage?: string;
}

const ImageUploader = ({
  onFileUploaded,
  text,
  extraStyles,
  initialImage,
}: IProps) => {
  console.log({ initialImage });
  const initialImageMock: UploadFile<any>[] = initialImage
    ? [
        {
          uid: initialImage!,
          name: initialImage!,
          status: "done",
          url: initialImage!,
        },
      ]
    : [];
  const [fileList, setFileList] = useState<UploadFile<any>[]>(initialImageMock);

  useEffect(() => {
    if (initialImage) {
      setFileList(initialImageMock);
    }
  }, [initialImage]);

  const customRequest = ({ onSuccess }: any) => {
    onSuccess();
  };
  const onChange = (file: UploadChangeParam<UploadFile<any>>) => {
    if (file?.file) {
      onFileUploaded(file.file);
      setFileList(file.fileList);
    }
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow!.document.write(image.outerHTML);
  };

  return (
    <ImgCrop aspect={375 / 250} rotate>
      <Upload
        listType="picture-card"
        className={s.upload}
        customRequest={customRequest}
        onChange={onChange}
        fileList={fileList}
        onPreview={onPreview}
      >
        {fileList.length === 0 && (
          <div className={s.wrapper} style={extraStyles}>
            <div className={classNames(s.inner)}>
              <PlusCircleOutlined
                style={{
                  fontSize: "2.5rem",
                  color: "#1A1B25",
                  marginBottom: 10,
                }}
              />

              <span className={s.text}>
                Trascina {text || "Video"} o clicca per caricare dal computer
              </span>
            </div>
          </div>
        )}
      </Upload>
    </ImgCrop>
  );
};

export default ImageUploader;
