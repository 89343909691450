import * as React from "react";
import { Button, PageHeader, Select } from "antd";
import Search from "antd/es/input/Search";
import useCachedQuery from "../../hooks/useCachedQuery";
import { Azienda, Utente } from "../../API";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import UtentiTable from "../../components/Tables/UtentiTable";
import BottomBar from "../../components/BottomBar/BottomBar";
import BackIcon from "../../components/BackIcon/BackIcon";
import { SorterResult } from "antd/es/table/interface";
import { BarsOutlined } from "@ant-design/icons";

interface IProps {}

const AziendeUtenti = ({}: IProps) => {
  const [filter, setFilter] = useState("");
  const { editKey } = useParams();
  const { data, isLoading } = useCachedQuery(
    "listUtentes",
    `list-utentes-${editKey}`,
    {
      filter: {
        aziendaUsersId: { eq: editKey },
      },
      limit: 9999,
    }
  );
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Azienda>>({});

  const sorterOptions = [
    {
      label: "Utente A-Z",
      value: {
        columnKey: "nome",
        order: "ascend",
      },
    },
    {
      label: "Utente Z-A",
      value: {
        columnKey: "nome",
        order: "descend",
      },
    },
  ].map((item) => ({ ...item, value: JSON.stringify(item.value) }));

  const filteredData = React.useMemo(() => {
    if (filter?.length === 0 || !filter) {
      return data;
    }
    return (data || []).filter((item: Utente) => {
      return (
        item.username
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()) ||
        item.nome?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
        item.email?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      );
    });
  }, [filter, setFilter, data]);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Lista Utenti"
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
        extra={[
          <div className={"formRow"}>
            <Search
              placeholder={"Cerca Utente"}
              onChange={(a: any) => {
                console.log({ a });
                setFilter(a.target.value);
              }}
            />
            <Select
              suffixIcon={<BarsOutlined style={{ color: "white" }} />}
              placeholder={"Ordinamento"}
              onChange={(v: any) => {
                setSortedInfo(JSON.parse(v));
              }}
              options={sorterOptions}
            />
          </div>,
        ]}
      />
      <UtentiTable
        sortedInfo={sortedInfo}
        data={filteredData}
        isLoading={isLoading}
        type={"azienda"}
      />
      <BottomBar>
        <Link to={`/aziende/associa-corsi/${editKey}`}>
          <Button style={{ marginBottom: 0 }} type={"primary"} size={"large"}>
            Associa corsi
          </Button>
        </Link>
      </BottomBar>
    </div>
  );
};

export default AziendeUtenti;
