import * as React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import s from "./AddCorso.module.less";
import { Anchor, Button, PageHeader, Tabs, Tooltip } from "antd";
import BackIcon from "../../components/BackIcon/BackIcon";
import AddCorsoForm from "../../components/Forms/AddCorsoForm";
import { useState } from "react";
import CorsoInfo from "../../components/CorsoInfo/CorsoInfo";
import useCachedQuery from "../../hooks/useCachedQuery";
import { Corso, Lezione } from "../../API";
import LezioniTable from "../../components/Tables/LezioniTable";
import classNames from "classnames";
import AddLezioneModal from "../../components/AddLezioneModal/AddLezioneModal";
import { useLezione } from "../../utils/LezioneContext";
import { sendMutation, sendRequest } from "../../utils/sendRequest";
import { useRemoveModal } from "../../utils/RemoveModalContext";
import AddTestForm from "../../components/Forms/AddTestForm/AddTestForm";
interface IProps {}

const AddCorso = ({}: IProps) => {
  const { editKey } = useParams();
  const { data, isLoading, refetch } = useCachedQuery<Corso>(
    "getCorso",
    `getCorso-${editKey}`,
    { id: editKey },
    undefined,
    !!editKey
  );

  const refetchAll = () => {
    refetch();
  };

  const lezioni: Lezione[] = (data?.lezioni?.items as any) || [];

  const [edit, setEdit] = useState(!editKey);
  const [id, setId] = useState("");
  const removeModal = useRemoveModal();
  const { openModalEdit, openModalCreate, modalVisibile, closeModal } =
    useLezione();

  const navigate = useNavigate();
  const editLezione = (l: Lezione) => {
    console.log({ l, lezioni });
    openModalEdit(l);
  };

  const deleteLezione = (id: string) => {
    removeModal.openModal(
      async () => {
        await sendMutation("deleteLezione", {
          input: {
            id,
          },
        });
        removeModal.closeModal();
        refetchAll();
      },
      "la lezione?",
      "Elimina lezione"
    );
  };

  const abort = (editKeyPassed?: string) => {
    setEdit(false);
    if (editKeyPassed) {
      navigate(generatePath("/corsi/add/:editKey", { editKey: editKeyPassed }));
    } else if (!editKey) {
      navigate(-1);
    }
    refetch();
  };

  const testInizialeTab = React.useMemo(() => {
    return (
      <AddTestForm
        refetch={refetch}
        initialValues={data?.testIniziale as any}
        iniziale={true}
      />
    );
  }, [data]);

  const testFinaleTab = React.useMemo(() => {
    return (
      <AddTestForm
        refetch={refetch}
        initialValues={data?.testFinale as any}
        iniziale={false}
      />
    );
  }, [data]);

  const corsoTab = React.useMemo(() => {
    return edit ? (
      <AddCorsoForm abort={abort} initialValues={data} />
    ) : (
      <CorsoInfo onEdit={() => setEdit(true)} corso={data!} />
    );
  }, [abort, data]);

  const lezioniTab = React.useMemo(() => {
    return (
      <div
        className={classNames(s.lezioniWrapper, lezioni?.length < 1 && s.empty)}
      >
        {lezioni?.length > 0 && (
          <LezioniTable
            data={lezioni.sort(
              (a, b) =>
                Number(a.index.split("-")[1]) - Number(b.index.split("-")[1])
            )}
            isLoading={isLoading}
            editLezione={editLezione}
            deleteLezione={deleteLezione}
          />
        )}
        {lezioni?.length < 1 && <p>Non sono ancora state caricate lezioni</p>}
        <Tooltip title={!editKey ? "Devi prima aggiungere un corso" : ""}>
          <Button
            onClick={() => openModalCreate()}
            disabled={!editKey}
            style={{ margin: 20 }}
            size={"large"}
            className={"solid"}
          >
            Aggiungi Lezione
          </Button>
        </Tooltip>
      </div>
    );
  }, [lezioni, isLoading]);

  return (
    <div id={"corso"} className={s.container}>
      <AddLezioneModal
        lezioni={lezioni}
        refetch={refetchAll}
        open={modalVisibile}
        closeModal={closeModal}
      />
      <PageHeader
        className="site-page-header"
        title="Inserisci nuovo corso"
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
      />
      <Tabs
        defaultActiveKey="1"
        tabPosition={"left"}
        items={[
          {
            label: `Corso`,
            key: "Corso",
            children: corsoTab,
          },
          {
            label: `Test iniziale`,
            key: "Test iniziale",
            children: testInizialeTab,
            disabled: !editKey,
          },
          {
            label: `Test finale`,
            key: "Test finale",
            children: testFinaleTab,
            disabled: !editKey,
          },
          {
            label: `Lezioni`,
            key: "Lezioni",
            children: lezioniTab,
          },
        ]}
      />
    </div>
  );
};

export default AddCorso;
