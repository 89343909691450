import * as React from "react";
import { Button, PageHeader, Select } from "antd";
import Search from "antd/es/input/Search";
import useCachedQuery from "../../hooks/useCachedQuery";
import { Azienda, Utente } from "../../API";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import UtentiTable from "../../components/Tables/UtentiTable";
import BottomBar from "../../components/BottomBar/BottomBar";
import BackIcon from "../../components/BackIcon/BackIcon";
import { SorterResult } from "antd/es/table/interface";
import { BarsOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

interface IProps {}

const CredenzialiUtenti = ({}: IProps) => {
  const [filter, setFilter] = useState("");
  const { editKey } = useParams();
  const azienda = useCachedQuery("getAzienda", "getAzienda-" + editKey, {
    id: editKey,
  });
  const { data, isLoading } = useCachedQuery(
    "listUtentes",
    `list-utentes-${editKey}`,
    {
      limit: 9999,
      filter: {
        aziendaUsersId: { eq: editKey },
      },
    }
  );
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Azienda>>({});

  const sorterOptions = [
    {
      label: "Utente A-Z",
      value: {
        columnKey: "nome",
        order: "ascend",
      },
    },
    {
      label: "Utente Z-A",
      value: {
        columnKey: "nome",
        order: "descend",
      },
    },
  ].map((item) => ({ ...item, value: JSON.stringify(item.value) }));

  const onExport = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#my-table" });
    doc.setFontSize(11);
    doc.setTextColor("#171821");

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    var pageSize = doc.internal.pageSize;
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    var text = doc.splitTextToSize("E-learning - Opinion", pageWidth - 35, {});
    doc.text(text, 14, 30);
    // Or use javascript directly:
    autoTable(doc, {
      headStyles: { fillColor: "#171821", textColor: "white" },
      startY: 40,
      head: [["Nome e cognome", "Username", "Password"]],
      body: (data as Utente[])
        .filter((utente) => utente.abilitato)
        .map((item) => [item.nome!, item.username!, item.password!]),
    });

    doc.save(`${azienda.data.ragioneSociale}-credenziali-utenti.pdf`);
  };

  const filteredData = React.useMemo(() => {
    if (filter?.length === 0 || !filter) {
      return data;
    }
    return (data || []).filter((item: Utente) => {
      return (
        item.username
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()) ||
        item.nome?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
        item.email?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      );
    });
  }, [filter, setFilter, data]);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Credenziali Utenti"
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
        extra={[
          <div className={"formRow"}>
            <Search
              placeholder={"Cerca Utente"}
              onChange={(a: any) => {
                console.log({ a });
                setFilter(a.target.value);
              }}
            />
            <Select
              suffixIcon={<BarsOutlined style={{ color: "white" }} />}
              placeholder={"Ordinamento"}
              onChange={(v: any) => {
                setSortedInfo(JSON.parse(v));
              }}
              options={sorterOptions}
            />
          </div>,
        ]}
      />
      <UtentiTable
        sortedInfo={sortedInfo}
        data={filteredData}
        isLoading={isLoading}
        type={"credenziali"}
      />
      <BottomBar>
        <Button
          onClick={onExport}
          style={{ marginBottom: 0 }}
          type={"primary"}
          size={"large"}
        >
          Export PDF
        </Button>
      </BottomBar>
    </div>
  );
};

export default CredenzialiUtenti;
