import * as React from "react";
import s from "./MobileCard.module.less";
import { Corso, UtenteTest } from "../../API";
import MobileCard, { detailRow } from "./MobileCard";
import useAllVideoProgress from "../../hooks/useAllVideoProgress";
import { Col, Row } from "antd";
import MobileButton from "../MobileButton/MobileButton";
import { renderCorsoAction } from "../Tables/UserCorsiTable";

interface IProps {
  corso: Corso;
  getCorsoProgress: (corsoId: string) => number;
  tests: UtenteTest[];
  openTestModal: (v: string) => void;
  openTestFinaleModal: (v: string) => void;
  openCertificatoModal: (v: string) => void;
}

const CorsoMobileCard = ({
  corso,
  getCorsoProgress,
  tests,
  openTestFinaleModal,
  openTestModal,
  openCertificatoModal,
}: IProps) => {
  const progress = getCorsoProgress(corso?.id);
  const progressString = !progress
    ? "Non Iniziato"
    : progress < 99
    ? "Iniziato"
    : "Completato";
  const hasCorsoTest = (id: string) => {
    return tests.find((item) => item?.corsoTestId === id)!;
  };
  return (
    <MobileCard>
      <div className={s.innerContainer}>
        {detailRow({ title: "nome corso", text: corso.titolo })}
        <div style={{ height: 10 }} />
        <Row>
          <Col flex={1}>
            {detailRow({ title: "completamento", text: progressString })}
          </Col>
          <Col flex={1}>
            {detailRow({
              title: "N° Lezioni",
              text: corso.lezioni?.items.length + "" || "N/A",
            })}
          </Col>
        </Row>
      </div>
      <MobileButton>
        {renderCorsoAction({
          record: corso,
          hasCorsoTest,
          getCorsoProgress,
          openTestModal,
          openTestFinaleModal,
          isMobile: true,
          openCertificatoModal,
        })}
      </MobileButton>
    </MobileCard>
  );
};

export default CorsoMobileCard;
