import * as React from "react";
import { useEffect, useState } from "react";
import { sendRequest } from "../../../utils/sendRequest";
import {
  Corso,
  Lezione,
  Domanda as IDomanda,
  DomandaInput,
} from "../../../API";
import { useParams } from "react-router-dom";
import Domanda from "./Domanda";
import s from "./AddTestForm.module.less";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

interface IProps {
  initialValues?: DomandaInput[];
  iniziale?: boolean;
  onAfterFinish?: () => void;
  refetch(): void;
}

const AddTestForm = ({ refetch, initialValues, iniziale }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { editKey } = useParams();
  const INITIAL_DOMANDE: DomandaInput[] = [0, 1, 2, 3, 4].map((number) => ({
    domanda: "",
    rispostaIndex: number,
    risposte: [],
    index: number,
  }));
  const [domande, setDomande] = useState<DomandaInput[]>(INITIAL_DOMANDE);

  useEffect(() => {
    if (initialValues?.length) {
      setDomande(initialValues.sort((a, b) => a.index - b.index));
    }
  }, [initialValues]);

  const getError = (v: DomandaInput) => {
    const risposteLength =
      v?.risposte?.map((r) => (r?.length || 0) > 0).length || 0;
    const rispostaAtIndex = v?.risposte?.[v?.rispostaIndex! - 1];
    if (!v?.domanda?.length) {
      return "La domanda è obbligatoria";
    }
    if (risposteLength <= 2) {
      return "almeno 3 risposte sono necessarie";
    }
    if (!((rispostaAtIndex?.length || 0) > 0)) {
      return "controlla che la risposta giusta sia stata inserita";
    }
    return undefined;
  };

  const domandeError = React.useMemo(
    () => domande.some((d) => (getError(d) || "").length > 0),
    [domande]
  );

  const updateDomanda = (index: number, domanda: DomandaInput) => {
    const newDomande: DomandaInput[] = [
      ...domande.filter((d) => d.index !== index),
      { ...domanda },
    ];
    setDomande(newDomande.sort((a, b) => a.index - b.index));
  };

  const addDomanda = () => {
    const newDomanda = {
      domanda: "",
      rispostaIndex: 0,
      risposte: [],
      index: domande.length,
    };
    const newDomande: DomandaInput[] = [...domande, { ...newDomanda }];
    setDomande(newDomande.sort((a, b) => a.index - b.index));
  };
  const onSubmit = async (data: any) => {
    console.log({ data });
    setSubmitted(true);
    if (domandeError) {
      return;
    }

    setLoading(true);
    const payload = iniziale
      ? { testIniziale: domande }
      : {
          testFinale: domande,
        };

    await sendRequest(
      "updateCorso",
      {
        input: {
          id: editKey,
          ...payload,
        },
      },
      true
    );

    refetch();

    setLoading(false);
  };

  const onRemove = (i: number) => {
    const newDomande: DomandaInput[] = [
      ...domande.filter((d) => d.index !== i),
    ];
    setDomande(
      newDomande
        .sort((a, b) => a.index - b.index)
        .map((item, index) => ({ ...item, index }))
    );
  };

  return (
    <div className={s.formContainer}>
      {domande.map((d) => (
        <Domanda
          updateDomanda={(partial) =>
            updateDomanda(d.index, {
              ...domande[d.index],
              ...partial,
            } as any)
          }
          domanda={domande[d.index]}
          title={`Domanda ${d.index + 1}`}
          error={submitted ? getError(domande[d.index]) : ""}
          onRemove={d.index > 4 ? () => onRemove(d.index) : undefined}
        />
      ))}
      <div className={s.addRow} onClick={addDomanda}>
        <span>Aggiungi Domanda </span>
        <PlusCircleOutlined />
      </div>
      <div
        className={"formRow"}
        style={{ justifyContent: "flex-end", marginBottom: 200, marginTop: 50 }}
      >
        <Button
          onClick={onSubmit}
          size={"large"}
          loading={loading}
          className={"ctaSolid"}
        >
          Salva
        </Button>
      </div>
    </div>
  );
};

export default AddTestForm;
