import * as React from "react";
import s from "./Test.module.less";
import { Domanda } from "../../API";
import Radio from "../Radio/Radio";
import { Checkbox } from "antd";
import classNames from "classnames";
import right from "./assets/right.svg";
import wrong from "./assets/wrong.svg";

interface IProps {
  show: boolean;
  domanda: Domanda;
  setRisposta: (i: number) => void;
  risposta?: number;
}

const DomandaTest = ({ show, domanda, setRisposta, risposta }: IProps) => {
  return (
    <div className={s.container}>
      <div className={classNames(s.center)}>
        <span className={"details"}> DOMANDA {domanda?.index + 1}</span>
        <p>{domanda?.domanda}</p>
      </div>
      <div className={s.risposte}>
        <div className={s.risposteWrapper}>
          {domanda?.risposte?.map((item, index) =>
            !show ? (
              <Checkbox
                checked={risposta === index}
                onChange={() => setRisposta(index)}
              >
                {item}
              </Checkbox>
            ) : (
              <div className={"formRow"}>
                <img
                  src={index + 1 === domanda?.rispostaIndex ? right : wrong}
                />
                <span>{item}</span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DomandaTest;
