/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUtenteAttivita = /* GraphQL */ `
  mutation CreateUtenteAttivita(
    $input: CreateUtenteAttivitaInput!
    $condition: ModelUtenteAttivitaConditionInput
  ) {
    createUtenteAttivita(input: $input, condition: $condition) {
      userId
      attivita {
        nome
        attivita
        durata
        data
        loginTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateUtenteAttivita = /* GraphQL */ `
  mutation UpdateUtenteAttivita(
    $input: UpdateUtenteAttivitaInput!
    $condition: ModelUtenteAttivitaConditionInput
  ) {
    updateUtenteAttivita(input: $input, condition: $condition) {
      userId
      attivita {
        nome
        attivita
        durata
        data
        loginTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteUtenteAttivita = /* GraphQL */ `
  mutation DeleteUtenteAttivita(
    $input: DeleteUtenteAttivitaInput!
    $condition: ModelUtenteAttivitaConditionInput
  ) {
    deleteUtenteAttivita(input: $input, condition: $condition) {
      userId
      attivita {
        nome
        attivita
        durata
        data
        loginTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createUtenteVideo = /* GraphQL */ `
  mutation CreateUtenteVideo(
    $input: CreateUtenteVideoInput!
    $condition: ModelUtenteVideoConditionInput
  ) {
    createUtenteVideo(input: $input, condition: $condition) {
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          users {
            items {
              email
              nome
              username
              password
              abilitato
              overrideCorsoProgress
              createdAt
              updatedAt
              aziendaUsersId
            }
            nextToken
          }
          corsi {
            items {
              id
              aziendaID
              corsoID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      lezioneId
      corsoId
      corsoTitle
      durations {
        realLoginTime
        loginTime
        duration
      }
      progress
      lastProgress
      utenteVideosId
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateUtenteVideo = /* GraphQL */ `
  mutation UpdateUtenteVideo(
    $input: UpdateUtenteVideoInput!
    $condition: ModelUtenteVideoConditionInput
  ) {
    updateUtenteVideo(input: $input, condition: $condition) {
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      lezioneId
      corsoId
      corsoTitle
      durations {
        realLoginTime
        loginTime
        duration
      }
      progress
      lastProgress
      utenteVideosId
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteUtenteVideo = /* GraphQL */ `
  mutation DeleteUtenteVideo(
    $input: DeleteUtenteVideoInput!
    $condition: ModelUtenteVideoConditionInput
  ) {
    deleteUtenteVideo(input: $input, condition: $condition) {
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      lezioneId
      corsoId
      corsoTitle
      durations {
        realLoginTime
        loginTime
        duration
      }
      progress
      lastProgress
      utenteVideosId
      id
      createdAt
      updatedAt
    }
  }
`;
export const createSito = /* GraphQL */ `
  mutation CreateSito(
    $input: CreateSitoInput!
    $condition: ModelSitoConditionInput
  ) {
    createSito(input: $input, condition: $condition) {
      nome
      imgUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateSito = /* GraphQL */ `
  mutation UpdateSito(
    $input: UpdateSitoInput!
    $condition: ModelSitoConditionInput
  ) {
    updateSito(input: $input, condition: $condition) {
      nome
      imgUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteSito = /* GraphQL */ `
  mutation DeleteSito(
    $input: DeleteSitoInput!
    $condition: ModelSitoConditionInput
  ) {
    deleteSito(input: $input, condition: $condition) {
      nome
      imgUrl
      createdAt
      updatedAt
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      username
      email
      nome
      password
      isSuper
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      username
      email
      nome
      password
      isSuper
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      username
      email
      nome
      password
      isSuper
      createdAt
      updatedAt
    }
  }
`;
export const createUtente = /* GraphQL */ `
  mutation CreateUtente(
    $input: CreateUtenteInput!
    $condition: ModelUtenteConditionInput
  ) {
    createUtente(input: $input, condition: $condition) {
      email
      nome
      username
      password
      abilitato
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
      }
      test {
        items {
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      videos {
        items {
          lezioneId
          corsoId
          corsoTitle
          progress
          lastProgress
          utenteVideosId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      corsi {
        items {
          id
          utenteID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      overrideCorsoProgress
      createdAt
      updatedAt
      aziendaUsersId
    }
  }
`;
export const updateUtente = /* GraphQL */ `
  mutation UpdateUtente(
    $input: UpdateUtenteInput!
    $condition: ModelUtenteConditionInput
  ) {
    updateUtente(input: $input, condition: $condition) {
      email
      nome
      username
      password
      abilitato
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
      }
      test {
        items {
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      videos {
        items {
          lezioneId
          corsoId
          corsoTitle
          progress
          lastProgress
          utenteVideosId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      corsi {
        items {
          id
          utenteID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      overrideCorsoProgress
      createdAt
      updatedAt
      aziendaUsersId
    }
  }
`;
export const deleteUtente = /* GraphQL */ `
  mutation DeleteUtente(
    $input: DeleteUtenteInput!
    $condition: ModelUtenteConditionInput
  ) {
    deleteUtente(input: $input, condition: $condition) {
      email
      nome
      username
      password
      abilitato
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
      }
      test {
        items {
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      videos {
        items {
          lezioneId
          corsoId
          corsoTitle
          progress
          lastProgress
          utenteVideosId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      corsi {
        items {
          id
          utenteID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      overrideCorsoProgress
      createdAt
      updatedAt
      aziendaUsersId
    }
  }
`;
export const createAzienda = /* GraphQL */ `
  mutation CreateAzienda(
    $input: CreateAziendaInput!
    $condition: ModelAziendaConditionInput
  ) {
    createAzienda(input: $input, condition: $condition) {
      id
      ragioneSociale
      indirizzo
      citta
      provincia
      cap
      partitaIva
      telefono
      email
      visura
      disattiva
      users {
        items {
          email
          nome
          username
          password
          abilitato
          overrideCorsoProgress
          createdAt
          updatedAt
          aziendaUsersId
        }
        nextToken
      }
      corsi {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAzienda = /* GraphQL */ `
  mutation UpdateAzienda(
    $input: UpdateAziendaInput!
    $condition: ModelAziendaConditionInput
  ) {
    updateAzienda(input: $input, condition: $condition) {
      id
      ragioneSociale
      indirizzo
      citta
      provincia
      cap
      partitaIva
      telefono
      email
      visura
      disattiva
      users {
        items {
          email
          nome
          username
          password
          abilitato
          overrideCorsoProgress
          createdAt
          updatedAt
          aziendaUsersId
        }
        nextToken
      }
      corsi {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAzienda = /* GraphQL */ `
  mutation DeleteAzienda(
    $input: DeleteAziendaInput!
    $condition: ModelAziendaConditionInput
  ) {
    deleteAzienda(input: $input, condition: $condition) {
      id
      ragioneSociale
      indirizzo
      citta
      provincia
      cap
      partitaIva
      telefono
      email
      visura
      disattiva
      users {
        items {
          email
          nome
          username
          password
          abilitato
          overrideCorsoProgress
          createdAt
          updatedAt
          aziendaUsersId
        }
        nextToken
      }
      corsi {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLezione = /* GraphQL */ `
  mutation CreateLezione(
    $input: CreateLezioneInput!
    $condition: ModelLezioneConditionInput
  ) {
    createLezione(input: $input, condition: $condition) {
      id
      index
      videoKey
      videoPlayBack
      slideKey
      titolo
      descrizione
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      corsoLezioniId
    }
  }
`;
export const updateLezione = /* GraphQL */ `
  mutation UpdateLezione(
    $input: UpdateLezioneInput!
    $condition: ModelLezioneConditionInput
  ) {
    updateLezione(input: $input, condition: $condition) {
      id
      index
      videoKey
      videoPlayBack
      slideKey
      titolo
      descrizione
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      corsoLezioniId
    }
  }
`;
export const deleteLezione = /* GraphQL */ `
  mutation DeleteLezione(
    $input: DeleteLezioneInput!
    $condition: ModelLezioneConditionInput
  ) {
    deleteLezione(input: $input, condition: $condition) {
      id
      index
      videoKey
      videoPlayBack
      slideKey
      titolo
      descrizione
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      corsoLezioniId
    }
  }
`;
export const createCorso = /* GraphQL */ `
  mutation CreateCorso(
    $input: CreateCorsoInput!
    $condition: ModelCorsoConditionInput
  ) {
    createCorso(input: $input, condition: $condition) {
      id
      index
      certificato
      titolo
      descrizione
      imgUrl
      testIniziale {
        index
        domanda
        rispostaIndex
        risposte
      }
      testFinale {
        index
        domanda
        rispostaIndex
        risposte
      }
      utenti {
        items {
          id
          utenteID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      lezioni {
        items {
          id
          index
          videoKey
          videoPlayBack
          slideKey
          titolo
          descrizione
          createdAt
          updatedAt
          corsoLezioniId
        }
        nextToken
      }
      test {
        items {
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      aziende {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCorso = /* GraphQL */ `
  mutation UpdateCorso(
    $input: UpdateCorsoInput!
    $condition: ModelCorsoConditionInput
  ) {
    updateCorso(input: $input, condition: $condition) {
      id
      index
      certificato
      titolo
      descrizione
      imgUrl
      testIniziale {
        index
        domanda
        rispostaIndex
        risposte
      }
      testFinale {
        index
        domanda
        rispostaIndex
        risposte
      }
      utenti {
        items {
          id
          utenteID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      lezioni {
        items {
          id
          index
          videoKey
          videoPlayBack
          slideKey
          titolo
          descrizione
          createdAt
          updatedAt
          corsoLezioniId
        }
        nextToken
      }
      test {
        items {
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      aziende {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCorso = /* GraphQL */ `
  mutation DeleteCorso(
    $input: DeleteCorsoInput!
    $condition: ModelCorsoConditionInput
  ) {
    deleteCorso(input: $input, condition: $condition) {
      id
      index
      certificato
      titolo
      descrizione
      imgUrl
      testIniziale {
        index
        domanda
        rispostaIndex
        risposte
      }
      testFinale {
        index
        domanda
        rispostaIndex
        risposte
      }
      utenti {
        items {
          id
          utenteID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      lezioni {
        items {
          id
          index
          videoKey
          videoPlayBack
          slideKey
          titolo
          descrizione
          createdAt
          updatedAt
          corsoLezioniId
        }
        nextToken
      }
      test {
        items {
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      aziende {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUtenteTest = /* GraphQL */ `
  mutation CreateUtenteTest(
    $input: CreateUtenteTestInput!
    $condition: ModelUtenteTestConditionInput
  ) {
    createUtenteTest(input: $input, condition: $condition) {
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      testIniziale
      testFinale
      id
      createdAt
      updatedAt
      utenteTestId
      corsoTestId
    }
  }
`;
export const updateUtenteTest = /* GraphQL */ `
  mutation UpdateUtenteTest(
    $input: UpdateUtenteTestInput!
    $condition: ModelUtenteTestConditionInput
  ) {
    updateUtenteTest(input: $input, condition: $condition) {
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      testIniziale
      testFinale
      id
      createdAt
      updatedAt
      utenteTestId
      corsoTestId
    }
  }
`;
export const deleteUtenteTest = /* GraphQL */ `
  mutation DeleteUtenteTest(
    $input: DeleteUtenteTestInput!
    $condition: ModelUtenteTestConditionInput
  ) {
    deleteUtenteTest(input: $input, condition: $condition) {
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      testIniziale
      testFinale
      id
      createdAt
      updatedAt
      utenteTestId
      corsoTestId
    }
  }
`;
export const createUtenteCorso = /* GraphQL */ `
  mutation CreateUtenteCorso(
    $input: CreateUtenteCorsoInput!
    $condition: ModelUtenteCorsoConditionInput
  ) {
    createUtenteCorso(input: $input, condition: $condition) {
      id
      utenteID
      corsoID
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUtenteCorso = /* GraphQL */ `
  mutation UpdateUtenteCorso(
    $input: UpdateUtenteCorsoInput!
    $condition: ModelUtenteCorsoConditionInput
  ) {
    updateUtenteCorso(input: $input, condition: $condition) {
      id
      utenteID
      corsoID
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUtenteCorso = /* GraphQL */ `
  mutation DeleteUtenteCorso(
    $input: DeleteUtenteCorsoInput!
    $condition: ModelUtenteCorsoConditionInput
  ) {
    deleteUtenteCorso(input: $input, condition: $condition) {
      id
      utenteID
      corsoID
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        overrideCorsoProgress
        createdAt
        updatedAt
        aziendaUsersId
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAziendeCorso = /* GraphQL */ `
  mutation CreateAziendeCorso(
    $input: CreateAziendeCorsoInput!
    $condition: ModelAziendeCorsoConditionInput
  ) {
    createAziendeCorso(input: $input, condition: $condition) {
      id
      aziendaID
      corsoID
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAziendeCorso = /* GraphQL */ `
  mutation UpdateAziendeCorso(
    $input: UpdateAziendeCorsoInput!
    $condition: ModelAziendeCorsoConditionInput
  ) {
    updateAziendeCorso(input: $input, condition: $condition) {
      id
      aziendaID
      corsoID
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAziendeCorso = /* GraphQL */ `
  mutation DeleteAziendeCorso(
    $input: DeleteAziendeCorsoInput!
    $condition: ModelAziendeCorsoConditionInput
  ) {
    deleteAziendeCorso(input: $input, condition: $condition) {
      id
      aziendaID
      corsoID
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
