import { notification } from "antd";

interface IProps {
  title: string;
  description: string;
  onClick?: () => any;
}

export const openNotification = ({ title, description, onClick }: IProps) => {
  notification.open({
    message: title,
    description,
    onClick,
  });
};

export const openNotificationError = ({
  title,
  description,
  onClick,
}: IProps) => {
  notification.error({
    message: title,
    description,
    onClick,
  });
};

export const openNotificationInfo = ({
  title,
  description,
  onClick,
}: IProps) => {
  notification.info({
    message: title,
    description,
    onClick,
  });
};
