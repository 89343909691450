import * as React from "react";
import { Modal } from "antd";
import AddLezioneForm from "../Forms/AddLezioneForm/AddLezioneForm";
import { Lezione } from "../../API";

interface IProps {
  open: boolean;
  closeModal(): void;
  refetch(): void;
  lezioni: Lezione[];
}

const AddLezioneModal = ({ open, closeModal, refetch, lezioni }: IProps) => {
  return (
    <Modal
      title="Nuova Lezione"
      centered
      width={1000}
      wrapClassName={"right"}
      open={open}
      onCancel={closeModal}
      footer={null}
    >
      <AddLezioneForm refetchCorso={refetch} lezioni={lezioni} />
    </Modal>
  );
};

export default AddLezioneModal;
