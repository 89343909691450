import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { SCENE_ROUTES } from "../../router/sceneRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo } from "./components/Logo";
import s from "./Sidebar.module.less";
import classNames from "classnames";
import { IRouteConfig } from "../../types/IRouteConfig";
import useAuthListener from "../../hooks/useAuthListener";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, user, role } = useAuthListener();

  const onClick = (e: any) => {
    navigate(e.key);
  };

  const sceneRoutes = SCENE_ROUTES(user.username).filter(
    (item) => item.allowedRoles?.includes(role) && !item.tabOnly
  );
  const renderItem = (item: IRouteConfig, index: number) => {
    const Icon = item.icon;
    const bottom = item.bottom ? (index + 1) * 45 - 30 : undefined;

    const selected =
      item.path === "/"
        ? location.pathname === "/"
        : location.pathname.startsWith(item.path);
    return (
      <Menu.Item
        onClick={item.key === "logout" ? logout : undefined}
        className={classNames(s.menuItem, selected && s.menuItemSelected)}
        style={
          bottom
            ? {
                bottom: bottom,
                position: "absolute",
                paddingLeft: item.key === "settings" ? 17 : 20,
                marginTop: 10,
              }
            : {}
        }
        key={item.path}
        icon={<Icon fill={selected ? "#EAEA4E" : "white"} />}
      >
        {item.label}
      </Menu.Item>
    );
  };

  return (
    <Sider
      className={classNames(role === "utente" && "utente")}
      style={{
        overflow: "hidden",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <Logo />
      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <div style={{ height: 112 }} />
        {sceneRoutes.filter((a) => !a.bottom).map(renderItem)}
        {sceneRoutes.filter((a) => a.bottom).map(renderItem)}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
