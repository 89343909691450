import * as React from "react";
import useQuery from "../../hooks/useQuery";
import { ColumnsType, ColumnType } from "antd/es/table";
import { Azienda, Corso, Utente } from "../../API";
import { Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import Icon from "antd/es/icon";
import { BarChartOutlined, EditOutlined } from "@ant-design/icons";
import useCachedQuery from "../../hooks/useCachedQuery";
import classNames from "classnames";
import { SorterResult } from "antd/es/table/interface";

interface IProps {
  data: Utente[];
  isLoading: boolean;
  type?: "azienda" | "credenziali";
  sortedInfo?: SorterResult<Azienda>;
}

const UtentiTable = ({ data, isLoading, sortedInfo, type }: IProps) => {
  const columns: ColumnsType<Utente> = [
    {
      title: "Nome e cognome",
      dataIndex: "nome",
      key: "nome",
      sortOrder: sortedInfo?.columnKey === "nome" ? sortedInfo.order : null,
      sorter: (a, b) => a.nome?.localeCompare(b.nome!) as any,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Azienda",
      dataIndex: "azienda",
      key: "azienda",
      sortOrder: sortedInfo?.columnKey === "azienda" ? sortedInfo.order : null,
      sorter: (a, b) =>
        a.azienda?.ragioneSociale?.localeCompare(
          b.azienda?.ragioneSociale!
        ) as any,
      render: (_, record) => <div>{record?.azienda?.ragioneSociale}</div>,
    },
    {
      title: "Corsi associati",
      dataIndex: "id",
      key: "corsi",
      render: (_, record) => <div>{record?.corsi?.items?.length}</div>,
    },
    {
      title: "Stato",
      dataIndex: "abilitato",
      key: "abilitato",
      render: (_, record) => (
        <div>{record?.abilitato ? "Attivo" : "Non Attivo"}</div>
      ),
    },

    {
      title: "Azioni",
      key: "action",
      render: (_, record) => (
        <div
          className={classNames("formRow", "icons")}
          style={{ alignItems: "center" }}
        >
          <Tooltip title={"Modifica Utente"}>
            <Link
              to={`${type === "azienda" ? "/aziende" : ""}/utenti/add/${
                record.username
              }`}
            >
              <EditOutlined style={{ color: "white" }} />
            </Link>
          </Tooltip>

          <Tooltip title={"Attivita Utente"}>
            <Link
              to={
                type === "azienda"
                  ? "/aziende/utenti/attivita/" + record.username
                  : "/utenti/attivita/" + record.username
              }
            >
              <BarChartOutlined style={{ color: "white" }} />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const filteredColumns = React.useMemo(() => {
    switch (type) {
      case "azienda":
        return columns.filter((item) => item.key !== "azienda");
        break;
      case "credenziali":
        return [
          ...columns.filter(
            (item) =>
              !["azienda", "action", "enabled", "corsi"].includes(
                item?.key as string
              )
          ),
          {
            title: "Password",
            dataIndex: "password",
            key: "password",
          },
        ];
        break;
      default:
        return columns;
    }
  }, [columns, type]);

  return (
    <div className={"innerContent"}>
      <Table loading={isLoading} dataSource={data} columns={filteredColumns} />
    </div>
  );
};

export default UtentiTable;
