import * as React from "react";
import { useEffect, useState } from "react";
import { sendRequest } from "../../../utils/sendRequest";
import { Button, Form, Input, Progress } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import Uploader from "../../Uploader/Uploader";
import { Corso, Lezione, Domanda as IDomanda } from "../../../API";
import TextArea from "antd/es/input/TextArea";
import useCachedQuery from "../../../hooks/useCachedQuery";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import s from "./AddLezioneForm.module.less";
import { useLezione } from "../../../utils/LezioneContext";
import { deleteFile, uploadFile } from "../../../utils/uploadFile";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import TooltipInput from "../../TooltipInput/TooltipInput";
import { GET_KEY_FROM_URL } from "../../../utils/constants";

interface IProps {
  initialValues?: Lezione;
  onAfterFinish?: () => void;
  refetchCorso(): void;
  lezioni: Lezione[];
}

const AddLezioneForm = ({ refetchCorso, lezioni }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { editKey } = useParams();
  const { updateLezioneValue, lezione, mode, closeModal, modalVisibile } =
    useLezione();
  const [videoSource, setVideoSource] = useState<UploadFile<any> | undefined>();
  const [videoProgress, setVideoProgress] = useState<number>(0);
  const [videoLock, setVideoLock] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const [videoResult, setVideoResult] = useState<any | undefined>();
  const [slideSource, setSlideSource] = useState<UploadFile<any> | undefined>();
  const [slideProgress, setSlideProgress] = useState<number>(0);
  const [slideLock, setSlideLock] = useState<boolean>(false);
  const [slideUrl, setSlideUrl] = useState<string | undefined>();
  const [slideResult, setSlideResult] = useState<any | undefined>();

  const corso = useCachedQuery("getCorso", `get-corso-${editKey}`, {
    id: editKey,
  });
  const data = lezioni;

  const initialId = React.useMemo(() => {
    if (!data || !corso) {
      return "";
    }
    const sortedData = data?.sort(
      (a, b) => Number(b.index.split("-")[1]) - Number(a.index.split("-")[1])
    );
    const highestID = (sortedData?.[0]?.index || "-0").split("-")[1];

    let number = Number(highestID);

    for (var i = 1; i < Number(highestID); i++) {
      const hasId = data?.some(
        (item) => Number((item.index || "-0").split("-")[1]) === i
      );
      if (!hasId) {
        number = i - 1;
      }
    }
    const nextNumber = number + 1;

    return corso?.data?.index + "-" + nextNumber;
    // 00100
  }, [data, modalVisibile, corso]);

  useEffect(() => {
    if (!lezione?.id) {
      updateLezioneValue("index", initialId + "");
    }
  }, [initialId, modalVisibile]);

  useEffect(() => {
    if (lezione.videoKey) {
      setVideoSource({ name: GET_KEY_FROM_URL(lezione.videoKey) } as any);
      setVideoResult(GET_KEY_FROM_URL(lezione.videoKey));
      setVideoProgress(100);
      setVideoUrl(lezione.videoKey);
    } else {
      setVideoSource(undefined);
      setVideoResult(undefined);
      setVideoProgress(0);
      setVideoUrl(undefined);
    }
    if (lezione?.slideKey) {
      setSlideSource({ name: GET_KEY_FROM_URL(lezione.slideKey) } as any);
      setSlideResult(GET_KEY_FROM_URL(lezione.slideKey));
      setSlideProgress(100);
      setSlideUrl(lezione.slideKey);
    } else {
      setSlideSource(undefined);
      setSlideResult(undefined);
      setSlideProgress(0);
      setSlideUrl(undefined);
    }
  }, [lezione]);

  const onVideoUploaded = async (f: UploadFile<any>) => {
    setVideoLock(true);
    if (!videoLock) {
      setVideoSource(f);

      const result = await uploadFile(
        f,
        f?.name,
        (progress) => {
          setVideoProgress((progress.loaded / progress.total) * 100);
        },
        lezione?.index || initialId
      );
      setVideoLock(false);
      setVideoUrl(result.url);
      setVideoResult(result.key);
    }
  };

  const onSlideUploaded = async (f: UploadFile) => {
    setSlideLock(true);
    if (!slideLock) {
      setSlideSource(f);

      const result = await uploadFile(
        f,
        f?.name,
        (progress) => {
          setSlideProgress((progress.loaded / progress.total) * 100);
        },
        lezione?.index || initialId
      );
      setSlideLock(false);
      setSlideUrl(result.url);
      setSlideResult(result.key);
    }
  };

  const onDeleteVideo = async () => {
    await deleteFile(videoResult);
    setVideoUrl("");
    setVideoProgress(0);
    setVideoSource(undefined);
    setVideoLock(false);
  };

  const previewVideo = async () => {
    videoUrl && window.open(videoUrl, "_blank");
  };

  const onDeleteSlide = async () => {
    await deleteFile(slideResult);
    setSlideUrl("");
    setSlideProgress(0);
    setSlideSource(undefined);
    setSlideLock(false);
  };

  const previewSlide = async () => {
    slideUrl && window.open(slideUrl, "_blank");
  };

  const onFinish = async (data: any) => {
    console.log({ data });
    setSubmitted(true);

    setLoading(true);
    const payload = {
      ...data,
      corsoLezioniId: editKey,
      slideKey: slideUrl,
      videoKey: videoUrl,
    };

    if (mode === "EDIT") {
      await sendRequest(
        "updateLezione",
        {
          input: { ...payload, id: lezione.id },
        },
        true
      );
    } else {
      await sendRequest(
        "createLezione",
        {
          input: payload,
        },
        true
      );
    }

    refetchCorso();
    closeModal();

    setLoading(false);
  };

  const blackListIds: string[] = React.useMemo(() => {
    return (data || []).map((item: Lezione) => item.index);
  }, [data]);

  const onFinishFailed = () => {};

  return (
    <Form
      layout={"vertical"}
      name="basic"
      key={JSON.stringify(lezione)}
      onFinish={onFinish}
      initialValues={lezione}
      onFinishFailed={onFinishFailed}
    >
      <div className={s.formContainer}>
        <div className={"formRow"}>
          <div style={{ flex: 3.8 }}>
            <div className={"formRow"}>
              <Form.Item
                style={{ flex: 1 }}
                name="index"
                initialValue={initialId}
                rules={[
                  { required: true, message: "Campo richiesto" },
                  {
                    message: "ID Già esistente",
                    validator: (_, value) => {
                      if (
                        blackListIds.includes(String(Number(value))) &&
                        mode === "CREATE"
                      ) {
                        return Promise.reject();
                      } else if (
                        blackListIds.includes(String(Number(value))) &&
                        mode === "EDIT" &&
                        Number(lezione.index) !== Number(value)
                      ) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <TooltipInput disabled={true} placeholder={"ID"} />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="titolo"
                rules={[{ required: false, message: "Campo richiesto" }]}
              >
                <TooltipInput placeholder="Titolo" />
              </Form.Item>
            </div>

            <div className={"formRow"}>
              <Form.Item
                style={{ flex: 1 }}
                name="descrizione"
                rules={[{ required: false, message: "Campo richiesto" }]}
              >
                <TextArea rows={5} placeholder="Descrizione" />
              </Form.Item>
            </div>
          </div>

          <div style={{ flex: 2 }}>
            <Uploader
              accept={"video/*"}
              text={"video"}
              extraStyles={{ height: 200, marginBottom: 20 }}
              onFileUploaded={onVideoUploaded}
            />
            <Uploader
              accept={".pdf"}
              text={"slide"}
              extraStyles={{ height: 200 }}
              onFileUploaded={onSlideUploaded}
            />

            {videoProgress !== 0 && (
              <div style={{ marginTop: 10 }}>
                <span>{videoSource?.name}</span>
                <div className={"formRow"}>
                  <Progress percent={Number(videoProgress.toFixed(0))} />
                  <DeleteOutlined onClick={() => onDeleteVideo()} />
                  {!!videoUrl && (
                    <EyeOutlined
                      disabled={!videoUrl}
                      onClick={() => previewVideo()}
                    />
                  )}
                </div>
              </div>
            )}
            {slideProgress !== 0 && (
              <div style={{ marginTop: 10 }}>
                <span>{slideSource?.name}</span>

                <div className={"formRow"}>
                  <Progress percent={Number(slideProgress.toFixed(0))} />
                  <DeleteOutlined onClick={() => onDeleteSlide()} />
                  {!!slideUrl && (
                    <EyeOutlined
                      disabled={!slideUrl}
                      onClick={() => previewSlide()}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classNames("formRow", s.footer)}>
        <Button onClick={closeModal} size={"large"} loading={loading}>
          Annulla
        </Button>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            type={"primary"}
            size={"large"}
            loading={loading}
          >
            Salva
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default AddLezioneForm;
