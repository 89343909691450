import * as React from "react";
import useCachedQuery from "./useCachedQuery";
import { Corso, Utente, UtenteVideo } from "../API";
import useQuery from "./useQuery";
import { groupBy } from "lodash";

interface IProps {
  username: string;
}

const useAllVideoProgress = ({ username }: IProps) => {
  const user = useQuery<Utente>(
    "getUtente",
    {
      username: username,
    },
    undefined,
    !!username
  );

  const videos = useQuery<UtenteVideo[]>(
    "utenteVideosByUsername",
    {
      limit: 999,
      utenteVideosId: username,
    },
    undefined,
    !!username
  );

  const allCorsi = useCachedQuery<Corso[]>("listCorsos", "list-corsos");

  const allUtenteCorsi = allCorsi.data?.filter((item) =>
    user.data?.corsi?.items.find((sub) => sub?.corsoID === item.id)
  );
  const videoProgress = React.useMemo(() => {
    console.log({ videos, length: videos?.data?.length, allUtenteCorsi });
    if (!videos?.data?.length) {
      return undefined;
    }

    return videos?.data?.filter((item) =>
      allUtenteCorsi?.find((sub) => sub.id === item?.corsoId)
    );
  }, [videos, allUtenteCorsi]);

  const getLezioneProgress = (lezioneId: string) => {
    const progress = videoProgress?.find(
      (item) => item!.lezioneId === lezioneId
    );
    if (user.data?.overrideCorsoProgress) {
      return 100 + "%";
    }
    return progress?.progress ? progress?.progress + "%" : 0;
  };

  const getCorsoProgress = (corsoId: string) => {
    if (!corsoId || !allUtenteCorsi?.length) {
      return 0;
    }
    const corso = (allUtenteCorsi || []).find((item) => item.id === corsoId);

    if (user.data?.overrideCorsoProgress) {
      return 100;
    }

    const progress = videoProgress
      ?.filter((item) => item?.corsoId === corsoId)
      .reduce((a, b) => a + b!.progress!, 0);

    const total = (corso?.lezioni?.items?.length || 0) * 100;
    return ((progress || 0) / total) * 100;
  };

  const corsoTitleById = (id: string) => {
    return allUtenteCorsi?.find((item: any) => item.id === id)?.titolo || "";
  };

  return {
    videoProgress,
    user,
    getCorsoProgress,
    corsoTitleById,
    getLezioneProgress,
  };
};

export default useAllVideoProgress;
