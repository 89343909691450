import * as React from "react";
import { Corso, Lezione } from "../../API";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import {
  DeleteOutlined,
  DesktopOutlined,
  EditOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import classNames from "classnames";
import useAllVideoProgress from "../../hooks/useAllVideoProgress";
import useAuthListener from "../../hooks/useAuthListener";
import useSmallScreen from "../../hooks/useSmallScreen";
import LezioneMobileCard from "../MobileCard/LezioneMobileCard";

interface IProps {
  data: Lezione[];
  isLoading?: boolean;
}

const ViewLezioniTable = ({ data, isLoading }: IProps) => {
  const { user } = useAuthListener();
  const { getLezioneProgress } = useAllVideoProgress({
    username: user.username,
  });

  const smallScreen = useSmallScreen();
  const columns: ColumnsType<Lezione> = [
    {
      title: "ID",
      dataIndex: "index",
      key: "id",
    },
    {
      title: "Titolo Lezione",
      dataIndex: "titolo",
      key: "titolo",
    },
    {
      title: "Completamento",
      dataIndex: "progress",
      key: "progress",
      render: (_, record) => (
        <div>{getLezioneProgress(record.id) || "Non iniziato"}</div>
      ),
    },
    {
      title: "Azioni",
      key: "action",
      render: (_, record) => (
        <div
          className={classNames("formRow", "icons")}
          style={{ alignItems: "center" }}
        >
          <Tooltip title={"Apri Lezione"}>
            <Link to={`/corsi/lezione/${record.id}`}>
              <PlayCircleOutlined style={{ color: "white" }} />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  if (smallScreen) {
    return (
      <div>
        {data.map((item) => (
          <LezioneMobileCard
            lezione={item}
            getLezioneProgress={getLezioneProgress}
          />
        ))}
      </div>
    );
  }
  return (
    <div className={"innerContent"}>
      <Table loading={isLoading} dataSource={data} columns={columns} />
    </div>
  );
};

export default ViewLezioniTable;
