import * as React from "react";
import useCachedQuery from "./useCachedQuery";
import { Utente, UtenteVideo } from "../API";
import useQuery from "./useQuery";

interface IProps {
  username: string;
  lezioneId: string;
}

const useVideoProgress = ({ username, lezioneId }: IProps) => {
  const user = useQuery<Utente>(
    "getUtente",
    {
      username: username,
    },
    undefined,
    !!username
  );

  const videos = useQuery<UtenteVideo[]>(
    "utenteVideosByUsername",
    {
      limit: 999,
      utenteVideosId: username,
    },
    undefined,
    !!username
  );

  const videoProgress = React.useMemo(() => {
    if (!videos?.data?.length!) {
      return undefined;
    }
    const found = (videos.data || []).find(
      (item) => item?.lezioneId === lezioneId
    );

    if (!found) {
      return undefined;
    }

    return found;
  }, [videos]);

  return { videoProgress, user, loading: videos.isLoading };
};

export default useVideoProgress;
