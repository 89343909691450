import * as React from "react";
import s from "./MobileButton.module.less";

interface IProps {
  children: any;
}

const MobileButton = ({ children }: IProps) => {
  return <div className={s.container}>{children}</div>;
};

export default MobileButton;
