import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import awsconfig from "../aws-exports";

type IAuthMode =
  | "API_KEY"
  | "AWS_IAM"
  | "OPENID_CONNECT"
  | "AMAZON_COGNITO_USER_POOLS"
  | "AWS_LAMBDA"
  | undefined;

const useQuery = <T = any>(
  query: string,
  variables?: object,
  onSuccess?: (data: T) => any,
  isAuto: boolean = true,
  authMode?: IAuthMode
) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>(null);
  const [refetchFlag, setRefetchFlag] = useState(false);
  const [enabled, setEnabled] = useState(isAuto);
  const refetch = () => {
    setRefetchFlag(!refetchFlag);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      console.log(query + " variables:", variables);
      const response: any = await API.graphql({
        // @ts-ignore
        query: queries[query],
        variables:{
          limit:999,
          ...variables,
        },
        authMode,
        authToken: awsconfig.aws_appsync_apiKey,
      });
      onSuccess?.(response.data[query].items ?? response.data[query]);
      setData(response.data[query].items ?? response.data[query]);
      setLoading(false);
      console.log(query, response.data[query]);
    } catch (e) {
      console.log("error", e);
      setError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enabled) {
      fetchData();
    } else {
      setEnabled(true);
    }
  }, [refetchFlag]);

  return { error, data, isLoading, refetch };
};

export default useQuery;
