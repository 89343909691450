/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://36hbtrlgene7jk5bgqmhnby7ta.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qkd6mivlmbfvfnyljxxkswq6x4",
    "aws_cognito_identity_pool_id": "eu-central-1:047b823b-943d-4552-b6e8-d7c5e67b2230",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_03d058IiA",
    "aws_user_pools_web_client_id": "7b7l0ptjprsp4i9tu0e005qt2b",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "elearning2d2c9c258725431dad74e726bbbe0390152556-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
