import { UploadFile } from "antd/es/upload/interface";
import { Storage } from "aws-amplify";
import { openNotificationError } from "./openNotification";
import { GET_FULL_URL } from "./constants";
import { nanoid } from "nanoid";

export const uploadFile = async (
  file: UploadFile,
  key: string,
  progressCallback?: (p: any) => void,
  overrideId?: string
) => {
  const shortid = overrideId || nanoid();
  const computedKey = `${shortid}-${key}`;
  try {
    const temp = await Storage.put(computedKey, file.originFileObj, {
      contentType: file.type,
      progressCallback,
    });
    return { url: GET_FULL_URL(computedKey), key: computedKey };
  } catch (error) {
    openNotificationError({
      title: "Errore con l'upload del file",
      description: JSON.stringify(error),
    });
    return { url: undefined, upload: undefined };
  }
};

export const deleteFile = async (key: string) => {
  try {
    const temp = await Storage.remove(key);
  } catch (error) {
    openNotificationError({
      title: "Errore con rimozione del file",
      description: JSON.stringify(error),
    });
  }
};
