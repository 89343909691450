import * as React from "react";
import s from "./Impostazioni.module.less";
import { Anchor, Button, PageHeader, Tabs, Tooltip } from "antd";
import useCachedQuery from "../../hooks/useCachedQuery";
import { sendMutation, sendRequest } from "../../utils/sendRequest";
import { useRemoveModal } from "../../utils/RemoveModalContext";
import useAuthListener from "../../hooks/useAuthListener";
import SiteInfo from "../../components/SiteInfo/SiteInfo";
import AdminTable from "../../components/Tables/AdminTable";
import BackIcon from "../../components/BackIcon/BackIcon";
import UtentiTable from "../../components/Tables/UtentiTable";
import BottomBar from "../../components/BottomBar/BottomBar";
import { Link } from "react-router-dom";
interface IProps {}

const Impostazioni = ({}: IProps) => {
  const { user } = useAuthListener();
  const username = user.username;
  const { data, isLoading, refetch } = useCachedQuery(
    "getAdmin",
    `getAdmin-${username}`,
    { username },
    undefined,
    !!username
  );

  const ruoliRequest = useCachedQuery("listAdmins", `listAdmins`);
  const ruoli = ruoliRequest?.data || [];

  const removeModal = useRemoveModal();

  const deleteAdmin = (username: string) => {
    removeModal.openModal(async () => {
      await sendMutation("deleteAdmin", {
        input: {
          username,
        },
      });
      removeModal.closeModal();
      refetch();
    }, "il ruolo");
  };

  const infoTab = React.useMemo(() => {
    return <SiteInfo />;
  }, [data]);

  const rolesTab = React.useMemo(() => {
    return <AdminTable data={ruoli} isLoading={isLoading} />;
  }, [data, isLoading]);

  return (
    <div className={s.container}>
      <PageHeader
        className="site-page-header"
        title="Impostazioni"
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
      />
      <Tabs
        defaultActiveKey="1"
        tabPosition={"left"}
        items={[
          {
            label: `Informazioni`,
            key: "Info",
            children: infoTab,
          },
          {
            label: `Ruoli`,
            key: "Roles",
            children: rolesTab,
          },
        ]}
      />

      <BottomBar absolute>
        <Link to={"/settings/admin/add"}>
          <Button style={{ marginBottom: 0 }} type={"primary"} size={"large"}>
            Inserisci Admin
          </Button>
        </Link>
      </BottomBar>
    </div>
  );
};

export default Impostazioni;
