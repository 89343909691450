import * as React from "react";
import { Button, DatePicker, Select, TimePicker } from "antd";
import { AttivitaItem, Corso } from "../../../API";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { Simulate } from "react-dom/test-utils";

interface IProps {
  corsi?: Corso[];
  attivita: AttivitaItem & { index: number };
  updateItem: (index: number, attivita: AttivitaItem) => void;
  onDelete?: (index: number) => void;
  error?: string;
}

const AttivitaFormInput = ({
  corsi,
  attivita,
  updateItem,
  onDelete,
  error,
}: IProps) => {
  return (
    <div key={attivita.index}>
      <div style={{ color: "red" }}>{error}</div>
      <div style={{ alignItems: "center" }} className={"formRow"}>
        <Select
          showSearch={true}
          style={{ width: "100%" }}
          size={"large"}
          options={(corsi || []).map((item) => ({
            label: item.titolo,
            value: item.titolo,
          }))}
          value={attivita.attivita}
          onChange={(value) =>
            updateItem(attivita.index, { ...attivita, attivita: value })
          }
          placeholder="Seleziona corso"
        />
        <DatePicker
          format={"DD/MM/YYYY HH:mm"}
          value={attivita?.data ? moment(attivita?.data) : undefined}
          style={{ width: "100%" }}
          placeholder={"Data inizio"}
          showTime={{ defaultValue: moment("12:00", "HH:mm") }}
          onChange={(date) => {
            console.log({ date });
            updateItem(attivita.index, {
              ...attivita,
              data: date?.toDate() as any,
            });
          }}
        />
        <TimePicker
          format={"HH:mm:ss"}
          style={{ width: "100%" }}
          placeholder={"Durata"}
          value={
            attivita?.durata ? moment(attivita?.durata, "HH:mm:ss") : undefined
          }
          onChange={(date) =>
            updateItem(attivita.index, {
              ...attivita,
              durata: moment(date).format("HH:mm:ss"),
            })
          }
        />
        {onDelete && (
          <DeleteOutlined
            onClick={() => onDelete(attivita.index)}
            style={{ color: "white", width: 20, fontSize: 22 }}
          />
        )}
      </div>
    </div>
  );
};

export default AttivitaFormInput;
