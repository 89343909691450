import * as React from "react";
import { Modal, ModalProps } from "antd";

interface IProps extends ModalProps {
  text?: string;
  title?: string;
}

const RemoveModal = ({ text, title, ...restProps }: IProps) => {
  return (
    <Modal {...restProps} title={title || "Sei sicuro?"}>
      <p>Sei sicuro di voler eliminare {text || "l'elemento"}</p>
    </Modal>
  );
};

export default RemoveModal;
