import * as React from "react";
import { Button, Upload, UploadProps } from "antd";
import {
  EditOutlined,
  FileAddOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { UploadFile, UploadType } from "antd/es/upload/interface";
import s from "./Uploader.module.less";
import { UploadChangeParam } from "antd/lib/upload";
import { useState } from "react";
import classNames from "classnames";
interface IProps extends UploadProps {
  onFileUploaded: (f: UploadFile) => void;
  text?: string;
  extraStyles?: React.CSSProperties | undefined;
  type?: UploadType;
}

const Uploader = ({ onFileUploaded, text, extraStyles, ...rest }: IProps) => {
  const [preview, setPreview] = useState<UploadFile<any> | undefined>();
  const customRequest = ({ onSuccess }: any) => {
    onSuccess();
  };
  const onChange = (file: UploadChangeParam<UploadFile<any>>) => {
    if (file?.file) {
      onFileUploaded(file.file);
      setPreview(file.file);
    }
  };

  return (
    <div>
      <Upload
        showUploadList={false}
        customRequest={customRequest}
        onChange={onChange}
        {...rest}
      >
        <div className={s.wrapper} style={extraStyles}>
          <div className={classNames(s.inner)}>
            <PlusCircleOutlined
              style={{
                fontSize: "2.5rem",
                color: "#1A1B25",
                marginBottom: 10,
              }}
            />
            <span className={s.text}>
              Trascina {text || "Video"} o clicca per caricare dal computer
            </span>
          </div>
        </div>
      </Upload>
    </div>
  );
};

export default Uploader;
