import { Image, Layout, Space } from "antd";
import logo from "../../../../assets/images/logo.png";
import s from "./Logo.module.less";

const Logo = () => {
  return (
    <div className={s.logo}>
      <Image width={140} src={logo} preview={false} />
    </div>
  );
};

export default Logo;
