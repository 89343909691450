import * as React from "react";
import { ColumnsType } from "antd/es/table";
import { Corso, UtenteTest } from "../../API";
import { Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { DesktopOutlined } from "@ant-design/icons";
import classNames from "classnames";
import achievement from "./assets/achievement.svg";
import s from "./Tables.module.less";
import useSmallScreen from "../../hooks/useSmallScreen";
import CorsoMobileCard from "../MobileCard/CorsoMobileCard";
interface IProps {
  data: Corso[];
  isLoading: boolean;
  tests: UtenteTest[];
  openTestModal: (v: string) => void;
  openTestFinaleModal: (v: string) => void;
  openCertificatoModal: (v: string) => void;
  getCorsoProgress: (v: string) => number;
  overrideCorsoProgress?: boolean;
}

interface IrenderCorsoAction {
  hasCorsoTest(id: string): UtenteTest;
  record: Corso;
  getCorsoProgress: (v: string) => number;
  openTestModal: (v: string) => void;
  openTestFinaleModal: (v: string) => void;
  openCertificatoModal: (v: string) => void;
  isMobile?: boolean;
  overrideCorsoProgress?: boolean;
}

export const renderCorsoAction = ({
  record,
  hasCorsoTest,
  getCorsoProgress,
  openTestModal,
  openTestFinaleModal,
  openCertificatoModal,
  isMobile,
  overrideCorsoProgress,
}: IrenderCorsoAction) => {
  const corsoTest = hasCorsoTest(record?.id);
  const hasTestIniziale = (record?.testIniziale?.length || 0) > 0;
  const hasTestFinale = (record?.testIniziale?.length || 0) > 0;
  const testInizialeDone = overrideCorsoProgress || corsoTest?.testIniziale;
  const testFinaleDone = corsoTest?.testFinale;
  const done = getCorsoProgress(record?.id) === 100;
  const apriCorsoInner = isMobile ? (
    <div
      style={{ alignItems: "center", display: "flex", height: 30, gridGap: 10 }}
    >
      <DesktopOutlined style={{ color: "#171821" }} />{" "}
      <span style={{ color: "#171821", fontWeight: 400, fontSize: 10 }}>
        LEZIONI
      </span>
    </div>
  ) : (
    <DesktopOutlined style={{ color: "white" }} />
  );

  return (
    <div
      className={classNames("formRow", "icons")}
      style={{ alignItems: "center" }}
    >
      <Tooltip title={"Apri corso"}>
        {testInizialeDone || !hasTestIniziale ? (
          <Link to={`/corsi/lezioni/${record?.id}`}>{apriCorsoInner}</Link>
        ) : (
          <div onClick={() => openTestModal(record?.id)}>{apriCorsoInner}</div>
        )}
      </Tooltip>
      {done && testFinaleDone && hasTestFinale && (
        <Tooltip title={"Scarica certificato"}>
          <div onClick={() => openCertificatoModal?.(record?.id)}>
            <img src={achievement} />
          </div>
        </Tooltip>
      )}
      {done && !testFinaleDone && hasTestFinale && (
        <div
          className={s.testFinale}
          onClick={() => openTestFinaleModal(record?.id)}
        >
          TEST FINALE
        </div>
      )}
    </div>
  );
};

const UserCorsiTable = ({
  data,
  isLoading,
  tests,
  openTestModal,
  openTestFinaleModal,
  getCorsoProgress,
  overrideCorsoProgress,
  openCertificatoModal,
}: IProps) => {
  const smallScreen = useSmallScreen();
  const hasCorsoTest = (id: string) => {
    return tests.find((item) => item?.corsoTestId === id)!;
  };

  const columns: ColumnsType<Corso> = [
    {
      title: "Titolo Corso",
      dataIndex: "titolo",
      key: "Titolo",
    },
    {
      title: "Numero Lezioni",
      dataIndex: "Titolo",
      key: "Titolo",
      render: (_, record) => <div>{(record?.lezioni?.items || []).length}</div>,
    },
    {
      title: "Stato",
      dataIndex: "Stato",
      key: "Stato",
      render: (_, record) => {
        const progress = getCorsoProgress(record?.id);
        const progressString = !progress
          ? "Non Iniziato"
          : progress < 99
          ? "Iniziato"
          : "Completato";
        return <div>{progressString}</div>;
      },
    },
    {
      title: "Azioni",
      key: "action",
      render: (_, record) =>
        renderCorsoAction({
          record,
          hasCorsoTest,
          getCorsoProgress,
          openTestModal,
          openTestFinaleModal,
          overrideCorsoProgress,
          openCertificatoModal,
        }),
    },
  ];

  if (smallScreen) {
    return (
      <div>
        {data
          .sort((a, b) => Number(a?.index) - Number(b?.index))
          .map((item) => (
            <CorsoMobileCard
              corso={item}
              getCorsoProgress={getCorsoProgress}
              tests={tests}
              openTestModal={openTestModal}
              openTestFinaleModal={openTestFinaleModal}
              openCertificatoModal={openCertificatoModal}
            />
          ))}
      </div>
    );
  }
  return (
    <div className={"innerContent"}>
      <Table
        loading={isLoading}
        dataSource={data.sort((a, b) => Number(a?.index) - Number(b?.index))}
        columns={columns}
      />
    </div>
  );
};

export default UserCorsiTable;
