import * as React from "react";
import useCachedQuery from "./useCachedQuery";
import { Corso, Utente } from "../API";
import useQuery from "./useQuery";
import { groupBy } from "lodash";

const useAllUtentes = () => {
  const { data, isLoading } = useCachedQuery<Utente[]>(
    "listUtentes",
    "list-utentes"
  );

  const getUtenteByUsername = (username: string) => {
    console.log({ username, data });
    if (!data) {
      return undefined;
    }

    return data.find((utente) => utente.username === username);
  };

  return { getUtenteByUsername, data, isLoading };
};

export default useAllUtentes;
