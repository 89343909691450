import * as React from "react";
import { useParams } from "react-router-dom";
import useCachedQuery from "../../hooks/useCachedQuery";
import {
  AttivitaItemInput,
  Corso,
  Duration,
  Utente,
  UtenteVideo,
} from "../../API";
import { groupBy } from "lodash";
import AttivitaItem, {
  IAttivitaItem,
} from "../../components/AttivitaItem/AttivitaItem";
import { DURATION_INTERVAL } from "../../utils/constants";
import moment from "moment";
import BottomBar from "../../components/BottomBar/BottomBar";
import { Button } from "antd";

interface IProps {}

const AttivitaEsterna = ({}: IProps) => {
  const { editKey } = useParams();
  const allCorsi = useCachedQuery<Corso[]>("listCorsos", "list-corsos");
  const { data, isLoading } = useCachedQuery<Utente>(
    "getUtente",
    `get-utente-${editKey}`,
    {
      username: editKey,
    }
  );

  // group by corsoId and date
  // group by years

  const parseData: { [key: string]: IAttivitaItem[] } = React.useMemo(() => {
    if (!data?.videos?.items || !allCorsi?.data) {
      return [];
    }

    const items: UtenteVideo[] = (data?.videos?.items || []) as any;

    // we group by corsoId
    // this way we will have [[corsoId:"test",...rest],[corsoId:"test1",...rest]]
    const groupedByCorsoId = Object.values(groupBy(items, "corsoId"));

    const corsoMap: {
      [corsoId: string]: {
        [loginTime: string]: { duration: number; realLoginTime: string };
      };
    } = {};
    // now for each corsoId array
    // we reduce summing up all of the durations by date
    groupedByCorsoId.forEach((item) => {
      const datesMap: {
        [loginTime: string]: { duration: number; realLoginTime: string };
      } = {};
      item.forEach((sub) => {
        (sub?.durations || []).forEach((progress) => {
          datesMap[progress?.loginTime || ""] = {
            duration:
              (datesMap[progress?.loginTime || ""]?.duration || 0) +
              (progress?.duration || 0),
            realLoginTime: progress?.realLoginTime!,
          };
        });
      });
      corsoMap[item[0]?.corsoId] = datesMap;
    });

    /*
     * we have
     * {
     *   $corsoId:
     *       {
     *         $loginTime: {$duration , $realLoginTime}
     *        }
     * }
     *
     * we want
     *
     * [nome, corsoId, duration, loginTime ]
     * */

    const toRet: IAttivitaItem[] = [];
    Object.keys(corsoMap).forEach((corsoId) => {
      Object.keys(corsoMap[corsoId]).forEach((loginTime) => {
        toRet.push({
          attivita:
            (allCorsi?.data || []).find((item) => item.id === corsoId)
              ?.titolo || "",
          durata: moment
            .utc(corsoMap[corsoId][loginTime]?.duration * DURATION_INTERVAL)
            .format("HH:mm:ss"),
          data: loginTime,
          realLoginTime: corsoMap[corsoId][loginTime]?.realLoginTime,
          nome: data.nome || data.username,
        });
      });
    });

    return groupBy(toRet, (item) =>
      moment(item.data).toDate().getFullYear()
    ) as any;

    // ideally we should have a map like {[login)Time:Date]: duration as the sumOfAllDurations with that loginTime}
  }, [data, allCorsi?.data]);

  return (
    <div>
      {Object.keys(parseData).map((key) => (
        <AttivitaItem
          loading={isLoading}
          data={parseData[key]
            .flatMap((item: any) => [
              {
                ...item,
                attivita: "Login",
                durata: "",
                data: item.realLoginTime,
              },
              { ...item },
            ])
            .sort(
              (a: AttivitaItemInput, b: AttivitaItemInput) =>
                //@ts-ignore
                moment(b.data).format("YYYYMMDD") -
                //@ts-ignore
                moment(a.data).format("YYYYMMDD")
            )}
          anno={key}
        />
      ))}
    </div>
  );
};

export default AttivitaEsterna;
