import React from "react";
import "./App.less";
import "./style-overrides.css";

import AuthGuard from "./hocs/AuthGuard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";
import Aziende from "./scenes/aziende/Aziende";
import Video from "./scenes/videos/Videos";
import Slides from "./scenes/slides/Slides";
import AddAzienda from "./scenes/addAzienda/AddAzienda";
import AddLezione from "./scenes/AddLezione/AddLezione";
import AddUser from "./scenes/AddUser/Adduser";
import Overview from "./scenes/Overview/Overview";
import Corsi from "./scenes/corsi/Corsi";
import Utenti from "./scenes/utenti/Utenti";
import AddCorso from "./scenes/AddCorso/AddCorso";
import { LezioneContextProvider } from "./utils/LezioneContext";
import AziendeUtenti from "./scenes/AziendeUtenti/AziendeUtenti";
import AssociaCorsi from "./scenes/AssociaCorsi/AssociaCorsi";
import { RemoveModalContextProvider } from "./utils/RemoveModalContext";
import CredenzialiUtenti from "./scenes/CredenzialiUtenti/CredenzialiUtenti";
import Impostazioni from "./scenes/Impostazioni/Impostazioni";
import AddAdmin from "./scenes/AddAdmin/AddAdmin";
import SuperAdminGuard from "./hocs/SuperAdminGuard";
import CorsiView from "./scenes/CorsiView/CorsiView";
import LezioneView from "./scenes/LezioneView/LezioneView";
import AdminGuard from "./hocs/AdminGuard";
import AttivitaUtente from "./scenes/AttivitaUtente/AttivitaUtente";
import AddAttivita from "./scenes/AddAttivita/AddAttivita";
import Contact from "./scenes/Contact/Contact";
import ProfiloSettings from "./scenes/ProfiloSettings/ProfiloSettings";
import AddAttivita2 from "./scenes/AddAttivita/AddAttivita2";
import AddAttivita3 from "./scenes/AddAttivita/AddAttivita3";
import moment from "moment-timezone";
import Destruction from "./scenes/Destruction/Destruction";
import AddAttivita4 from "./scenes/AddAttivita/AddAttivita4";

Amplify.configure(awsmobile);

function App() {
  moment.tz.setDefault("Europe/Rome");
  return (
    <BrowserRouter>
      <RemoveModalContextProvider>
        <LezioneContextProvider>
          <Routes>
            <Route path={"/"} element={<AuthGuard />}>
              <Route index element={<Overview />} />
              <Route path={"/videos"} element={<Video />} />
              <Route path={"/contact"} element={<Contact />} />
              <Route path={"/slides"} element={<Slides />} />
              <Route path={"/add-attivita"} element={<AdminGuard />}>
                <Route index element={<AddAttivita />} />
              </Route>
              <Route path={"/add-attivita2"} element={<AdminGuard />}>
                <Route index element={<AddAttivita2 />} />
              </Route>
              <Route path={"/destructive-penguin"} element={<AdminGuard />}>
                <Route index element={<Destruction />} />
              </Route>
              <Route path={"/add-attivita3"} element={<AdminGuard />}>
                <Route index element={<AddAttivita3 />} />
              </Route>
              <Route path={"/add-attivita4"} element={<AdminGuard />}>
                <Route index element={<AddAttivita4 />} />
              </Route>
              <Route path={"/aziende"} element={<AdminGuard />}>
                <Route index element={<Aziende />} />
                {withEditRoute(<AddAzienda />, "/aziende/add")}
                {withEditRoute(<AddUser />, "/aziende/utenti/add")}
                <Route
                  path={"/aziende/utenti/attivita/:editKey"}
                  element={<AttivitaUtente />}
                />
                <Route
                  path={"/aziende/utenti/:editKey"}
                  element={<AziendeUtenti />}
                />
                <Route
                  path={"/aziende/utenti/credenziali/:editKey"}
                  element={<CredenzialiUtenti />}
                />
                <Route
                  path={"/aziende/associa-corsi/:editKey"}
                  element={<AssociaCorsi />}
                />
              </Route>
              <Route path={"/utenti"} element={<AdminGuard />}>
                <Route index element={<Utenti />} />
                {withEditRoute(<AddUser />, "/utenti/add")}
                <Route
                  path={"/utenti/attivita/:editKey"}
                  element={<AttivitaUtente />}
                />
              </Route>
              <Route path={"/settings"} element={<SuperAdminGuard />}>
                <Route index element={<Impostazioni />} />
                {withEditRoute(<AddAdmin />, "/settings/admin/add")}
              </Route>
              <Route path={"/profilo/:editKey"} element={<SuperAdminGuard />}>
                <Route index element={<AddAdmin />} />
              </Route>
              <Route path={"/profilo-settings"}>
                <Route index element={<ProfiloSettings />} />
                <Route
                  path={"/profilo-settings/assistenza"}
                  element={<Contact canGoBack={true} />}
                />
              </Route>
              <Route path={"/corsi"} element={<Corsi />} />
              <Route path={"/corsi/lezioni/:editKey"} element={<CorsiView />} />
              <Route
                path={"/corsi/lezione/:editKey"}
                element={<LezioneView />}
              />
              <Route path={"/corsi/add"} element={<AdminGuard />}>
                <Route index element={<AddCorso />} />
                <Route path={"/corsi/add/:editKey"} element={<AddCorso />} />
              </Route>
            </Route>
          </Routes>
        </LezioneContextProvider>
      </RemoveModalContextProvider>
    </BrowserRouter>
  );
}

function withEditRoute(element: JSX.Element, path: string) {
  const cleanPath =
    path.substr(path.length - 1, 1) === "/"
      ? path.substr(0, path.length - 1)
      : path;
  return ["/", "/:editKey"].map((ending) => (
    <Route key={ending} path={`${cleanPath}${ending}`} element={element} />
  ));
}

export default App;
