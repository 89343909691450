import * as React from "react";
import { PageHeader, Select } from "antd";
import BackIcon from "../../components/BackIcon/BackIcon";
import useCachedQuery from "../../hooks/useCachedQuery";
import useQuery from "../../hooks/useQuery";
import { Azienda, Corso, Utente, UtenteAttivita } from "../../API";
import { useEffect, useState } from "react";
import AttivitaForm2 from "../../components/Forms/AttivitaForm/AttivitaForm2";
import AttivitaForm3 from "../../components/Forms/AttivitaForm/AttivitaForm3";

const AddAttivita3 = ({}) => {
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [azienda, setAzienda] = useState<string | undefined>(undefined);
  const { data } = useCachedQuery<Utente[]>("listUtentes", `get-utentes`);
  const attività = useQuery<UtenteAttivita[]>("utenteAttivitaByUserId", {
    userId: username,
  });
  const corsi = useQuery<Corso[]>("listCorsos")?.data;
  const aziende = useQuery("listAziendas")?.data;
  useEffect(() => {
    attività.refetch();
  }, [username]);
  const currentUtente: Utente = data?.find(
    (item) => item.username === username
  )!;

  const attivitaUtente = attività.data?.[0];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={"Inserisci attività utente"}
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
      />

      <AttivitaForm3
        loading={attività.isLoading}
        aziende={aziende}
        setUsername={setUsername}
        data={data}
        azienda={azienda}
        setAzienda={setAzienda}
        refetch={attività.refetch}
        userId={username}
        initialValues={attivitaUtente as any}
        corsi={corsi}
      />
    </div>
  );
};

export default AddAttivita3;
