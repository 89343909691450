import { LoginForm } from "./components/LoginForm";
import { Col, Row, Layout, Image } from "antd";
import s from "./Login.module.less";
import logo from "./assets/logo-opinion.svg";

const Login = ({ login }: { login: any }) => {
  return (
    <Row style={{ minHeight: "100vh", background: "#202124" }}>
      <Col className={s.loginContainer} flex={1}>
        <Layout style={{ minHeight: "100vh" }} className={s.formLayout}>
          <div className={s.loginWrapper}>
            <h1>Benvenuto!</h1>
            <p>Inserisci i tuoi dati per accedere!</p>
            <LoginForm login={login} />
          </div>
        </Layout>
      </Col>
    </Row>
  );
};

export default Login;
