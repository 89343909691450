import * as React from "react";
import AziendeTable from "../../components/Tables/AziendeTable";
import BottomBar from "../../components/BottomBar/BottomBar";
import { Button, Input, PageHeader, Select } from "antd";
import { Link } from "react-router-dom";
import useCachedQuery from "../../hooks/useCachedQuery";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Azienda } from "../../API";
import { SorterResult, SortOrder } from "antd/es/table/interface";
import { BarsOutlined } from "@ant-design/icons";

interface IProps {}

const Aziende = ({}: IProps) => {
  const [filter, setFilter] = useState("");
  const { data, isLoading } = useCachedQuery("listAziendas", "list-aziende");
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Azienda>>({});

  const sorterOptions = [
    {
      label: "Aziende A-Z",
      value: {
        columnKey: "ragioneSociale",
        order: "ascend",
      },
    },
    {
      label: "Aziende Z-A",
      value: {
        columnKey: "ragioneSociale",
        order: "descend",
      },
    },
  ].map((item) => ({ ...item, value: JSON.stringify(item.value) }));

  const filteredData = React.useMemo(() => {
    if (filter?.length === 0 || !filter) {
      return data;
    }
    return (data || []).filter((item: Azienda) => {
      return (
        item.ragioneSociale
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()) ||
        item.citta?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
        item.telefono?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      );
    });
  }, [filter, data]);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Lista Aziende"
        extra={[
          <div className={"formRow"}>
            <Search
              placeholder={"Cerca Azienda"}
              onChange={(a: any) => {
                setFilter(a.target.value);
              }}
            />
            <Select
              suffixIcon={<BarsOutlined style={{ color: "white" }} />}
              placeholder={"Ordinamento"}
              onChange={(v: any) => setSortedInfo(JSON.parse(v))}
              options={sorterOptions}
            />
          </div>,
        ]}
      />
      <AziendeTable
        sortedInfo={sortedInfo}
        data={filteredData}
        isLoading={isLoading}
      />
      <BottomBar>
        <Link to={"/aziende/add"}>
          <Button style={{ marginBottom: 0 }} type={"primary"} size={"large"}>
            Inserisci Azienda
          </Button>
        </Link>
      </BottomBar>
    </div>
  );
};

export default Aziende;
