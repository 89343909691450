import * as React from "react";
import { Button, Modal } from "antd";
import s from "../TestModal/Test.module.less";

interface IProps {
  open?: string;
  closeModal(): void;
  title: string;
  certificatoId?: string;
}

const CertificatoModal = ({ title, open, closeModal }: IProps) => {
  return (
    <Modal
      title={title}
      centered
      width={1000}
      open={!!open}
      onCancel={closeModal}
      footer={
        <Button onClick={closeModal} size={"large"} type={"primary"}>
          Conferma
        </Button>
      }
    >
      <div className={s.formContainer}>
        <p>
          L’attestato sarà consegnato presso l’azienda alla fine del progetto
          formativo.
        </p>
      </div>
    </Modal>
  );
};

export default CertificatoModal;
