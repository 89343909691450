import * as React from "react";
import { Corso, Lezione } from "../../API";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import classNames from "classnames";

interface IProps {
  data: Lezione[];
  isLoading?: boolean;
  editLezione?: (v: Lezione) => void;
  deleteLezione?: (v: string) => void;
}

const LezioniTable = ({
  data,
  isLoading,
  editLezione,
  deleteLezione,
}: IProps) => {
  const columns: ColumnsType<Lezione> = [
    {
      title: "ID",
      dataIndex: "index",
      key: "id",
    },
    {
      title: "Titolo Lezione",
      dataIndex: "titolo",
      key: "titolo",
      sorter: (a, b) => a.titolo?.localeCompare(b.titolo!) as any,
    },
    {
      title: "Azioni",
      key: "action",
      render: (_, record) => (
        <div
          className={classNames("formRow", "icons")}
          style={{ alignItems: "center" }}
        >
          <Tooltip title={"Modifica Lezione"}>
            <div onClick={() => editLezione?.(record)}>
              <EditOutlined style={{ color: "white" }} />
            </div>
          </Tooltip>
          <Tooltip title={"Elimina Lezione"}>
            <div onClick={() => deleteLezione?.(record.id)}>
              <DeleteOutlined style={{ color: "white" }} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table loading={isLoading} dataSource={data} columns={columns} />
    </div>
  );
};

export default LezioniTable;
