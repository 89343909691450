import * as React from "react";
import s from "./AttivitaItem.module.less";
import { MomentInput } from "moment";
import moment from "moment";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { Link } from "react-router-dom";
import logo from "../../logo.svg";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export interface IAttivitaItem {
  nome: string;
  attivita: string;
  durata?: string;
  data: MomentInput;
  realLoginTime: MomentInput;
}
interface IProps {
  loading: boolean;
  data: IAttivitaItem[];
  anno: string;
}

const AttivitaItem = ({ anno, data, loading }: IProps) => {
  const [expanded, setExpanded] = useState(true);
  const columns: ColumnsType<IAttivitaItem> = [
    {
      title: "Nome e cognome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Attività",
      dataIndex: "attivita",
      key: "attivita",
    },
    {
      title: "Durata",
      dataIndex: "durata",
      key: "durata",
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      render: (_, record) => moment(record.data).format("DD/MM/YY[-]H:mm"),
    },
  ];

  const toggle = () => {
    setExpanded(!expanded);
  };

  const onExport = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#my-table" });
    doc.setFontSize(11);
    doc.setTextColor("#171821");

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    var pageSize = doc.internal.pageSize;
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    var text = doc.splitTextToSize("E-learning - Opinion", pageWidth - 35, {});
    doc.text(text, 14, 30);
    // Or use javascript directly:
    autoTable(doc, {
      headStyles: { fillColor: "#171821", textColor: "white" },
      head: [["ID account", "Attività", "Durata", "Data"]],
      startY: 40,
      body: (data as any[])
        .sort((a, b) => moment(a.data).diff(moment(b.data)))
        .map((item) => [
          item.nome!,
          item.attivita!,
          item.durata!,
          moment(item.data).format("DD/MM/YY[-]H:mm"),
        ]),
    });

    doc.save(`${data[0]?.nome}-${anno}-attivita.pdf`);
  };

  return (
    <div className={classNames(s.container, expanded && s.expanded)}>
      <div style={{ justifyContent: "space-between" }} className={"formRow"}>
        <div
          onClick={toggle}
          style={{ alignItems: "center" }}
          className={"formRow"}
        >
          <h1>{anno}</h1>
          {!expanded ? (
            <DownOutlined style={{ color: "white", marginTop: -7 }} />
          ) : (
            <UpOutlined style={{ color: "white", marginTop: -7 }} />
          )}
        </div>
        <Button
          className={"ctaSolid"}
          size={"large"}
          type={"primary"}
          onClick={onExport}
        >
          Export PDF
        </Button>
      </div>
      <Table
        pagination={false}
        dataSource={data.sort((a, b) => moment(b.data).diff(moment(a.data)))}
        loading={loading}
        columns={columns}
      />
    </div>
  );
};

export default AttivitaItem;
