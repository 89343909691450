import * as React from "react";
import { Button, Checkbox, DatePicker, Select, TimePicker } from "antd";
import { AttivitaItem, Corso } from "../../../API";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { MomentInput } from "moment";
import { useState } from "react";

interface IProps {
  corsi?: Corso[];
  createNewRow: (time: Date, durata: Date, corso: string) => void;
  onDelete?: (index: number) => void;
  error?: string;
}

const AttivitaDateInput = ({ corsi, createNewRow, error }: IProps) => {
  const getRandomDate = (start: Date, end: Date) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };
  moment.tz.setDefault("Europe/Rome");

  const [corso, setCorso] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div style={{ color: "red" }}>{error}</div>
      <div style={{ alignItems: "center" }} className={"formRow"}>
        <Select
          showSearch={true}
          style={{ width: "100%", minWidth: 400 }}
          size={"large"}
          options={(corsi || []).map((item) => ({
            label: item.titolo,
            value: item.titolo,
          }))}
          value={corso}
          onChange={(value) => setCorso(value)}
          placeholder="Seleziona corso"
        />
        <DatePicker
          format={"DD/MM/YYYY"}
          style={{ width: "100%" }}
          open={open}
          placeholder={"Data inizio"}
          onChange={(date) => {
            const time = getRandomDate(
              moment(date).set({ hour: 9 }).toDate(),
              moment(date).set({ hour: 15 }).toDate()
            );
            const durata = getRandomDate(
              moment(date).set({ hour: 4, minute: 0, second: 0 }).toDate(),
              moment(date).set({ hour: 4, minute: 59, second: 59 }).toDate()
            );
            createNewRow(time, durata, corso);
          }}
        />
        <Checkbox checked={open} onChange={(v) => setOpen(!open)}>
          Apri
        </Checkbox>
      </div>
    </div>
  );
};

export default AttivitaDateInput;
