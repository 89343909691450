import * as React from "react";
import useQuery from "../../hooks/useQuery";
import { Button } from "antd";
import { UtenteAttivita, UtenteVideo } from "../../API";
import { groupBy } from "lodash";
import { sendRequest } from "../../utils/sendRequest";
import useLimitLessQuery from "../../hooks/useLimitlessQuery";

interface IProps {}

const Destruction = ({}: IProps) => {
  const utenteVideos = useQuery<UtenteVideo[]>("listUtenteVideos", {
    limit: 99999,
  }).data;

  const utenteAttivitas = useLimitLessQuery<UtenteAttivita[]>(
    "listUtenteAttivitas"
  ).data;

  console.log({ utenteAttivitas });
  const deleteUtenteAttivita = async (
    id: string,
    onError: any,
    onSuccess: any
  ) => {
    await sendRequest(
      "deleteUtenteAttivita",
      { input: { id } },
      true,
      true,
      onError,
      onSuccess
    );
  };

  const deleteUtenteVideo = async (
    id: string,
    onError: any,
    onSuccess: any
  ) => {
    await sendRequest(
      "deleteUtenteVideo",
      { input: { id } },
      true,
      true,
      onError,
      onSuccess
    );
  };

  const onDeleteAttivita = async () => {
    const duplicates = utenteAttivitas
      ?.filter((item, n) => {
        return utenteAttivitas.find(
          (i, n2) => item.userId === i.userId && n !== n2
        );
      })
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));

    console.log({ duplicates });
    const grouped = groupBy(duplicates, "userId");
    console.log({ grouped });
    const byUserIdArray = Object.values(grouped);
    console.log({ byUserIdArray });
    const toDeleteIds: string[] = [];
    byUserIdArray.forEach((arr) => {
      arr.slice(1).forEach((item) => toDeleteIds.push(item.id));
    });
    let deleted = 0;

    for (let i in toDeleteIds) {
      await deleteUtenteAttivita(
        toDeleteIds[i],
        () => {},
        () => deleted++
      );
    }
    console.log({ toDeleteIds, length: toDeleteIds.length, deleted });
  };

  const onDeleteVideos = async () => {
    const duplicates = utenteVideos
      ?.filter((item) => {
        return utenteVideos.find(
          (i) =>
            item.id !== i.id &&
            item.lezioneId === i.lezioneId &&
            item.utenteVideosId === i.utenteVideosId
        );
      })
      .sort(
        (a, b) =>
          a.lezioneId.localeCompare(b.lezioneId) ||
          (a.utenteVideosId || "").localeCompare(b.utenteVideosId || "")
      );
    const grouped = groupBy(duplicates, "utenteVideosId");
    const byUtenteVideosIdArray = Object.values(grouped);
    const toDeleteIds: string[] = [];
    byUtenteVideosIdArray.forEach((arr) => {
      Object.values(groupBy(arr, "lezioneId")).forEach((arr) => {
        const sorted = arr.sort(
          (a, b) => (b.progress || 0) - (a.progress || 0)
        );
        console.log(
          sorted[0].progress + "" + sorted[0].id,
          sorted?.[1].progress
        );
        sorted.slice(1).forEach((item) => toDeleteIds.push(item.id));
      });
    });
    let deleted = 0;

    for (let i in toDeleteIds) {
      await deleteUtenteVideo(
        toDeleteIds[i],
        () => {},
        () => deleted++
      );
    }
    console.log({ toDeleteIds, length: toDeleteIds.length, deleted });
  };

  return (
    <div>
      <Button onClick={onDeleteVideos}>DELETE DUPLICATE VIDEO</Button>
      <Button onClick={onDeleteAttivita}>DELETE DUPLICATE ATTIVITA</Button>
    </div>
  );
};

export default Destruction;
