import { FunctionComponent, useContext, useState } from "react";
import React from "react";
import { CreateLezioneInput, DomandaInput, Lezione } from "../API";
import { nanoid } from "nanoid";

interface ILezioneContext {
  lezione: Lezione | CreateLezioneInput;
  updateLezioneValue: (key: string, val: string) => void;
  resetLezione: () => void;
  openModalEdit: (b: any) => void;
  openModalCreate: () => void;
  modalVisibile: boolean;
  setModalVisible: any;
  mode: "EDIT" | "CREATE";
  closeModal: any;
}

const INITIAL_LEZIONE: CreateLezioneInput = {
  index: "",
  titolo: "",
  descrizione: "",
};

const noop = (functionName: string) => () =>
  console.log(`${functionName} not initialised`);

const LezioneContext = React.createContext<ILezioneContext>({
  lezione: INITIAL_LEZIONE,
  resetLezione: noop("resetLezione"),
  updateLezioneValue: noop("updateLezione"),
  mode: "CREATE",
  openModalEdit: noop("openModalEdit"),
  openModalCreate: noop("openModalCreate"),
  modalVisibile: false,
  setModalVisible: noop("setModalVisible"),
  closeModal: noop("closeModal"),
});

export const LezioneContextProvider: FunctionComponent = ({ children }) => {
  const [lezione, setLezione] = React.useState<CreateLezioneInput | Lezione>(
    INITIAL_LEZIONE
  );
  const [mode, setMode] = React.useState<"CREATE" | "EDIT">("CREATE");
  const [modalVisibile, setModalVisible] = useState(false);

  const updateLezioneValue = (key: string, val: string) => {
    setLezione({ ...lezione, [key]: val });
  };

  const resetLezione = () => {
    setLezione({
      index: "",
      titolo: "",
      descrizione: "",
    });
  };

  const openModalEdit = (b: Lezione) => {
    setLezione(b);
    setMode("EDIT");
    setModalVisible(true);
  };

  const openModalCreate = () => {
    resetLezione();
    setMode("CREATE");
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    resetLezione();
  };

  return (
    <LezioneContext.Provider
      value={{
        resetLezione,
        setModalVisible,
        modalVisibile,
        lezione,
        updateLezioneValue,
        openModalEdit,
        openModalCreate,
        mode,
        closeModal,
      }}
    >
      {children}
    </LezioneContext.Provider>
  );
};

export const useLezione = () => useContext(LezioneContext);
