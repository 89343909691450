import { Form, Input, Button, Checkbox, Spin } from "antd";
import styles from "./LoginForm.module.less";
import { useState } from "react";

const LoginForm = ({ login }: { login: any }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async ({ username, password }: ILoginPayload) => {
    login?.(username, password);
    console.log("finish");
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      layout={"vertical"}
      className={styles.container}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Inserisci username!" }]}
      >
        <Input
          placeholder={"inserisci"}
          bordered={false}
          style={{ borderBottom: "1px solid #EAEAEA", width: 300 }}
        />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Inserisci password!" }]}
      >
        <Input.Password
          placeholder={"inserisci"}
          style={{ borderBottom: "1px solid #EAEAEA", width: 300 }}
          bordered={false}
        />
      </Form.Item>

      <Form.Item>
        <Button
          style={{
            marginTop: 32,
            width: 300,
            height: 45,
            borderColor: "#EAEA4E",
            borderWidth: 2,
            color: "#EAEA4E",
            borderRadius: 0,
          }}
          type="ghost"
          htmlType="submit"
        >
          {loading ? <Spin style={{ marginTop: 5 }} /> : "Login"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
