import * as React from "react";
import { Input, Checkbox, Form } from "antd";
import Radio from "../../Radio/Radio";
import { useState } from "react";
import s from "./Domanda.module.less";
import { Domanda as IDomanda } from "../../../API";
import TooltipInput from "../../TooltipInput/TooltipInput";
import { DeleteOutlined } from "@ant-design/icons";

interface IProps {
  title: string;
  domanda: Partial<IDomanda>;
  updateDomanda: (v: Partial<IDomanda>) => void;
  error?: string;
  onRemove?: () => void;
}

const Domanda = ({
  title,
  domanda,
  updateDomanda,
  error,
  onRemove,
}: IProps) => {
  const updateRisposta = (v: string, i: number) => {
    const temp = domanda.risposte || [];
    temp[i] = v;
    updateDomanda({ risposte: temp });
  };
  const updatePartialDomanda = (v: Partial<IDomanda>) => {
    updateDomanda?.(v);
  };

  const setValue = (s: string, i: number) => {
    if (s === "Vero") {
      updatePartialDomanda?.({
        rispostaIndex: i,
      });
    }
  };

  const getValue = (i: number) =>
    i === domanda?.rispostaIndex ? "Vero" : "Falso";

  return (
    <div className={s.container}>
      {error && <p className={s.error}>{error}</p>}
      <p>
        {title}
        {onRemove && (
          <span
            onClick={onRemove}
            style={{ color: "#FB6363", marginLeft: 10, cursor: "pointer" }}
          >
            Rimuovi domanda <DeleteOutlined color={"red"} />
          </span>
        )}
      </p>
      <TooltipInput
        value={domanda?.domanda}
        onChange={(v) => updateDomanda?.({ domanda: v.target.value })}
        placeholder="Domanda"
      />
      <div className={"formRow"}>
        <TooltipInput
          value={domanda?.risposte?.[0] || ""}
          onChange={(v) => updateRisposta(v.target.value, 0)}
          placeholder="Risposta 1"
        />
        <Radio
          value={getValue(1)}
          setValue={(s: string) => setValue(s, 1)}
          options={["Vero", "Falso"]}
        />
      </div>
      <div className={"formRow"}>
        <TooltipInput
          value={domanda?.risposte?.[1] || ""}
          onChange={(v) => updateRisposta(v.target.value, 1)}
          placeholder="Risposta 2"
        />
        <Radio
          value={getValue(2)}
          setValue={(s: string) => setValue(s, 2)}
          options={["Vero", "Falso"]}
        />
      </div>
      <div className={"formRow"}>
        <TooltipInput
          value={domanda?.risposte?.[2] || ""}
          onChange={(v) => updateRisposta(v.target.value, 2)}
          placeholder="Risposta 3"
        />
        <Radio
          value={getValue(3)}
          setValue={(s: string) => setValue(s, 3)}
          options={["Vero", "Falso"]}
        />
      </div>
    </div>
  );
};

export default Domanda;
