import * as React from "react";
import { Button, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { UploadFile } from "antd/es/upload/interface";
import { openNotificationError } from "../../utils/openNotification";
import ReactPlayer from "react-player";

interface IProps {}

const Video = ({}: IProps) => {
  const onChange = async (file: UploadFile) => {
    console.log({ file });
    // await uploadFile(file, "test");
  };
  const customRequest = ({ onSuccess }: any) => {
    onSuccess();
  };

  const uploadFile = async (file: UploadFile, key: string) => {
    try {
      const res = await Storage.put(key, file.originFileObj, {
        contentType: file.type,
      });
      console.log("res", res);
    } catch (error) {
      openNotificationError({
        title: "Errore con l'upload del file",
        description: JSON.stringify(error),
      });
    }
  };

  return (
    <div>
      <Upload
        customRequest={customRequest}
        onChange={(file) => onChange(file.file)}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
        <ReactPlayer
          controls={true}
          url="https://elearning2d2c9c258725431dad74e726bbbe0390152556-dev.s3.eu-central-1.amazonaws.com/public/video-y4frPiU0W1c4GWA5RlkGu"
        />
      </Upload>
    </div>
  );
};

export default Video;
