import * as React from "react";
import AziendeTable from "../../components/Tables/AziendeTable";
import BottomBar from "../../components/BottomBar/BottomBar";
import { Button, Input, PageHeader, Select } from "antd";
import { Link } from "react-router-dom";
import useCachedQuery from "../../hooks/useCachedQuery";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Azienda, Corso, Utente } from "../../API";
import CorsiTable from "../../components/Tables/CorsiTable";
import UtentiTable from "../../components/Tables/UtentiTable";
import { SorterResult, SortOrder } from "antd/es/table/interface";
import { BarsOutlined, DeleteOutlined, MenuOutlined } from "@ant-design/icons";

interface IProps {}

const Utenti = ({}: IProps) => {
  const [filter, setFilter] = useState("");
  const { data, isLoading } = useCachedQuery("listUtentes", "list-utentes");
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Azienda>>({});

  const sorterOptions = [
    {
      label: "Utente A-Z",
      value: {
        columnKey: "nome",
        order: "ascend",
      },
    },
    {
      label: "Utente Z-A",
      value: {
        columnKey: "nome",
        order: "descend",
      },
    },
    {
      label: "Aziende A-Z",
      value: {
        columnKey: "azienda",
        order: "ascend",
      },
    },
    {
      label: "Aziende Z-A",
      value: {
        columnKey: "azienda",
        order: "descend",
      },
    },
  ].map((item) => ({ ...item, value: JSON.stringify(item.value) }));

  const filteredData = React.useMemo(() => {
    if (filter?.length === 0 || !filter) {
      return data;
    }
    return (data || []).filter((item: Utente) => {
      return (
        item.username
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()) ||
        item.nome?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
        item.email?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      );
    });
  }, [filter, data]);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Lista Utenti"
        extra={[
          <div className={"formRow"}>
            <Search
              placeholder={"Cerca Utente"}
              onChange={(a: any) => {
                console.log({ a });
                setFilter(a.target.value);
              }}
            />
            <Select
              suffixIcon={<BarsOutlined style={{ color: "white" }} />}
              placeholder={"Ordinamento"}
              onChange={(v: any) => {
                setSortedInfo(JSON.parse(v));
              }}
              options={sorterOptions}
            />
          </div>,
        ]}
      />
      <UtentiTable
        sortedInfo={sortedInfo}
        data={filteredData}
        isLoading={isLoading}
      />
      <BottomBar>
        <Link to={"/utenti/add"}>
          <Button style={{ marginBottom: 0 }} type={"primary"} size={"large"}>
            Inserisci Utente
          </Button>
        </Link>
      </BottomBar>
    </div>
  );
};

export default Utenti;
