import { useState } from "react";
import useAuthListener from "../hooks/useAuthListener";
import { Login } from "../scenes/login";
import { Layout, Spin } from "antd";
import { AppLayout } from "../components/AppLayout";
import { Outlet } from "react-router-dom";

const AuthGuard: React.FC = () => {
  const { user, loading, login } = useAuthListener();

  if (loading) {
    return (
      <Layout>
        <Spin />
      </Layout>
    );
  }

  if (user?.username) {
    return (
      <AppLayout>
        <Outlet />
      </AppLayout>
    );
  }

  return <Login login={login} />;
};

export default AuthGuard;
