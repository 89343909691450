import * as React from "react";
import s from "./TabBar.module.less";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthListener from "../../hooks/useAuthListener";
import { SCENE_ROUTES } from "../../router/sceneRoutes";
import { IRouteConfig } from "../../types/IRouteConfig";
import { Menu } from "antd";
import classNames from "classnames";
import Tab from "./Tab";

interface IProps {
  absolute?: boolean;
}

const TabBar = ({ absolute }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, role } = useAuthListener();

  const onClick = (key: string) => {
    navigate(key);
  };

  const sceneRoutes = SCENE_ROUTES(user.username).filter(
    (item) => item.allowedRoles?.includes(role) && item.tab
  );

  const renderItem = (item: IRouteConfig, index: number) => {
    const Icon = item.icon;

    const selected =
      item.path === "/"
        ? location.pathname === "/"
        : location.pathname.startsWith(item.path);
    return (
      <Tab
        onClick={() => onClick(item.path)}
        selected={selected}
        key={item.path}
        icon={<Icon fill={selected ? "#EAEA4E" : "white"} height={18} />}
        text={item.label}
      />
    );
  };
  return (
    <div
      className={s.bar}
      style={{ position: absolute ? "absolute" : "sticky" }}
    >
      {sceneRoutes.map(renderItem)}
    </div>
  );
};

export default TabBar;
