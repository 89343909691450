import {
  AttivitaItem,
  AttivitaItemInput,
  Azienda,
  Corso,
  UpdateUtenteVideoMutation,
  Utente,
  UtenteAttivita,
} from "../../../API";
import { useEffect, useState } from "react";
import { Button, DatePicker, Select, TimePicker } from "antd";
import * as React from "react";
import AttivitaFormInput3 from "./AttivitaFormInput3";
import s from "../AddTestForm/AddTestForm.module.less";
import { PlusCircleOutlined } from "@ant-design/icons";
import BottomBar from "../../BottomBar/BottomBar";
import { sendMutation, sendRequest } from "../../../utils/sendRequest";
import { IAttivitaItem } from "../../AttivitaItem/AttivitaItem";
import moment from "moment-timezone";

import { MomentInput } from "moment/moment";
import AttivitaDateInput from "./AttivitaDateInput";
import classNames from "classnames";
import AttivitaFormInput4 from "./AttivitaFormInput4";
import { openNotificationError } from "../../../utils/openNotification";
import useQuery from "../../../hooks/useQuery";

interface IProps {
  initialValues?: UtenteAttivita;
  userId?: string;
  corsi?: Corso[];
  refetch?: () => void;
  azienda: any;
  aziende: any;
  setAzienda: any;
  setUsername: any;
  data: any;
}

const INITIAL_ATTIVITA: AttivitaItemInput[] = [
  {
    attivita: "",
    nome: "",
    durata: "",
  },
];

type IErrorMap = { [index: number]: string };
const AttivitaForm4 = ({
  corsi,
  userId,
  refetch,
  azienda,
  setAzienda,
  data,
  aziende,
}: IProps) => {
  const [attivita, setAttivita] = useState<
    (AttivitaItemInput & { index: number })[]
  >([]);
  const [utenti, setUtenti] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const errors: IErrorMap = React.useMemo(() => {
    const initialMap: IErrorMap = {};
    attivita.forEach((item) => {
      if (!item.attivita?.length) {
        initialMap[item.index] = "Corso obbligatorio";
      } else {
        delete initialMap[item.index];
      }
    });
    return initialMap;
  }, [attivita]);

  const setAttivitaIndex = (attivita: AttivitaItemInput[]) => {
    setAttivita(attivita.map((item, i) => ({ ...item, index: i })));
  };

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const getRandomDate = (start: Date, end: Date) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };

  const onSubmit = async () => {
    setSubmitted(true);
    if (attivita.length === 0) {
      openNotificationError({
        title: "Errore",
        description: "Inserisci almeno un attivita",
      });
      return;
    }
    if (!Object.values(errors).length) {
      console.log("PASSED");
    } else {
      console.log("NOT PASSED");
    }

    for (const username of utenti) {
      const time = (date: MomentInput) =>
        getRandomDate(
          moment(date).set({ hour: 9 }).toDate(),
          moment(date).set({ hour: 15 }).toDate()
        );

      const durata = (date: MomentInput) =>
        getRandomDate(
          moment(date).set({ hour: 4, minute: 0, second: 0 }).toDate(),
          moment(date).set({ hour: 4, minute: 59, second: 59 }).toDate()
        );

      const loginTime = (date: MomentInput) => {
        const toSub = getRandomDate(
          moment(date).set({ hour: 0, minute: 1, second: 0 }).toDate(),
          moment(date).set({ hour: 0, minute: 4, second: 59 }).toDate()
        );

        return moment(date)
          .subtract(toSub.getMinutes(), "minutes")
          .subtract(toSub.getSeconds(), "seconds")
          .toDate();
      };

      const getUtenteAttivita = async (id: string) => {
        const attivita = await sendRequest("utenteAttivitaByUserId", {
          userId: id,
        });
        return attivita?.[0];
      };

      const attivitas = attivita.map((item) => {
        const data = time(item.data);
        const getDurata = durata(data);
        const getLoginTime = loginTime(data);
        return {
          durata:
            moment(getDurata).format("HH:mm:ss") === "Invalid date"
              ? item.durata
              : moment(getDurata).format("HH:mm:ss"),
          data: moment(data).toDate(),
          loginTime: item.loginTime || getLoginTime,
          nome: username,
          attivita: item.attivita,
        };
      });
      const attivitaUtente = await getUtenteAttivita(username);

      if (attivitaUtente) {
        await sendMutation("updateUtenteAttivita", {
          input: {
            id: attivitaUtente?.id,
            userId,
            attivita: [...(attivitaUtente?.attivita || []), ...attivitas],
          },
        });
      } else {
        await sendMutation("createUtenteAttivita", {
          input: {
            userId: username,
            attivita: attivitas,
          },
        });
      }
    }

    refetch?.();
    console.log({ attivita, userId });
  };

  const updateAttivita = (i: number, newAttivita: AttivitaItemInput) => {
    console.log({ newAttivita, i });
    setAttivita([
      ...(attivita || []).filter((item) => item?.index !== i),
      { ...newAttivita, index: i, loginTime: undefined },
    ]);
  };

  const onDelete = (i: number) => {
    setAttivitaIndex([...(attivita || []).filter((item) => item?.index !== i)]);
  };

  const onAdd = (att?: AttivitaItemInput) => {
    setAttivitaIndex([...attivita, att || INITIAL_ATTIVITA[0]]);
  };

  useEffect(() => {
    // setAttivitaIndex(INITIAL_ATTIVITA);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gridGap: 10,
        marginTop: -80,
      }}
    >
      <div className={classNames("formRow", "sticky")}>
        <Select
          style={{ marginRight: 25 }}
          showSearch
          size={"large"}
          placeholder="Seleziona un azienda"
          optionFilterProp="children"
          onChange={(value) => {
            setAzienda(value);
          }}
          filterOption={(input, option) => {
            return (
              (option?.label ?? "")
                //@ts-ignore
                .toLowerCase?.()
                .includes(input.toLowerCase())
            );
          }}
        >
          {(aziende || [])
            .sort((a: Azienda, b: Azienda) =>
              a.ragioneSociale?.localeCompare(b?.ragioneSociale || "")
            )
            .map((azienda: Azienda) => (
              <Select.Option key={azienda.id} value={azienda.id}>
                {azienda.ragioneSociale}
              </Select.Option>
            ))}
        </Select>
        <Select
          showSearch
          mode={"multiple"}
          size={"large"}
          placeholder="Seleziona utenti"
          optionFilterProp="children"
          onChange={(value) => {
            setUtenti(value);
          }}
          filterOption={(input, option) => {
            return (
              (option?.children ?? "")
                //@ts-ignore
                .toLowerCase?.()
                .includes(input.toLowerCase())
            );
          }}
        >
          {(azienda
            ? (data || []).filter(
                (item: any) => item.aziendaUsersId === azienda
              )
            : data || []
          )
            .sort((a: any, b: any) => a.username?.localeCompare(b.username))
            .map((utente: Utente) => (
              <Select.Option key={utente.username} value={utente.username}>
                {utente.nome || utente.username}
              </Select.Option>
            ))}
        </Select>
        <AttivitaDateInput
          corsi={corsi}
          createNewRow={(time, durata, corso) =>
            onAdd({
              attivita: corso,
              data: time as any,
              durata: durata as any,
            })
          }
        />
      </div>
      <div className={"formRow"}>
        <p style={{ width: "100%" }}>Corso</p>
        <p style={{ width: "100%" }}>Data</p>
      </div>
      {attivita.map((item) => (
        <AttivitaFormInput4
          key={item.index}
          corsi={corsi}
          error={submitted ? errors[item.index] : ""}
          onDelete={onDelete}
          attivita={item as any}
          updateItem={updateAttivita}
        />
      ))}
      {!!userId && (
        <div
          className={s.addRow}
          style={{ textAlign: "left" }}
          onClick={() => onAdd()}
        >
          <span>Aggiungi Attività </span>
          <PlusCircleOutlined />
        </div>
      )}
      <BottomBar absolute={false}>
        <Button size={"large"} type={"primary"} onClick={onSubmit}>
          Salva
        </Button>
      </BottomBar>
    </div>
  );
};

export default AttivitaForm4;
