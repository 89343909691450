import * as React from "react";
import { Button, Modal } from "antd";
import AddLezioneForm from "../Forms/AddLezioneForm/AddLezioneForm";
import { Domanda, Lezione } from "../../API";
import { useEffect, useState } from "react";
import DomandaTest from "./DomandaTest";
import BottomBar from "../BottomBar/BottomBar";
import s from "./Test.module.less";
import spark from "./assets/spark.svg";
import { useNavigate } from "react-router-dom";

interface IProps {
  open?: string;
  closeModal(): void;
  title: string;
  test: Domanda[];
  onFinish: (wrong: number) => void;
  finale?: boolean;
}

const TestModal = ({
  open,
  closeModal,
  title,
  test,
  onFinish,
  finale,
}: IProps) => {
  const [currentDomanda, setCurrentDomanda] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [ended, setEnded] = useState(false);

  const [risposta, setRisposta] = useState<number>();
  const [wrongCounter, setWrongCounter] = useState<number>(0);

  const rightAnswers = test?.length - wrongCounter;
  const threshold = (test?.length * 80) / 100;
  const passed = rightAnswers >= threshold;

  const navigate = useNavigate();

  useEffect(() => {
    reset();
  }, [open]);

  const reset = () => {
    setWrongCounter(0);
    setCurrentDomanda(0);
    setShowAnswer(false);
    setEnded(false);
  };

  const goToCorso = () => {
    if (finale) {
      if (passed) {
        closeModal();
      } else {
        reset();
      }
      return;
    }
    navigate(`/corsi/lezioni/${open}`);
    closeModal();
  };
  const validRisposta = () => {
    return test?.[currentDomanda].rispostaIndex - 1 === risposta;
  };

  const onSubmit = () => {
    if (currentDomanda === test.length - 1 && showAnswer) {
      setEnded(true);
      if (finale) {
        passed && onFinish(wrongCounter);
      } else {
        onFinish(wrongCounter);
      }

      return;
    }

    if (!showAnswer && (!!risposta || risposta === 0)) {
      setShowAnswer(true);
      if (!validRisposta()) {
        setWrongCounter(wrongCounter + 1);
      }
      setRisposta(undefined);
    } else if (!showAnswer) {
      console.log("RIGHT EMPTY");
      return;
    } else {
      setShowAnswer(false);
      setCurrentDomanda(currentDomanda + 1);
    }
  };

  const endedInner = React.useMemo(() => {
    if (!finale) {
      return (
        <>
          <img src={spark} style={{ width: 50 }} />
          <div style={{ height: 20 }} />
          <p className={s.body}>Hai completato il tuo test iniziale</p>
          <p className={s.body}>Sei pronto per la tua prima lezione?</p>
        </>
      );
    }

    if (passed) {
      return (
        <>
          <h1 style={{ color: "#66FB63" }}>
            {rightAnswers}/{test?.length}
          </h1>
          <div style={{ height: 20 }} />
          <p className={s.body}>Complimenti!</p>
          <p className={s.body}>Hai superato il test con successo</p>
        </>
      );
    }
    return (
      <>
        <h1 style={{ color: "#FB6363" }}>
          {rightAnswers}/{test?.length}
        </h1>
        <div style={{ height: 20 }} />
        <p className={s.body}>Peccato!</p>
        <p className={s.body}>Questa volta non hai superato il test</p>
        <p className={s.body}>Ripetilo nuovamente</p>
      </>
    );
  }, [finale, wrongCounter, rightAnswers, threshold, passed]);

  return (
    <Modal
      title={title}
      centered
      width={1000}
      open={!!open}
      onCancel={closeModal}
      footer={
        <Button
          onClick={ended ? goToCorso : onSubmit}
          size={"large"}
          type={"primary"}
        >
          {ended
            ? finale
              ? passed
                ? "Torna ai corsi"
                : "Ripeti test"
              : "Vai al corso"
            : currentDomanda === test?.length - 1
            ? "Vedi Risultati"
            : "Avanti"}
        </Button>
      }
    >
      <div className={s.formContainer}>
        {ended ? (
          <div
            className={s.center}
            style={{
              height: 300,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {endedInner}
          </div>
        ) : (
          <>
            <DomandaTest
              risposta={risposta}
              setRisposta={(i: number) => setRisposta(i)}
              show={showAnswer}
              domanda={test?.[currentDomanda]}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default TestModal;
