import * as React from "react";
import { Button, Form, Input, PageHeader } from "antd";
import s from "./AddAzienda.module.less";
import AddAziendaForm from "../../components/Forms/AddAziendaForm/AddAziendaForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { sendMutation, sendRequest } from "../../utils/sendRequest";
import useQuery from "../../hooks/useQuery";
import Search from "antd/es/input/Search";
import BackIcon from "../../components/BackIcon/BackIcon";
import { DeleteOutlined } from "@ant-design/icons";
import { useRemoveModal } from "../../utils/RemoveModalContext";

interface IProps {}

const AddAzienda = ({}: IProps) => {
  const { editKey } = useParams();
  const { data, isLoading, refetch } = useQuery(
    "getAzienda",
    { id: editKey },
    undefined,
    !!editKey
  );

  const { openModal, closeModal } = useRemoveModal();
  const onAfterFinish = () => {
    refetch();
    window.history.back();
  };
  const deleteAzienda = () => {
    openModal(
      async () => {
        await sendMutation("deleteAzienda", {
          input: {
            id: editKey,
          },
        });
        closeModal();
        onAfterFinish();
      },
      "l'azienda?",
      "Elimina azienda"
    );
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Dati Aziendali"
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
        extra={
          !!editKey && [
            <Button
              className={"delete"}
              size={"large"}
              onClick={deleteAzienda}
              icon={<DeleteOutlined />}
            >
              Elimina Azienda
            </Button>,
          ]
        }
      />
      <AddAziendaForm initialValues={data} onAfterFinish={onAfterFinish} />
    </div>
  );
};

export default AddAzienda;
