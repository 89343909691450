import * as React from "react";
import BottomBar from "../../components/BottomBar/BottomBar";
import { Button, PageHeader } from "antd";
import { Link } from "react-router-dom";
import useCachedQuery from "../../hooks/useCachedQuery";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Corso, Utente, UtenteCorso, UtenteTest } from "../../API";
import CorsiTable from "../../components/Tables/CorsiTable";
import { sendMutation } from "../../utils/sendRequest";
import { useRemoveModal } from "../../utils/RemoveModalContext";
import useAuthListener from "../../hooks/useAuthListener";
import UserCorsiTable from "../../components/Tables/UserCorsiTable";
import useQuery from "../../hooks/useQuery";
import TestModal from "../../components/TestModal/TestModal";
import useAllVideoProgress from "../../hooks/useAllVideoProgress";
import s from "./Corsi.module.less";
import CertificatoModal from "../../components/CertificatoModal/CertificatoModal";

interface IProps {}

const Corsi = () => {
  const { user } = useAuthListener();

  return user?.isAdmin ? <CorsiAdmin /> : <CorsiUser />;
};
const CorsiAdmin = ({}: IProps) => {
  const [filter, setFilter] = useState("");
  const { data, isLoading, refetch } = useCachedQuery(
    "listCorsos",
    "list-corsos"
  );
  const { openModal, closeModal } = useRemoveModal();

  const deleteCorso = (id: string) => {
    openModal(
      async () => {
        await sendMutation("deleteCorso", {
          input: {
            id,
          },
        });
        closeModal();
        refetch();
      },
      "il corso?",
      "Elimina corso"
    );
  };

  const filteredData: Corso[] = React.useMemo(() => {
    if (filter?.length === 0 || !filter) {
      return data;
    }
    return (data || []).filter((item: Corso) => {
      return (
        item.titolo?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
        item.descrizione
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase())
      );
    });
  }, [filter, data]);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Lista Corsi"
        extra={[
          <Search
            placeholder={"Cerca Corso"}
            onChange={(a: any) => {
              setFilter(a.target.value);
            }}
          />,
        ]}
      />
      <CorsiTable
        onDeleteCorso={deleteCorso}
        data={(filteredData || [])?.sort(
          (a, b) => Number(a.index) - Number(b.index)
        )}
        isLoading={isLoading}
      />
      <BottomBar>
        <Link to={"/corsi/add"}>
          <Button style={{ marginBottom: 0 }} type={"primary"} size={"large"}>
            Inserisci Corso
          </Button>
        </Link>
      </BottomBar>
    </div>
  );
};
const CorsiUser = ({}: IProps) => {
  const { user } = useAuthListener();
  const { getCorsoProgress } = useAllVideoProgress({
    username: user?.username,
  });
  const [testModal, setTestModal] = useState<string | undefined>();
  const [certificatoModal, setCertificatoModal] = useState<
    string | undefined
  >();
  const [testFinaleModal, setTestFinaleModal] = useState<string | undefined>();
  const { data, isLoading, refetch } = useQuery<Utente>(
    "getUtente",
    {
      username: user.username,
    },
    undefined,
    !!user?.username
  );

  const tests: UtenteTest[] = (data?.test?.items as any) || [];
  const allCorsi = useCachedQuery<Corso[]>("listCorsos", "list-corsos");

  const corsiPre: Corso[] = ((data?.corsi?.items as any) || []).map(
    (item: UtenteCorso) => item.corso
  );

  const corsi = React.useMemo(() => {
    const corsiAfter = corsiPre.map((corso) =>
      allCorsi.data?.find((item) => item.id === corso.id)
    );
    return corsiAfter || corsiPre;
  }, [corsiPre]);
  console.log({ corsi });

  const [filter, setFilter] = useState("");

  const filteredData = React.useMemo(() => {
    if (!corsi?.length) {
      return [];
    }

    if (filter?.length === 0 || !filter) {
      return corsi;
    }
    return (corsi || []).filter((item) => {
      return (
        item?.titolo
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()) ||
        item?.descrizione
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase())
      );
    });
  }, [filter, data, corsi]);

  const onCreateTestIniziale = async (_: number) => {
    await sendMutation(
      "createUtenteTest",
      {
        input: {
          utenteTestId: user.username,
          corsoTestId: testModal,
          testIniziale: true,
        },
      },
      true
    );
  };

  const onCreateTestFinale = async (_: number) => {
    const corsoId = testFinaleModal;
    const testId = tests.find((item) => item.corsoTestId === corsoId)?.id;
    await sendMutation(
      "updateUtenteTest",
      {
        input: {
          id: testId,
          testFinale: true,
        },
      },
      true
    );
    refetch();
  };

  const currentTestIniziale = (corsi || []).find(
    (item) => item?.id === testModal
  )?.testIniziale;

  const currentTestFinale = (corsi || []).find(
    (item) => item?.id === testFinaleModal
  )?.testFinale;

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="I Tuoi Corsi"
        extra={[
          <Search
            className={s.searchDesktop}
            placeholder={"Cerca Corso"}
            onChange={(a: any) => {
              setFilter(a.target.value);
            }}
          />,
        ]}
      />
      <Search
        className={s.searchMobile}
        placeholder={"Cerca Corso"}
        onChange={(a: any) => {
          setFilter(a.target.value);
        }}
      />
      <CertificatoModal
        open={certificatoModal}
        closeModal={() => setCertificatoModal(undefined)}
        title={"Certificato"}
      />
      <TestModal
        onFinish={onCreateTestIniziale}
        open={testModal}
        closeModal={() => setTestModal(undefined)}
        title={"Test Iniziale"}
        test={currentTestIniziale as any}
      />
      <TestModal
        onFinish={onCreateTestFinale}
        open={testFinaleModal}
        closeModal={() => setTestFinaleModal(undefined)}
        title={"Test Finale"}
        finale={true}
        test={currentTestFinale as any}
      />
      <UserCorsiTable
        openCertificatoModal={(id: string) => setCertificatoModal(id)}
        overrideCorsoProgress={data?.overrideCorsoProgress || false}
        getCorsoProgress={getCorsoProgress}
        openTestModal={(id: string) => setTestModal(id)}
        openTestFinaleModal={(id: string) => setTestFinaleModal(id)}
        tests={tests}
        data={filteredData as Corso[]}
        isLoading={isLoading || allCorsi.isLoading}
      />
    </div>
  );
};

export default Corsi;
