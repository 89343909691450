import * as React from "react";
import { Corso } from "../../API";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { sendRequest } from "../../utils/sendRequest";
import { Button, Form, Input } from "antd";
import s from "./AddAziendaForm/AddAziendaForm.module.less";
import TextArea from "antd/es/input/TextArea";
import Uploader from "../Uploader/Uploader";
import useCachedQuery from "../../hooks/useCachedQuery";
import { UploadFile } from "antd/es/upload/interface";
import { uploadFile } from "../../utils/uploadFile";
import TooltipInput from "../TooltipInput/TooltipInput";
import ImageUploader from "../ImageUploader/ImageUploader";
import { customAlphabet } from "nanoid";

interface IProps {
  initialValues?: Corso;
  abort?: (editKey?: string) => void;
}

const AddCorsoForm = ({ initialValues, abort }: IProps) => {
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useCachedQuery<Corso[]>(
    "listCorsos",
    "listCorsos"
  );
  const { editKey } = useParams();
  const [image, setImage] = useState<UploadFile | undefined>();

  const initialId = React.useMemo(() => {
    if (isLoading) {
      return "";
    }
    const fillWithZeros = (n: number) => {
      const stringN = n + "";
      const zerosToAdd = 5 - stringN.length;
      let result = stringN;
      for (let i = 0; i < zerosToAdd; i++) {
        result = "0" + result;
      }

      return result;
    };

    const sortedData = data?.sort((a, b) => Number(b.index) - Number(a.index));
    const highestID = sortedData?.[0]?.index || 0;

    let number = Number(highestID);

    for (var i = 1; i < Number(highestID); i++) {
      const hasId = data?.some((item) => Number(item.index) === i);
      if (!hasId) {
        number = i - 1;
      }
    }
    // [0,1,2,3,5,6]
    // for 6 times
    // if data.find(item.index === i)
    const nextNumber = number + 1;

    return fillWithZeros(nextNumber);
    // 00100
  }, [data, isLoading]);

  const blackListIds: string[] = React.useMemo(() => {
    return (data || []).map((item: Corso) => item.index);
  }, [data]);

  const onFinish = async (data: any) => {
    setLoading(true);
    let imgUrl = undefined;
    if (image) {
      imgUrl = (await uploadFile(image, "course-cover")).url;
    }
    if (!!editKey) {
      await sendRequest(
        "updateCorso",
        {
          input: {
            id: editKey,
            imgUrl,
            ...data,
          },
        },
        true
      );
      abort?.(editKey);
    } else {
      const corso = await sendRequest(
        "createCorso",
        {
          input: {
            imgUrl,
            ...data,
          },
        },
        true
      );
      abort?.(corso.id);
    }

    setLoading(false);
  };

  const onFinishFailed = () => {};

  if (isLoading) {
    return null;
  }
  return (
    <Form
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      layout={"vertical"}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className={s.formContainer}>
        <div className={"formRow"}>
          <div style={{ flex: 3.8 }}>
            <div className={"formRow"}>
              <Form.Item
                style={{ flex: 1 }}
                name="index"
                initialValue={initialId}
                rules={[
                  { required: true, message: "Campo richiesto" },
                  {
                    message: "ID Già esistente",
                    validator: (_, value) => {
                      console.log({ blackListIds, value });
                      if (blackListIds.includes(value) && !editKey) {
                        return Promise.reject();
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <TooltipInput disabled={true} placeholder={"ID"} />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="titolo"
                rules={[{ required: false, message: "Campo richiesto" }]}
              >
                <TooltipInput placeholder="Titolo" />
              </Form.Item>
            </div>

            <div className={"formRow"}>
              <Form.Item
                style={{ flex: 1 }}
                name="descrizione"
                rules={[{ required: true, message: "Campo richiesto" }]}
              >
                <TextArea rows={5} placeholder="Descrizione" />
              </Form.Item>
            </div>
          </div>
          <div style={{ flex: 2 }}>
            <ImageUploader
              accept={"video/*"}
              initialImage={initialValues?.imgUrl || undefined}
              text={"immagine"}
              extraStyles={{ height: 200 }}
              onFileUploaded={setImage}
            />
          </div>
        </div>
        <div
          className={"formRow"}
          style={{ justifyContent: "flex-end", marginRight: 10, marginTop: 5 }}
        >
          {abort && (
            <Button onClick={() => abort?.()} size={"large"}>
              Annulla
            </Button>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              htmlType="submit"
              size={"large"}
              loading={loading}
              className={"ctaSolid"}
            >
              Salva
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default AddCorsoForm;
