import * as React from "react";
import { Admin } from "../../API";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import classNames from "classnames";

interface IProps {
  data: Admin[];
  isLoading?: boolean;
  deleteAdmin?: (v: string) => void;
}

const AdminTable = ({ data, isLoading, deleteAdmin }: IProps) => {
  const columns: ColumnsType<Admin> = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (_, record) => <span>{record?.nome || record?.username}</span>,
    },
    {
      title: "Ruolo",
      dataIndex: "titolo",
      key: "titolo",
      render: (_, record) => (
        <span>{record.isSuper ? "Super Admin" : "Admin"}</span>
      ),
      defaultSortOrder: "descend",
      sorter: (a, b) => (a.isSuper ? 1 : 0),
    },
    {
      title: "Azioni",
      key: "action",
      render: (_, record) => (
        <div
          className={classNames("formRow", "icons")}
          style={{ alignItems: "center" }}
        >
          <Link to={`/settings/admin/add/${record.username}`}>
            <EditOutlined style={{ color: "white" }} />
          </Link>
          <div onClick={() => deleteAdmin?.(record.username)}>
            <DeleteOutlined style={{ color: "white" }} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table loading={isLoading} dataSource={data} columns={columns} />
    </div>
  );
};

export default AdminTable;
