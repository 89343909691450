import * as React from "react";
import s from "./TabBar.module.less";
import classNames from "classnames";

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  icon: any;
  selected: boolean;
  text: string;
}

const Tab = ({ children, icon, selected, text, ...rest }: IProps) => {
  return (
    <div className={classNames(selected && s.tabSelected, s.tab)} {...rest}>
      {icon}
      {text}
    </div>
  );
};

export default Tab;
