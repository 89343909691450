import * as React from "react";
import { Button, PageHeader, Select, Spin } from "antd";
import useCachedQuery from "../../hooks/useCachedQuery";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackIcon from "../../components/BackIcon/BackIcon";
import ViewLezioniTable from "../../components/Tables/ViewLezioniTable";
import ReactPlayer from "react-player";
import PdfViewer from "../../components/PdfViewer/PdfViewer";
import moment from "moment";
import useAuthListener from "../../hooks/useAuthListener";
import {
  CreateUtenteVideoInput,
  CreateUtenteVideoMutationVariables,
  DurationInput,
  Lezione,
  Utente,
  UtenteVideo,
} from "../../API";
import { AxiosResponse } from "axios";
import { OnProgressProps } from "react-player/base";
import { sendMutation } from "../../utils/sendRequest";
import useVideoProgress from "../../hooks/useVideoProgress";
import { Simulate } from "react-dom/test-utils";
import { DURATION_INTERVAL } from "../../utils/constants";
import useSmallScreen from "../../hooks/useSmallScreen";

interface IProps {}

const LezioneView = ({}: IProps) => {
  const { editKey } = useParams();
  const playerRef = useRef<any>();
  const { user } = useAuthListener();
  const username = user?.username;
  const loginTime = user?.loginTime;
  const small = useSmallScreen();
  const { videoProgress, loading } = useVideoProgress({
    username,
    lezioneId: editKey!,
  });

  const [progress, setProgress] = useState(videoProgress);
  const { data, isLoading } = useCachedQuery<Lezione>(
    "getLezione",
    `get-lezione-${editKey}`,
    {
      id: editKey,
    }
  );

  useEffect(() => {
    if (!data?.id) {
      return;
    }

    if (data.slideKey && !data.videoKey) {
      onProgress({ played: 1 } as any);
    }
  }, [data]);

  useEffect(() => {
    if (videoProgress?.id) {
      setProgress(videoProgress);
    }
  }, [videoProgress]);

  useEffect(() => {
    if (playerRef?.current && videoProgress) {
      // @ts-ignore
      if (videoProgress.lastProgress === 100) {
        return;
      }
      playerRef?.current?.seekTo(
        (videoProgress?.lastProgress || 0) * 0.01,
        "fraction"
      );
    }
  }, [playerRef?.current, videoProgress]);

  const titolo = data?.titolo;

  const corsoId = data?.corsoLezioniId;
  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const onProgress = async (state: OnProgressProps, skipDuration?: boolean) => {
    const played = Number((state?.played * 100).toFixed(0));
    const toCreate: CreateUtenteVideoInput = {
      lezioneId: editKey!,
      corsoId: corsoId!,
      utenteVideosId: username,
      progress: played > (videoProgress?.progress || 0) ? played : undefined,
      lastProgress: played,
    };
    const rndInt = randomIntFromInterval(1, 5);

    if (progress?.id) {
      const foundDuration = (progress.durations || []).find(
        (item) => item?.loginTime === loginTime
      );

      const updatedDurations = [
        ...(progress?.durations || []).filter(
          (duration) => duration?.loginTime !== loginTime
        ),
        {
          duration: (foundDuration?.duration || 0) + 1,
          loginTime: foundDuration?.loginTime || loginTime,
          realLoginTime:
            foundDuration?.realLoginTime ||
            moment(loginTime).subtract(rndInt, "minutes"),
        },
      ];
      const updatedProgress = await sendMutation(
        "updateUtenteVideo",
        {
          input: {
            id: progress?.id,
            durations: skipDuration ? undefined : updatedDurations,
            ...toCreate,
          },
        },
        true,
        true
      );
      setProgress(updatedProgress);
    } else {
      const progress = await sendMutation(
        "createUtenteVideo",
        {
          input: {
            ...toCreate,
            durations: [
              {
                loginTime,
                duration: 1,
                realLoginTime: moment(loginTime).subtract(rndInt, "minutes"),
              },
            ],
          },
        },
        true,
        true
      );
      setProgress(progress);
    }
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={titolo}
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
      />
      {data?.videoKey && (
        <ReactPlayer
          width={small ? "100%" : undefined}
          ref={playerRef}
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onProgress={onProgress}
          onEnded={() => onProgress({ played: 1 } as any, true)}
          progressInterval={DURATION_INTERVAL}
          controls={true}
          url={data.videoKey}
        />
      )}
      <div style={{ height: 100 }} />
      {data?.slideKey && <PdfViewer url={data.slideKey} />}
    </div>
  );
};

export default LezioneView;
