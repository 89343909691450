import { useEffect, useState } from "react";
import { useMemoryState } from "./useMemoryState";
import { sendRequest } from "../utils/sendRequest";
import { openNotificationError } from "../utils/openNotification";
import { Admin, Utente } from "../API";
import { useNavigate } from "react-router-dom";

const useAuthListener = () => {
  const [user, setUser] = useMemoryState<
    (Partial<Admin> & { loginTime: Date }) | undefined
  >("user", undefined);
  const [loading, setLoading] = useState(false);
  const role = user.isSuper ? "superAdmin" : user.isAdmin ? "admin" : "utente";

  const navigate = useNavigate();
  const login = async (username: string, password: string) => {
    const result = await sendRequest("login", {
      username,
      password,
    });

    const parsed = JSON.parse(result);

    if (parsed.statusCode === 200) {
      setUser({ ...parsed.body, loginTime: new Date() });
      return;
    }
    openNotificationError({
      title: "Errore con il login",
      description: "Il tuo username o password non è valido",
    });
  };

  const logout = () => {
    setUser({});
    navigate("/");
    window.location.reload();
  };

  return { user, loading, login, logout, role };
};

export default useAuthListener;
