import * as React from "react";
import useQuery from "../../hooks/useQuery";
import { ColumnsType } from "antd/es/table";
import { Corso } from "../../API";
import { Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import Icon from "antd/es/icon";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useCachedQuery from "../../hooks/useCachedQuery";
import classNames from "classnames";
interface IProps {
  data: Corso[];
  isLoading: boolean;
  onDeleteCorso: (v: string) => void;
}

const CorsiTable = ({ data, isLoading, onDeleteCorso }: IProps) => {
  const columns: ColumnsType<Corso> = [
    {
      title: "ID",
      dataIndex: "index",
      key: "id",
    },
    {
      title: "Titolo Corso",
      dataIndex: "titolo",
      key: "Titolo",
    },
    {
      title: "Numero Lezioni",
      dataIndex: "Titolo",
      key: "Titolo",
      render: (_, record) => <div>{(record.lezioni?.items || []).length}</div>,
    },
    {
      title: "Azioni",
      key: "action",
      render: (_, record) => (
        <div
          className={classNames("formRow", "icons")}
          style={{ alignItems: "center" }}
        >
          <Link to={`/corsi/add/${record.id}`}>
            <Tooltip title={"Modifica Corso"}>
              <EditOutlined style={{ color: "white" }} />
            </Tooltip>
          </Link>
          <Tooltip title={"Elimina Corso"}>
            <div onClick={() => onDeleteCorso(record.id)}>
              <DeleteOutlined style={{ color: "white" }} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className={"innerContent"}>
      <Table loading={isLoading} dataSource={data} columns={columns} />
    </div>
  );
};

export default CorsiTable;
