import { useState } from "react";
import useAuthListener from "../hooks/useAuthListener";
import { Login } from "../scenes/login";
import { Layout, Spin } from "antd";
import { AppLayout } from "../components/AppLayout";
import { Outlet } from "react-router-dom";

const SuperAdminGuard: React.FC = () => {
  const { user, loading } = useAuthListener();

  if (loading) {
    return (
      <Layout>
        <Spin />
      </Layout>
    );
  }

  if (user?.isAdmin) {
    return <Outlet />;
  }

  return null;
};

export default SuperAdminGuard;
