import { IRouteConfig } from "../types/IRouteConfig";
import { ReactComponent as Dashboard } from "../assets/dashboard/dashboard.svg";
import { ReactComponent as Utenti } from "../assets/dashboard/utenti.svg";
import { ReactComponent as Corsi } from "../assets/dashboard/corsi.svg";
import { ReactComponent as Azienda } from "../assets/dashboard/azienda.svg";
import { ReactComponent as Profilo } from "../assets/dashboard/profile.svg";
import { ReactComponent as Cog } from "../assets/dashboard/cog.svg";
import { ReactComponent as Logout } from "../assets/dashboard/logout.svg";
import { ReactComponent as Contact } from "../assets/dashboard/contact.svg";
import Slides from "../scenes/slides/Slides";
import Aziende from "../scenes/aziende/Aziende";

export const SCENE_ROUTES: (id: string) => IRouteConfig[] = (id: string) => [
  {
    key: "overview",
    label: "Overview",
    path: "/",
    icon: Dashboard,
    allowedRoles: ["admin", "superAdmin", "utente"],
    tab: true,
  },
  {
    key: "azienda",
    label: "Aziende",
    path: "/aziende",
    icon: Azienda,
    allowedRoles: ["admin", "superAdmin"],
  },
  {
    key: "users",
    label: "Utenti",
    path: "/utenti",
    icon: Utenti,
    allowedRoles: ["admin", "superAdmin"],
  },
  {
    key: "courses",
    label: "Corsi",
    path: "/corsi",
    icon: Corsi,
    allowedRoles: ["admin", "superAdmin", "utente"],
    tab: true,
  },

  {
    bottom: true,
    key: "logout",
    label: "Logout",
    path: "#",
    icon: Logout,
    allowedRoles: ["admin", "superAdmin", "utente"],
  },
  {
    bottom: true,
    key: "profile",
    label: "Profilo",
    path: "/profilo/" + id,
    icon: Profilo,
    allowedRoles: ["admin", "superAdmin"],
  },
  {
    key: "settings",
    label: "Assistenza",
    path: "/contact",
    icon: Contact,
    bottom: true,
    allowedRoles: ["utente"],
  },
  {
    key: "settings",
    label: "Impostazioni",
    path: "/settings",
    icon: Cog,
    bottom: true,
    allowedRoles: ["superAdmin"],
  },
  {
    tabOnly: true,
    tab: true,
    key: "profile",
    label: "Profilo",
    path: "/profilo-settings/",
    icon: Profilo,
    allowedRoles: ["utente"],
  },
];
