import * as React from "react";
import { Button, DatePicker, Select, TimePicker } from "antd";
import { AttivitaItem, Corso } from "../../../API";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { MomentInput } from "moment";

interface IProps {
  corsi?: Corso[];
  attivita: AttivitaItem & { index: number };
  updateItem: (index: number, attivita: AttivitaItem) => void;
  onDelete?: (index: number) => void;
  error?: string;
}

const AttivitaFormInput3 = ({
  corsi,
  attivita,
  updateItem,
  onDelete,
  error,
}: IProps) => {
  const getRandomDate = (start: Date, end: Date) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };
  moment.tz.setDefault("Europe/Rome");

  console.log({ moment: moment(), date: moment().format() });
  console.log({
    attivitaData: moment(attivita?.data).toDate(),
  });
  return (
    <div key={attivita.index}>
      <div style={{ color: "red" }}>{error}</div>
      <div style={{ alignItems: "center" }} className={"formRow"}>
        <Select
          showSearch={true}
          style={{ width: "100%" }}
          size={"large"}
          options={(corsi || []).map((item) => ({
            label: item.titolo,
            value: item.titolo,
          }))}
          value={attivita.attivita}
          onChange={(value) =>
            updateItem(attivita.index, { ...attivita, attivita: value })
          }
          placeholder="Seleziona corso"
        />
        <DatePicker
          format={"DD/MM/YYYY"}
          value={attivita?.data ? moment(attivita?.data) : undefined}
          style={{ width: "100%" }}
          placeholder={"Data inizio"}
          onChange={(date) => {
            const time = getRandomDate(
              moment(date).set({ hour: 9 }).toDate(),
              moment(date).set({ hour: 15 }).toDate()
            );
            const durata = getRandomDate(
              moment(date).set({ hour: 4, minute: 0, second: 0 }).toDate(),
              moment(date).set({ hour: 4, minute: 59, second: 59 }).toDate()
            );
            console.log({ durata });
            updateItem(attivita.index, {
              ...attivita,
              data: time as any,
              durata: durata as any,
            });
          }}
        />
        <TimePicker
          format={"HH:mm"}
          style={{ width: "100%" }}
          placeholder={"Orario di inizio"}
          value={attivita?.data ? moment(attivita?.data) : undefined}
          onChange={(date) =>
            updateItem(attivita.index, {
              ...attivita,
              data: moment(attivita.data)
                .set({
                  hour: moment(date).hour(),
                  minute: moment(date).minute(),
                  second: moment(date).second(),
                })
                .format() as any,
            })
          }
        />
        <TimePicker
          format={"HH:mm:ss"}
          style={{ width: "100%" }}
          placeholder={"Durata"}
          value={
            attivita?.durata ? moment(attivita?.durata, "HH:mm:ss") : undefined
          }
          onChange={(date) =>
            updateItem(attivita.index, {
              ...attivita,
              durata: new Date(moment(date!) as any) as any,
            })
          }
        />
        {onDelete && (
          <DeleteOutlined
            onClick={() => onDelete(attivita.index)}
            style={{ color: "white", width: 20, fontSize: 22 }}
          />
        )}
      </div>
    </div>
  );
};

export default AttivitaFormInput3;
