import * as React from "react";
import { Form, Select } from "antd";
import { SelectValue } from "antd/es/select";
import { Azienda } from "../../API";
import useQuery from "../../hooks/useQuery";

interface IProps {
  // onChange: (value: Azienda) => void;
}

const AziendaDropdown = ({ ...props }: IProps) => {
  const { data } = useQuery("listAziendas");
  return (
    <Form.Item
      style={{ flex: 1 }}
      name="aziendaUsersId"
      required={true}
      rules={[{ type: "string", required: true, message: "Campo richiesto" }]}
    >
      <Select
        showSearch
        size={"large"}
        placeholder="Seleziona un azienda"
        optionFilterProp="children"
        filterOption={(input, option) => {
          return (
            (option?.label ?? "")
              //@ts-ignore
              .toLowerCase?.()
              .includes(input.toLowerCase())
          );
        }}
      >
        {(data || []).map((azienda: Azienda) => (
          <Select.Option key={azienda.id} value={azienda.id}>
            {azienda.ragioneSociale}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default AziendaDropdown;
