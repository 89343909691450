import * as React from "react";
import s from "./SiteInfo.module.less";
import ImageUploader from "../ImageUploader/ImageUploader";
import { Input } from "antd";
import TooltipInput from "../TooltipInput/TooltipInput";

interface IProps {}

const SiteInfo = ({}: IProps) => {
  const onFileUploaded = () => {};

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <ImageUploader onFileUploaded={onFileUploaded} />
        <TooltipInput placeholder={"Nome"} />
      </div>
    </div>
  );
};

export default SiteInfo;
