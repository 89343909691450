import React, { useCallback, useLayoutEffect, useState } from "react";

// See: https://usehooks-ts.com/react-hook/use-event-listener
import useEventListener from "./useEventListener";

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const dimensions = React.useMemo(
    () => ref?.getBoundingClientRect(),
    [ref?.offsetHeight, ref?.offsetWidth]
  );

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: dimensions?.width || 0,
      height: dimensions?.height || 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  useEventListener("resize", handleSize);

  useLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  return [setRef, { width: size.width, height: size.height - 20 }];
}

export default useElementSize;
