import { Button, Form, Input, Switch } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { sendRequest } from "../../utils/sendRequest";
import { phoneValidator } from "../../utils/validators";
import { Admin, Azienda, Utente } from "../../API";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { openNotificationError } from "../../utils/openNotification";
import AziendaDropdown from "../AziendaDropdown/AziendaDropdown";
import Radio from "../Radio/Radio";
import BottomBar from "../BottomBar/BottomBar";
import TooltipInput from "../TooltipInput/TooltipInput";

interface IProps {
  initialValues?: Admin;
  onAfterSubmit?: () => void;
}
const AddAdminForm = ({ initialValues, onAfterSubmit }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [isSuper, setSuper] = useState("Admin");
  const { editKey } = useParams();
  const initialPassword = React.useMemo(() => {
    return nanoid(5);
  }, []);

  useEffect(() => {
    if (initialValues)
      setSuper(initialValues?.isSuper ? "Super Admin" : "Admin");
  }, [initialValues]);

  const onFinish = async (data: any) => {
    if (data.password !== data.ripassword) {
      throw new Error("Password do not match");
      return;
    }
    setLoading(true);
    delete data.ripassword;

    if (!!editKey) {
      await sendRequest(
        "updateAdmin",
        {
          input: {
            username: editKey,
            isSuper: isSuper === "Super Admin" ? true : false,
            ...data,
          },
        },
        true
      );
    } else {
      await sendRequest(
        "createAdmin",
        {
          input: {
            isSuper: isSuper === "Super Admin" ? true : false,
            ...data,
          },
        },
        true
      );
    }
    onAfterSubmit?.();
    setLoading(false);
  };

  const onFinishFailed = () => {};

  return (
    <Form
      initialValues={{
        ...initialValues,
        ripassword: initialValues?.password,
      }}
      key={JSON.stringify(initialValues)}
      layout={"vertical"}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className={"formRow"}>
        <Form.Item
          style={{ flex: 1 }}
          name="nome"
          rules={[{ required: false, message: "Campo richiesto" }]}
        >
          <TooltipInput placeholder="Nome e Cognome" />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          name="username"
          rules={[{ required: true, message: "Campo richiesto" }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          name="email"
          rules={[
            { type: "email", required: false, message: "Campo richiesto" },
          ]}
        >
          <TooltipInput placeholder="E-mail" />
        </Form.Item>
      </div>
      <div className={"formRow"}>
        <Form.Item
          style={{ flex: 1 }}
          name="password"
          rules={[{ required: true, message: "Campo richiesto" }]}
        >
          <TooltipInput placeholder={"Password"} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          name="ripassword"
          rules={[{ required: true, message: "Campo richiesto" }]}
        >
          <TooltipInput placeholder={"Ripeti Password"} />
        </Form.Item>
      </div>
      <Radio
        options={["Admin", "Super Admin"]}
        value={isSuper}
        setValue={setSuper}
      />
      <BottomBar absolute>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button size={"large"} onClick={() => window.history.back()}>
            Annulla
          </Button>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            size={"large"}
            type={"primary"}
            loading={loading}
          >
            {editKey ? "Salva" : "Inserisci Utente"}
          </Button>
        </Form.Item>
      </BottomBar>
    </Form>
  );
};

export default AddAdminForm;
