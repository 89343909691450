import * as React from "react";
import { Corso, Lezione, UtenteCorso } from "../../API";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import classNames from "classnames";

interface IProps {
  data: UtenteCorso[];
  isLoading?: boolean;
  deleteAssociazione?: (v: UtenteCorso) => void;
}

const AssociazioniTable = ({ data, isLoading, deleteAssociazione }: IProps) => {
  const columns: ColumnsType<UtenteCorso> = [
    {
      title: "Nome e cognome",
      dataIndex: "titolo",
      key: "titolo",
      render: (_, record) => <span>{record.utente.nome}</span>,
    },
    {
      title: "Corso",
      dataIndex: "corso",
      key: "corso",
      render: (_, record) => <span>{record.corso.titolo}</span>,
    },
    {
      title: "Azioni",
      key: "action",
      render: (_, record) => (
        <div
          className={classNames("formRow", "icons")}
          style={{ alignItems: "center" }}
        >
          <div onClick={() => deleteAssociazione?.(record)}>
            <DeleteOutlined style={{ color: "white" }} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table loading={isLoading} dataSource={data} columns={columns} />
    </div>
  );
};

export default AssociazioniTable;
