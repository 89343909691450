import * as React from "react";
import s from "./MobileCard.module.less";

interface IProps {
  children: any;
}

export const detailRow = ({ title, text }: { title: string; text: string }) => (
  <div>
    <p className={"details"} style={{ fontSize: 11 }}>
      {title}
    </p>
    <p>{text}</p>
  </div>
);

const MobileCard = ({ children }: IProps) => {
  return <div className={s.container}>{children}</div>;
};

export default MobileCard;
