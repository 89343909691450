import * as React from "react";
import ContactForm from "../../components/Forms/ContactForm";
import { Button, PageHeader } from "antd";
import BackIcon from "../../components/BackIcon/BackIcon";
import { DeleteOutlined } from "@ant-design/icons";

interface IProps {
  canGoBack?: boolean;
}

const Contact = ({ canGoBack }: IProps) => {
  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Assistenza"
        onBack={() => canGoBack && window.history.back()}
        backIcon={canGoBack ? <BackIcon /> : null}
      />
      <div style={{ maxWidth: 700, margin: "auto" }}>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
