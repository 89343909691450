import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import awsconfig from "../aws-exports";
import { Cache } from "aws-amplify";

type IAuthMode =
  | "API_KEY"
  | "AWS_IAM"
  | "OPENID_CONNECT"
  | "AMAZON_COGNITO_USER_POOLS"
  | "AWS_LAMBDA"
  | undefined;

const useCachedQuery = <T = any>(
  query: string,
  cacheKey: string,
  variables?: object,
  onSuccess?: (data: T) => any,
  isAuto: boolean = true,
  authMode?: IAuthMode
) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>(null);
  const [refetchFlag, setRefetchFlag] = useState(false);
  const [enabled, setEnabled] = useState(isAuto);
  const refetch = () => {
    Cache.removeItem(cacheKey);
    setRefetchFlag(!refetchFlag);
  };

  const afterData = (data: any, saveCache: boolean) => {
    onSuccess?.(data);
    setData(data);
    if (saveCache) {
      Cache.setItem(cacheKey, data);
    }
  };

  const fetchData = async () => {
    const cachedData = Cache.getItem(cacheKey);
    if (cachedData) {
      afterData(cachedData, false);
    }
    setLoading(true);
    try {
      console.log(query + " variables:", variables);
      const response: any = await API.graphql({
        // @ts-ignore
        query: queries[query],
        variables:{
          limit:999,
          ...variables,
        },
        authMode,
        authToken: awsconfig.aws_appsync_apiKey,
      });
      const data = response.data[query].items ?? response.data[query];
      afterData(data, true);
      setLoading(false);
    } catch (e) {
      console.log("error", e);
      setError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enabled) {
      fetchData();
    } else {
      setEnabled(true);
    }
  }, [refetchFlag]);

  return { error, data, isLoading, refetch };
};

export default useCachedQuery;
