import * as React from "react";
import s from "./MobileCard.module.less";
import { Corso, Lezione, UtenteTest } from "../../API";
import MobileCard, { detailRow } from "./MobileCard";
import useAllVideoProgress from "../../hooks/useAllVideoProgress";
import { Col, Row } from "antd";
import MobileButton from "../MobileButton/MobileButton";
import { renderCorsoAction } from "../Tables/UserCorsiTable";
import useAuthListener from "../../hooks/useAuthListener";
import { Link } from "react-router-dom";
import { PlayCircleOutlined } from "@ant-design/icons";

interface IProps {
  lezione: Lezione;
  getLezioneProgress: (lezioneId: string) => string | 0;
}

const LezioneMobileCard = ({ lezione, getLezioneProgress }: IProps) => {
  return (
    <MobileCard>
      <div className={s.innerContainer}>
        <Row>
          <Col flex={1}>
            {detailRow({ title: "ID", text: lezione.index.split("-")?.[1] })}
          </Col>
          <Col flex={5}>
            {detailRow({ title: "Lezione", text: lezione.titolo })}
            {detailRow({
              title: "completamento",
              text: getLezioneProgress(lezione.id) || "Non iniziato",
            })}
          </Col>
        </Row>
      </div>
      <MobileButton>
        <Link to={`/corsi/lezione/${lezione.id}`}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              height: 30,
              gridGap: 10,
              marginTop: 4,
            }}
          >
            <PlayCircleOutlined style={{ color: "#171821" }} />
            <span
              style={{
                color: "#171821",
                fontWeight: 400,
                fontSize: 11,
                marginTop: 2,
              }}
            >
              VAI ALLA LEZIONE
            </span>
          </div>
        </Link>
      </MobileButton>
    </MobileCard>
  );
};

export default LezioneMobileCard;
