import * as React from "react";
import s from "./ProfiloSettings.module.less";
import { ReactComponent as Logout } from "../../assets/dashboard/logout.svg";
import { ReactComponent as Contact } from "../../assets/dashboard/contact.svg";
import { Link } from "react-router-dom";
import useAuthListener from "../../hooks/useAuthListener";
interface IProps {}

const ProfiloSettings = ({}: IProps) => {
  const { logout } = useAuthListener();
  return (
    <div style={{ display: "flex", flexDirection: "column", gridGap: 20 }}>
      <Link
        className={"row"}
        style={{ gridGap: 10 }}
        to={"/profilo-settings/assistenza"}
      >
        <Contact fill={"white"} />
        <p>Assistenza</p>
      </Link>
      <div style={{ gridGap: 10 }} onClick={logout} className={"row"}>
        <Logout className={"row"} fill={"white"} />
        <p>Logout </p>
      </div>
    </div>
  );
};

export default ProfiloSettings;
