import * as React from "react";
import useQuery from "../../hooks/useQuery";
import { ColumnsType } from "antd/es/table";
import { Azienda } from "../../API";
import { Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import Icon from "antd/es/icon";
import {
  EditOutlined,
  FileTextOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useCachedQuery from "../../hooks/useCachedQuery";
import classNames from "classnames";
import { SorterResult } from "antd/es/table/interface";

interface IProps {
  data: Azienda[];
  isLoading: boolean;
  sortedInfo?: SorterResult<Azienda>;
}

const AziendeTable = ({ data, isLoading, sortedInfo }: IProps) => {
  const columns: ColumnsType<Azienda> = [
    {
      title: "Ragione Sociale",
      dataIndex: "ragioneSociale",
      key: "ragioneSociale",
      sortOrder:
        sortedInfo?.columnKey === "ragioneSociale" ? sortedInfo.order : null,
      sorter: (a, b) =>
        a?.ragioneSociale?.localeCompare(b?.ragioneSociale!) as any,
    },
    {
      title: "Partita Iva",
      dataIndex: "partitaIva",
      key: "partitaIva",
    },
    {
      title: "Indirizzo",
      dataIndex: "indirizzo",
      key: "indirizzo",
    },
    {
      title: "Corsi",
      dataIndex: "corsi",
      key: "corsi",
      render: (_, record) => <span>{record.corsi?.items?.length}</span>,
    },
    {
      title: "Numero Utenti",
      dataIndex: "utenti",
      key: "utenti",
      sortOrder: sortedInfo?.columnKey === "utente" ? sortedInfo.order : null,
      render: (_, record) => <span>{record.users?.items?.length}</span>,
    },
    {
      title: "Stato",
      dataIndex: "disattiva",
      key: "disattiva",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        Number(a?.disattiva || false) - Number(b.disattiva || false),
      render: (_, record) => (
        <div>{record?.disattiva ? "Non attiva" : "Attiva"}</div>
      ),
    },
    {
      title: "Azioni",
      key: "action",
      render: (_, record) => (
        <div
          className={classNames("formRow", "icons")}
          style={{ alignItems: "center" }}
        >
          <Tooltip title={"Modifica Azienda"}>
            <Link to={`/aziende/add/${record.id}`}>
              <EditOutlined style={{ color: "white" }} />
            </Link>
          </Tooltip>
          <Tooltip title={"Vedi Utenti"}>
            <Link to={`/aziende/utenti/${record.id}`}>
              <UserOutlined style={{ color: "white" }} />
            </Link>
          </Tooltip>
          <Tooltip title={"Credenziali Utenti"}>
            <Link to={`/aziende/utenti/credenziali/${record.id}`}>
              <FileTextOutlined style={{ color: "white" }} />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className={"innerContent"}>
      <Table loading={isLoading} dataSource={data} columns={columns} />
    </div>
  );
};

export default AziendeTable;
