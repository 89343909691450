/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const login = /* GraphQL */ `
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;
export const contactForm = /* GraphQL */ `
  query ContactForm($name: String!, $email: String!, $body: String!) {
    contactForm(name: $name, email: $email, body: $body)
  }
`;
export const getUtenteAttivita = /* GraphQL */ `
  query GetUtenteAttivita($id: ID!) {
    getUtenteAttivita(id: $id) {
      userId
      attivita {
        nome
        attivita
        durata
        data
        loginTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listUtenteAttivitas = /* GraphQL */ `
  query ListUtenteAttivitas(
    $filter: ModelUtenteAttivitaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUtenteAttivitas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        attivita {
          nome
          attivita
          durata
          data
          loginTime
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUtenteVideo = /* GraphQL */ `
  query GetUtenteVideo($id: ID!) {
    getUtenteVideo(id: $id) {
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
        aziendaUsersId
      }
      lezioneId
      corsoId
      corsoTitle
      durations {
        realLoginTime
        loginTime
        duration
      }
      progress
      lastProgress
      id
      createdAt
      updatedAt
      utenteVideosId
    }
  }
`;
export const listUtenteVideos = /* GraphQL */ `
  query ListUtenteVideos(
    $filter: ModelUtenteVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUtenteVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        lezioneId
        corsoId
        corsoTitle
        durations {
          realLoginTime
          loginTime
          duration
        }
        progress
        lastProgress
        id
        createdAt
        updatedAt
        utenteVideosId
      }
      nextToken
    }
  }
`;
export const getSito = /* GraphQL */ `
  query GetSito($nome: String!) {
    getSito(nome: $nome) {
      nome
      imgUrl
      createdAt
      updatedAt
    }
  }
`;
export const listSitos = /* GraphQL */ `
  query ListSitos(
    $nome: String
    $filter: ModelSitoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSitos(
      nome: $nome
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        nome
        imgUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($username: String!) {
    getAdmin(username: $username) {
      username
      email
      nome
      password
      isSuper
      createdAt
      updatedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $username: String
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdmins(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        email
        nome
        password
        isSuper
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUtente = /* GraphQL */ `
  query GetUtente($username: String!) {
    getUtente(username: $username) {
      email
      nome
      username
      password
      abilitato
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        createdAt
        updatedAt
      }
      test {
        items {
          corso {
            id
            testIniziale {
              index
              domanda
              rispostaIndex
              risposte
            }
            testFinale {
              index
              domanda
              rispostaIndex
              risposte
            }
            utenti {
              nextToken
            }
            lezioni {
              nextToken
            }
            test {
              nextToken
            }
            aziende {
              nextToken
            }
            createdAt
            updatedAt
          }
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      corsi {
        items {
          id
          utenteID
          corsoID
          utente {
            email
            nome
            username
            password
            abilitato
            azienda {
              id
              ragioneSociale
              indirizzo
              citta
              provincia
              cap
              partitaIva
              telefono
              email
              visura
              disattiva
              createdAt
              updatedAt
            }
            test {
              nextToken
            }
            videos {
              nextToken
            }
            corsi {
              nextToken
            }
            createdAt
            updatedAt
            aziendaUsersId
          }
          corso {
            id
            index
            certificato
            titolo
            descrizione
            imgUrl
            testIniziale {
              index
              domanda
              rispostaIndex
              risposte
            }
            testFinale {
              index
              domanda
              rispostaIndex
              risposte
            }
            utenti {
              nextToken
            }
            lezioni {
              nextToken
            }
            test {
              nextToken
            }
            aziende {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      overrideCorsoProgress
      createdAt
      updatedAt
      aziendaUsersId
    }
  }
`;
export const listUtentes = /* GraphQL */ `
  query ListUtentes(
    $username: String
    $filter: ModelUtenteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUtentes(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
          items {
            id
          }
        }
        createdAt
        updatedAt
        aziendaUsersId
      }
      nextToken
    }
  }
`;
export const getAzienda = /* GraphQL */ `
  query GetAzienda($id: ID!) {
    getAzienda(id: $id) {
      id
      ragioneSociale
      indirizzo
      citta
      provincia
      cap
      partitaIva
      telefono
      email
      visura
      disattiva
      users {
        items {
          email
          nome
          username
          password
          abilitato
          createdAt
          updatedAt
          aziendaUsersId
        }
        nextToken
      }
      corsi {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAziendas = /* GraphQL */ `
  query ListAziendas(
    $filter: ModelAziendaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAziendas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          items {
            username
          }
          nextToken
        }
        corsi {
          items {
            id
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLezione = /* GraphQL */ `
  query GetLezione($id: ID!) {
    getLezione(id: $id) {
      id
      index
      videoKey
      videoPlayBack
      slideKey
      titolo
      descrizione
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      corsoLezioniId
    }
  }
`;
export const listLeziones = /* GraphQL */ `
  query ListLeziones(
    $filter: ModelLezioneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeziones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        videoKey
        videoPlayBack
        slideKey
        titolo
        descrizione
        corso {
          id
          index
          certificato
          titolo
          descrizione
          imgUrl
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        corsoLezioniId
      }
      nextToken
    }
  }
`;
export const getCorso = /* GraphQL */ `
  query GetCorso($id: ID!) {
    getCorso(id: $id) {
      id
      index
      certificato
      titolo
      descrizione
      imgUrl
      testIniziale {
        index
        domanda
        rispostaIndex
        risposte
      }
      testFinale {
        index
        domanda
        rispostaIndex
        risposte
      }
      utenti {
        items {
          id
          utenteID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      lezioni {
        items {
          id
          index
          videoKey
          videoPlayBack
          slideKey
          titolo
          descrizione
          createdAt
          updatedAt
          corsoLezioniId
        }
        nextToken
      }
      test {
        items {
          testIniziale
          testFinale
          id
          createdAt
          updatedAt
          utenteTestId
          corsoTestId
        }
        nextToken
      }
      aziende {
        items {
          id
          aziendaID
          corsoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCorsos = /* GraphQL */ `
  query ListCorsos(
    $filter: ModelCorsoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          items {
            id
          }
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUtenteTest = /* GraphQL */ `
  query GetUtenteTest($id: ID!) {
    getUtenteTest(id: $id) {
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
        aziendaUsersId
      }
      testIniziale
      testFinale
      id
      createdAt
      updatedAt
      utenteTestId
      corsoTestId
    }
  }
`;
export const listUtenteTests = /* GraphQL */ `
  query ListUtenteTests(
    $filter: ModelUtenteTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUtenteTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        corso {
          id
          index
          certificato
          titolo
          descrizione
          imgUrl
          createdAt
          updatedAt
        }
        utente {
          email
          nome
          username
          password
          abilitato
          createdAt
          updatedAt
          aziendaUsersId
        }
        testIniziale
        testFinale
        id
        createdAt
        updatedAt
        utenteTestId
        corsoTestId
      }
      nextToken
    }
  }
`;
export const getUtenteCorso = /* GraphQL */ `
  query GetUtenteCorso($id: ID!) {
    getUtenteCorso(id: $id) {
      id
      utenteID
      corsoID
      utente {
        email
        nome
        username
        password
        abilitato
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        test {
          nextToken
        }
        videos {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
        aziendaUsersId
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUtenteCorsos = /* GraphQL */ `
  query ListUtenteCorsos(
    $filter: ModelUtenteCorsoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUtenteCorsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        utenteID
        corsoID
        utente {
          email
          nome
          username
          password
          abilitato
          createdAt
          updatedAt
          aziendaUsersId
        }
        corso {
          id
          index
          certificato
          titolo
          descrizione
          imgUrl
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAziendeCorso = /* GraphQL */ `
  query GetAziendeCorso($id: ID!) {
    getAziendeCorso(id: $id) {
      id
      aziendaID
      corsoID
      azienda {
        id
        ragioneSociale
        indirizzo
        citta
        provincia
        cap
        partitaIva
        telefono
        email
        visura
        disattiva
        users {
          nextToken
        }
        corsi {
          nextToken
        }
        createdAt
        updatedAt
      }
      corso {
        id
        index
        certificato
        titolo
        descrizione
        imgUrl
        testIniziale {
          index
          domanda
          rispostaIndex
          risposte
        }
        testFinale {
          index
          domanda
          rispostaIndex
          risposte
        }
        utenti {
          nextToken
        }
        lezioni {
          nextToken
        }
        test {
          nextToken
        }
        aziende {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAziendeCorsos = /* GraphQL */ `
  query ListAziendeCorsos(
    $filter: ModelAziendeCorsoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAziendeCorsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        aziendaID
        corsoID
        azienda {
          id
          ragioneSociale
          indirizzo
          citta
          provincia
          cap
          partitaIva
          telefono
          email
          visura
          disattiva
          createdAt
          updatedAt
        }
        corso {
          id
          index
          certificato
          titolo
          descrizione
          imgUrl
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const utenteAttivitaByUserId = /* GraphQL */ `
  query UtenteAttivitaByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUtenteAttivitaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    utenteAttivitaByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        attivita {
          nome
          attivita
          durata
          data
          loginTime
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const utenteVideosByUsername = /* GraphQL */ `
  query UtenteVideosByUsername(
    $utenteVideosId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUtenteVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    utenteVideosByUsername(
      utenteVideosId: $utenteVideosId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        utente {
          email
          nome
          username
          password
          abilitato
          overrideCorsoProgress
          createdAt
          updatedAt
          aziendaUsersId
        }
        lezioneId
        corsoId
        corsoTitle
        durations {
          realLoginTime
          loginTime
          duration
        }
        progress
        lastProgress
        utenteVideosId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
