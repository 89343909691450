import * as React from "react";
import { Input, InputProps, Tooltip } from "antd";

interface IProps extends InputProps {}

const TooltipInput = ({ ...props }: IProps) => {
  return (
    <Tooltip
      title={props.placeholder}
      placement="topLeft"
      overlayClassName="numeric-input"
    >
      <Input {...props} />
    </Tooltip>
  );
};

export default TooltipInput;
