import * as React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import s from "./BackIcon.module.less";
import { ReactComponent as Back } from "./backIcon.svg";

interface IProps {}

const BackIcon = ({}: IProps) => {
  return (
    <div className={s.row}>
      <Back fill={"#7E7F87"} />
      <span className={s.back}>INDIETRO</span>
    </div>
  );
};

export default BackIcon;
