import * as React from "react";
import AddUtenteForm from "../../components/Forms/AddUtenteForm";
import { useParams } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import s from "../addAzienda/AddAzienda.module.less";
import { Button, PageHeader } from "antd";
import AddAziendaForm from "../../components/Forms/AddAziendaForm/AddAziendaForm";
import BackIcon from "../../components/BackIcon/BackIcon";
import { sendMutation } from "../../utils/sendRequest";
import { useRemoveModal } from "../../utils/RemoveModalContext";
import { DeleteOutlined } from "@ant-design/icons";

interface IProps {}

const AddUser = ({}: IProps) => {
  const { editKey } = useParams();
  const { data, isLoading, refetch } = useQuery(
    "getUtente",
    { username: editKey },
    undefined,
    !!editKey
  );
  const { openModal, closeModal } = useRemoveModal();

  const onAfterSubmit = () => {
    refetch?.();
    window.history.back();
  };
  const deleteUser = () => {
    openModal(
      async () => {
        await sendMutation("deleteUtente", {
          input: {
            username: editKey,
          },
        });
        closeModal();
        onAfterSubmit();
      },
      "l'utente?",
      "Elimina utente"
    );
  };

  return (
    <div className={s.container}>
      <PageHeader
        className="site-page-header"
        title="Dati Utente"
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
        extra={
          !!editKey && [
            <Button
              className={"delete"}
              size={"large"}
              onClick={deleteUser}
              icon={<DeleteOutlined />}
            >
              Elimina Utente
            </Button>,
          ]
        }
      />
      <AddUtenteForm onAfterSubmit={onAfterSubmit} initialValues={data} />
    </div>
  );
};

export default AddUser;
