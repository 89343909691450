import {
  AttivitaItem,
  AttivitaItemInput,
  Azienda,
  Corso,
  UpdateUtenteVideoMutation,
  Utente,
  UtenteAttivita,
} from "../../../API";
import { useEffect, useState } from "react";
import { Button, DatePicker, Select, TimePicker } from "antd";
import * as React from "react";
import AttivitaFormInput3 from "./AttivitaFormInput3";
import s from "../AddTestForm/AddTestForm.module.less";
import { PlusCircleOutlined } from "@ant-design/icons";
import BottomBar from "../../BottomBar/BottomBar";
import { sendMutation, sendRequest } from "../../../utils/sendRequest";
import { IAttivitaItem } from "../../AttivitaItem/AttivitaItem";
import moment from "moment-timezone";

import { MomentInput } from "moment/moment";
import AttivitaDateInput from "./AttivitaDateInput";
import classNames from "classnames";

interface IProps {
  initialValues?: UtenteAttivita;
  userId?: string;
  corsi?: Corso[];
  refetch?: () => void;
  azienda: any;
  aziende: any;
  setAzienda: any;
  setUsername: any;
  data: any;
  loading: boolean;
}

const INITIAL_ATTIVITA: AttivitaItemInput[] = [
  {
    attivita: "",
    nome: "",
    durata: "",
  },
];

type IErrorMap = { [index: number]: string };
const AttivitaForm3 = ({
  initialValues,
  corsi,
  userId,
  refetch,
  azienda,
  setAzienda,
  setUsername,
  data,
  aziende,
  loading,
}: IProps) => {
  const [attivita, setAttivita] = useState<
    (AttivitaItemInput & { index: number })[]
  >([]);

  const [submitted, setSubmitted] = useState(false);

  const errors: IErrorMap = React.useMemo(() => {
    const initialMap: IErrorMap = {};
    attivita.forEach((item) => {
      if (!item.attivita?.length) {
        initialMap[item.index] = "Corso obbligatorio";
      } else if (!item.data?.length) {
        initialMap[item.index] = "Data obbligatorio";
      } else if (!item.durata?.length) {
        initialMap[item.index] = "Durata obbligatorio";
      } else {
        delete initialMap[item.index];
      }
    });
    return initialMap;
  }, [attivita]);

  const setAttivitaIndex = (attivita: AttivitaItemInput[]) => {
    setAttivita(attivita.map((item, i) => ({ ...item, index: i })));
  };

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const onSubmit = async () => {
    setSubmitted(true);
    if (!Object.values(errors).length) {
      console.log("PASSED");
    } else {
      console.log("NOT PASSED");
    }
    const rndInt = randomIntFromInterval(1, 5);

    //@ts-ignore
    const parsedAttivita = attivita.map((item) => {
      return {
        durata:
          moment(item.durata).format("HH:mm:ss") === "Invalid date"
            ? item.durata
            : moment(item.durata).format("HH:mm:ss"),
        data: moment(item.data).toDate(),
        loginTime:
          item.loginTime || moment(item.data).subtract(rndInt, "minutes"),
        nome: userId,
        attivita: item.attivita,
      };
    });

    console.log({ parsedAttivita });

    if (initialValues?.id) {
      if (attivita.length === 0) {
        await sendMutation("deleteUtenteAttivita", {
          input: {
            id: initialValues?.id,
          },
        });
        return;
      } else {
        await sendMutation("updateUtenteAttivita", {
          input: {
            id: initialValues?.id,
            userId,
            attivita: parsedAttivita,
          },
        });
      }
    } else {
      await sendMutation("createUtenteAttivita", {
        input: {
          userId,
          attivita: parsedAttivita,
        },
      });
    }
    refetch?.();
    console.log({ attivita, userId });
  };

  useEffect(() => {
    if (initialValues?.attivita?.length) {
      setAttivitaIndex(initialValues?.attivita as any);
    } else {
      setAttivitaIndex(INITIAL_ATTIVITA);
    }
  }, [initialValues]);

  const updateAttivita = (i: number, newAttivita: AttivitaItemInput) => {
    console.log({ newAttivita, i });
    setAttivita([
      ...(attivita || []).filter((item) => item?.index !== i),
      { ...newAttivita, index: i, loginTime: undefined },
    ]);
  };

  const onDelete = (i: number) => {
    setAttivitaIndex([...(attivita || []).filter((item) => item?.index !== i)]);
  };

  const onAdd = (att?: AttivitaItemInput) => {
    setAttivitaIndex([...attivita, att || INITIAL_ATTIVITA[0]]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gridGap: 10,
        marginTop: -80,
      }}
    >
      <div className={classNames("formRow", "sticky")}>
        <Select
          style={{ marginRight: 25 }}
          showSearch
          size={"large"}
          placeholder="Seleziona un azienda"
          optionFilterProp="children"
          onChange={(value) => {
            setAzienda(value);
          }}
          filterOption={(input, option) => {
            return (
              (option?.label ?? "")
                //@ts-ignore
                .toLowerCase?.()
                .includes(input.toLowerCase())
            );
          }}
        >
          {(aziende || [])
            .sort((a: Azienda, b: Azienda) =>
              a.ragioneSociale?.localeCompare(b?.ragioneSociale || "")
            )
            .map((azienda: Azienda) => (
              <Select.Option key={azienda.id} value={azienda.id}>
                {azienda.ragioneSociale}
              </Select.Option>
            ))}
        </Select>
        <Select
          showSearch
          size={"large"}
          placeholder="Seleziona un utente"
          optionFilterProp="children"
          onChange={(value) => {
            setUsername(value);
          }}
          filterOption={(input, option) => {
            return (
              (option?.children ?? "")
                //@ts-ignore
                .toLowerCase?.()
                .includes(input.toLowerCase())
            );
          }}
        >
          {(azienda
            ? (data || []).filter(
                (item: any) => item.aziendaUsersId === azienda
              )
            : data || []
          )
            .sort((a: any, b: any) => a.username?.localeCompare(b.username))
            .map((utente: Utente) => (
              <Select.Option key={utente.username} value={utente.username}>
                {utente.nome || utente.username}
              </Select.Option>
            ))}
        </Select>
        <AttivitaDateInput
          corsi={corsi}
          createNewRow={(time, durata, corso) =>
            onAdd({
              attivita: corso,
              data: time as any,
              durata: durata as any,
            })
          }
        />
      </div>
      <div className={"formRow"}>
        <p style={{ width: "100%" }}>Corso</p>
        <p style={{ width: "100%" }}>Data</p>
        <p style={{ width: "100%" }}>Orario di inizio</p>
        <p style={{ width: "100%" }}>Durata</p>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        attivita.map((item) => (
          <AttivitaFormInput3
            key={item.index}
            corsi={corsi}
            error={submitted ? errors[item.index] : ""}
            onDelete={onDelete}
            attivita={item as any}
            updateItem={updateAttivita}
          />
        ))
      )}
      {!!userId && (
        <div
          className={s.addRow}
          style={{ textAlign: "left" }}
          onClick={() => onAdd()}
        >
          <span>Aggiungi Attività </span>
          <PlusCircleOutlined />
        </div>
      )}
      <BottomBar absolute={false}>
        <Button size={"large"} type={"primary"} onClick={onSubmit}>
          Salva
        </Button>
      </BottomBar>
    </div>
  );
};

export default AttivitaForm3;
