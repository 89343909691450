import * as React from "react";
import { useWindowSize } from "react-use";

const useSmallScreen = () => {
  const [smallScreen, setSmallScreen] = React.useState(false);

  const measurements = useWindowSize();

  React.useEffect(() => {
    setSmallScreen(measurements.width <= 768);
  }, [measurements?.width]);

  return smallScreen;
};

export default useSmallScreen;
