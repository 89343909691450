import * as React from "react";
import { Select } from "antd";
import { Corso } from "../../API";
import useQuery from "../../hooks/useQuery";
import useCachedQuery from "../../hooks/useCachedQuery";

interface IProps {
  onChange: (value: string) => void;
}

const CorsoDropdown = ({ onChange }: IProps) => {
  const { data } = useCachedQuery<Corso[]>("listCorsos", "list-corsos");

  /*  const corsi = (data || []).filter(
    (item) =>
      (item?.testFinale?.length || 0) > 0 &&
      (item?.testIniziale?.length || 0) > 0
  );*/

  const corsi = data || [];

  return (
    <Select
      style={{ flex: 1, width: "100%" }}
      onChange={onChange}
      size={"large"}
      placeholder="Seleziona un corso"
      optionFilterProp="children"
      filterOption={(input, option) => {
        return (
          (option?.label ?? "")
            //@ts-ignore
            .toLowerCase?.()
            .includes(input.toLowerCase())
        );
      }}
    >
      {(corsi || []).map((corso: Corso) => (
        <Select.Option key={corso.id} value={JSON.stringify(corso)}>
          {corso.titolo}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CorsoDropdown;
