import * as React from "react";
import s from "./Overview.module.less";
import useAuthListener from "../../hooks/useAuthListener";
import { Link } from "react-router-dom";
import {
  PlayCircleOutlined,
  PlusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import useQuery from "../../hooks/useQuery";
import useCachedQuery from "../../hooks/useCachedQuery";
import useAllVideoProgress from "../../hooks/useAllVideoProgress";
import { Progress, Spin } from "antd";
import { Corso, Lezione, UtenteVideo } from "../../API";
import moment from "moment";
import { useEffect } from "react";
import useSmallScreen from "../../hooks/useSmallScreen";
import classNames from "classnames";

interface IProps {}

export const Overview = () => {
  const { user } = useAuthListener();

  return user?.isAdmin ? <OverviewAdmin /> : <OverviewUser />;
};
export const OverviewAdmin = ({}: IProps) => {
  const { user } = useAuthListener();

  const utenti = useCachedQuery("listUtentes", "list-users", { limit: 999 });
  const aziende = useCachedQuery("listAziendas", "list-aziende", {limit:999});
  const corsi = useCachedQuery("listCorsos", "list-corsos");

  const renderTopCard = (title: string, number: string) => {
    return (
      <div className={s.topCard}>
        <p className={s.small}>{title}</p>
        <p className={s.big}>{number || 0}</p>
      </div>
    );
  };

  const renderBottomCard = (text: string, href: string) => {
    return (
      <Link to={href} className={s.bottomCard}>
        <PlusCircleOutlined
          style={{ fontSize: "2.5rem", color: "white", marginBottom: 10 }}
        />
        <p>{text}</p>
      </Link>
    );
  };

  return (
    <div>
      <h1 className={classNames(s.title)}>
        Ciao {user?.nome || user?.username}
      </h1>
      <div className={s.innerContainer}>
        <div className={s.row}>
          {renderTopCard("Numero Aziende", aziende?.data?.length)}
          {renderTopCard("Numero Utenti", utenti?.data?.length)}
          {renderTopCard("Corsi attivi", corsi?.data?.length)}
        </div>
        <div className={s.row}>
          {renderBottomCard("Aggiungi azienda", "/aziende/add")}
          {renderBottomCard("Aggiungi utente", "/utenti/add")}
          {renderBottomCard("Aggiungi corso", "/corsi/add")}
        </div>
      </div>
    </div>
  );
};
export const OverviewUser = ({}: IProps) => {
  const auth = useAuthListener();
  const username = auth.user?.username;
  const mobile = useSmallScreen();
  const { data } = useCachedQuery(
    "getUtente",
    `get-utente-${username}`,
    {
      username: username,
    },
    undefined,
    !!username
  );

  const allCorsi = useCachedQuery<Corso[]>("listCorsos", "list-corsos");

  const corsi = data?.corsi.items || [];

  const renderTopCard = (title: string, number: string) => {
    return (
      <div className={s.topCard} style={{ width: "45%" }}>
        <p className={s.small}>{title}</p>
        <p className={s.big}>{number || 0}</p>
      </div>
    );
  };

  const { videoProgress, corsoTitleById, getCorsoProgress } =
    useAllVideoProgress({ username });

  const lastVideoWatched = React.useMemo(() => {
    if (!videoProgress?.length) {
      return null;
    }

    return videoProgress.sort((a, b) =>
      moment(b?.updatedAt).diff(a?.updatedAt)
    )?.[0];
  }, [videoProgress]);

  const lastLezione = useQuery<Lezione>(
    "getLezione",
    { id: lastVideoWatched?.lezioneId },
    undefined,
    !!lastVideoWatched
  );

  useEffect(() => {
    if (lastVideoWatched) {
      lastLezione.refetch();
    }
  }, [lastVideoWatched]);

  const getCorsiNumber = React.useMemo(() => {
    const ids = new Set<string>();
    videoProgress?.forEach((video) => ids.add(video?.corsoId!));
    return Array.from(ids).length;
  }, [videoProgress]);

  const renderProgress = React.useMemo(() => {
    if (!videoProgress?.length) {
      return null;
    }

    const ids = new Set<string>();
    videoProgress.forEach((video) => ids.add(video?.corsoId!));
    return Array.from(ids)
      .sort((a, b) => {
        const corsoA = videoProgress
          ?.filter((v) => v?.corsoId === a)
          // @ts-ignore
          .sort((a: UtenteVideo, b: UtenteVideo) => {
            const lastDurationA = a.durations?.sort((a, b) =>
              moment(a?.loginTime).diff(moment(b?.loginTime))
            )[0];
            const lastDurationB = b.durations?.sort((a, b) =>
              moment(a?.loginTime).diff(moment(b?.loginTime))
            )[0];

            return moment(lastDurationA?.loginTime).diff(
              moment(lastDurationB?.loginTime)
            );
          })[0];
        const corsoB = videoProgress
          ?.filter((v) => v?.corsoId === b)
          // @ts-ignore
          .sort((a: UtenteVideo, b: UtenteVideo) => {
            const lastDurationA = a.durations?.sort((a, b) =>
              moment(a?.loginTime).diff(moment(b?.loginTime))
            )[0];
            const lastDurationB = b.durations?.sort((a, b) =>
              moment(a?.loginTime).diff(moment(b?.loginTime))
            )[0];

            return moment(lastDurationA?.loginTime).diff(
              moment(lastDurationB?.loginTime)
            );
          })[0];

        return moment(corsoB?.durations?.[0]?.loginTime).diff(
          corsoA?.durations?.[0]?.loginTime
        );
      })
      .map((corsoId: string) => {
        const actual = getCorsoProgress(corsoId);
        return (
          <div style={{ marginTop: 25 }}>
            <p>{corsoTitleById(corsoId)}</p>
            <Progress percent={Number(actual.toFixed(0))} />
          </div>
        );
      })
      .slice(0, 3);
  }, [videoProgress, corsi, allCorsi]);

  return (
    <div>
      <h1 className={s.title}>Ciao {data?.nome || data?.username}</h1>
      <div className={s.innerContainer}>
        <div className={s.row}>
          {renderTopCard("Numero Corsi", corsi?.length)}
          {renderTopCard("Corsi Iniziati", getCorsiNumber + "")}
        </div>
        {!!videoProgress && (
          <div className={s.row}>
            <div
              className={s.topCard}
              style={{
                width: "45%",
                height: 400,
                justifyContent: "flex-start",
              }}
            >
              <h2>I tuoi progressi</h2>
              {renderProgress}
              <Link className={s.addRow} to={"/corsi"}>
                <span>Vai a tutti i corsi </span>
                <RightOutlined />
              </Link>
            </div>
            <div className={s.topCard} style={{ width: "45%", height: 400 }}>
              <div>
                <h2>Continua da dove hai interrotto</h2>
                {lastLezione?.isLoading ? (
                  <Spin />
                ) : (
                  <>
                    <div style={{ position: "relative" }}>
                      <Link
                        to={`/corsi/lezione/${lastVideoWatched?.lezioneId}`}
                      >
                        <PlayCircleOutlined
                          style={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: mobile ? "50%" : "180px",
                            fontSize: 40,
                            marginLeft: -15,
                            marginTop: -15,
                          }}
                        />
                        <video className={s.video}>
                          <source src={lastLezione?.data?.videoKey!} />
                        </video>
                      </Link>
                    </div>
                    <p
                      className={"details"}
                      style={{ marginTop: 15, marginBottom: 0 }}
                    >
                      {lastLezione?.data?.corso?.titolo}
                    </p>
                    <p style={{ fontSize: "1.2rem" }}>
                      {lastLezione?.data?.titolo}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
