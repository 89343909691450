import * as React from "react";
import useQuery from "../../hooks/useQuery";
import { AttivitaItemInput, Utente, UtenteAttivita } from "../../API";
import { useParams } from "react-router-dom";
import { groupBy } from "lodash";
import moment from "moment/moment";
import AttivitaItem from "../../components/AttivitaItem/AttivitaItem";

import useAllUtentes from "../../hooks/useAllUtentes";

interface IProps {}

const AttivitaInterna = ({}: IProps) => {
  const { editKey } = useParams();

  const attività = useQuery<UtenteAttivita[]>("utenteAttivitaByUserId", {
    userId: editKey,
  });
  const { getUtenteByUsername, isLoading } = useAllUtentes();
  const attivitaUtente = attività.data?.[0];

  const byYear = React.useMemo(() => {
    if (!attivitaUtente?.attivita) {
      return [];
    }

    return groupBy(attivitaUtente?.attivita, (item) =>
      moment(item?.data).toDate().getFullYear()
    ) as any;
  }, [attivitaUtente]);

  return (
    <div>
      {Object.keys(byYear).map((key) => (
        <AttivitaItem
          loading={attività.isLoading || isLoading}
          data={byYear[key]
            .flatMap((item: any) => [
              {
                ...item,
                nome: getUtenteByUsername(item.nome)?.nome,
                attivita: "Login",
                durata: "",
                data: item.loginTime,
              },
              { ...item, nome: getUtenteByUsername(item.nome)?.nome },
            ])
            .sort(
              (a: AttivitaItemInput, b: AttivitaItemInput) =>
                //@ts-ignore
                moment(b.data).format("YYYYMMDD") -
                //@ts-ignore
                moment(a.data).format("YYYYMMDD")
            )}
          anno={key}
        />
      ))}
    </div>
  );
};

export default AttivitaInterna;
