import {
  AttivitaItem,
  AttivitaItemInput,
  Corso,
  UpdateUtenteVideoMutation,
  UtenteAttivita,
} from "../../../API";
import { useEffect, useState } from "react";
import { Button, DatePicker, Select, TimePicker } from "antd";
import * as React from "react";
import AttivitaFormInput from "./AttivitaFormInput";
import s from "../AddTestForm/AddTestForm.module.less";
import { PlusCircleOutlined } from "@ant-design/icons";
import BottomBar from "../../BottomBar/BottomBar";
import { sendMutation, sendRequest } from "../../../utils/sendRequest";
import { IAttivitaItem } from "../../AttivitaItem/AttivitaItem";
import moment from "moment";

interface IProps {
  initialValues?: UtenteAttivita;
  userId?: string;
  corsi?: Corso[];
  refetch?: () => void;
}

const INITIAL_ATTIVITA: AttivitaItemInput[] = [
  {
    attivita: "",
    nome: "",
    durata: "",
  },
];

type IErrorMap = { [index: number]: string };
const AttivitaForm = ({ initialValues, corsi, userId, refetch }: IProps) => {
  const [attivita, setAttivita] = useState<
    (AttivitaItemInput & { index: number })[]
  >([]);

  const [submitted, setSubmitted] = useState(false);

  const errors: IErrorMap = React.useMemo(() => {
    const initialMap: IErrorMap = {};
    attivita.forEach((item) => {
      if (!item.attivita?.length) {
        initialMap[item.index] = "Corso obbligatorio";
      } else if (!item.data?.length) {
        initialMap[item.index] = "Data obbligatorio";
      } else if (!item.durata?.length) {
        initialMap[item.index] = "Durata obbligatorio";
      } else {
        delete initialMap[item.index];
      }
    });
    return initialMap;
  }, [attivita]);

  const setAttivitaIndex = (attivita: AttivitaItemInput[]) => {
    setAttivita(attivita.map((item, i) => ({ ...item, index: i })));
  };

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const onSubmit = async () => {
    console.log({ errors });
    setSubmitted(true);
    if (!Object.values(errors).length) {
      console.log("PASSED");
    } else {
      console.log("NOT PASSED");
    }
    const rndInt = randomIntFromInterval(1, 5);
    console.log({ rndInt });
    //@ts-ignore
    const parsedAttivita = attivita.map((item) => ({
      durata: item.durata,
      data: moment(item.data).toDate(),
      loginTime:
        item.loginTime || moment(item.data).subtract(rndInt, "minutes"),
      nome: userId,
      attivita: item.attivita,
    }));

    console.log({ parsedAttivita });
    if (initialValues?.id) {
      await sendMutation("updateUtenteAttivita", {
        input: {
          id: initialValues?.id,
          userId,
          attivita: parsedAttivita,
        },
      });
    } else {
      await sendMutation("createUtenteAttivita", {
        input: {
          userId,
          attivita: parsedAttivita,
        },
      });
    }
    refetch?.();
    console.log({ attivita, userId });
  };

  useEffect(() => {
    if (initialValues?.attivita?.length) {
      setAttivitaIndex(initialValues?.attivita as any);
    } else {
      setAttivitaIndex(INITIAL_ATTIVITA);
    }
  }, [initialValues]);

  const updateAttivita = (i: number, newAttivita: AttivitaItemInput) => {
    console.log({ newAttivita, i });
    setAttivita([
      ...(attivita || []).filter((item) => item?.index !== i),
      { ...newAttivita, index: i, loginTime: undefined },
    ]);
  };

  const onDelete = (i: number) => {
    setAttivitaIndex([...(attivita || []).filter((item) => item?.index !== i)]);
  };

  const onAdd = () => {
    setAttivitaIndex([...attivita, INITIAL_ATTIVITA[0]]);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gridGap: 20 }}>
      <div className={"formRow"}>
        <p style={{ width: "100%" }}>Corso</p>
        <p style={{ width: "100%" }}>Data</p>
        <p style={{ width: "100%" }}>Durata</p>
      </div>
      {attivita.map((item) => (
        <AttivitaFormInput
          key={item.index}
          corsi={corsi}
          error={submitted ? errors[item.index] : ""}
          onDelete={onDelete}
          attivita={item as any}
          updateItem={updateAttivita}
        />
      ))}
      {!!userId && (
        <div className={s.addRow} style={{ textAlign: "left" }} onClick={onAdd}>
          <span>Aggiungi Attività </span>
          <PlusCircleOutlined />
        </div>
      )}
      <BottomBar absolute={false}>
        <Button size={"large"} type={"primary"} onClick={onSubmit}>
          Salva
        </Button>
      </BottomBar>
    </div>
  );
};

export default AttivitaForm;
