import * as React from "react";
import { Checkbox } from "antd";
import s from "./Radio.module.less";
import { Dispatch, SetStateAction, useState } from "react";

const CheckboxGroup = Checkbox.Group;

interface IProps {
  options: string[];
  value: string;
  setValue: (s: string) => void;
}

const Radio = ({ options, value, setValue }: IProps) => {
  const onChange = (v: any) => {
    console.log({ v, value });
    if (value === v) {
      setValue("");
    } else {
      setValue(v);
    }
  };

  return (
    <div
      className={"formRow"}
      style={{ alignContent: "center", alignItems: "center" }}
    >
      {options.map((item) => (
        <Checkbox checked={value === item} onChange={() => onChange(item)}>
          {item}
        </Checkbox>
      ))}
    </div>
  );
};

export default Radio;
