import { FunctionComponent, useContext, useState } from "react";
import React from "react";
import { CreateLezioneInput, DomandaInput, Lezione } from "../API";
import RemoveModal from "../components/RemoveModal/RemoveModal";

interface IRemoveModalContext {
  openModal(onOkay: () => Promise<void>, text?: string, title?: string): void;
  closeModal(): void;
  setConfirmLoading(v: boolean): void;
}
const noop = console.log(`modal not initialised`);

const RemoveModalContext = React.createContext<IRemoveModalContext>({
  openModal: () => noop,
  closeModal: () => noop,
  setConfirmLoading: () => noop,
});

export const RemoveModalContextProvider: FunctionComponent = ({ children }) => {
  const [text, setText] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [onOkay, setOnOkay] = React.useState<() => Promise<void>>();
  const [modalVisibile, setModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const openModal = (
    onOkay: () => Promise<void>,
    text?: string,
    title?: string
  ) => {
    setText(text || "");
    setTitle(title || "");
    setOnOkay(() => onOkay);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setText("");
    setOnOkay(undefined);
  };

  return (
    <RemoveModalContext.Provider
      value={{
        openModal,
        closeModal,
        setConfirmLoading,
      }}
    >
      <RemoveModal
        confirmLoading={confirmLoading}
        onOk={() => {
          onOkay?.();
        }}
        onCancel={closeModal}
        open={modalVisibile}
        text={text}
        title={title}
      />
      {children}
    </RemoveModalContext.Provider>
  );
};

export const useRemoveModal = () => useContext(RemoveModalContext);
