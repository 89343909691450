import { useState } from "react";

const getFromStorage = (key: string) => {
  if (key) return JSON.parse(localStorage.getItem(key || "") || "{}");
};

const setToStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export function useMemoryState<T>(key: string, initialState: T) {
  const [state, setState] = useState(() => {
    const memoryState = getFromStorage(`${key}`);
    const hasMemoryValue = !!memoryState;
    if (hasMemoryValue) {
      return memoryState;
    } else {
      return initialState;
    }
  });

  function onChange(nextState: T) {
    setToStorage(`${key}`, nextState);
    setState(nextState);
  }

  return [state, onChange];
}
