import * as React from "react";
import { useState } from "react";
import { Button, Form, Input } from "antd";
import s from "./AddAziendaForm/AddAziendaForm.module.less";
import TextArea from "antd/es/input/TextArea";
import TooltipInput from "../TooltipInput/TooltipInput";
import { sendRequest } from "../../utils/sendRequest";
import {
  openNotification,
  openNotificationError,
} from "../../utils/openNotification";

interface IProps {}

const ContactForm = ({}: IProps) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (data: any) => {
    setLoading(true);
    const result = await sendRequest("contactForm", {
      ...data,
    });

    const parsedResult: any = JSON.parse(result);
    console.log({ result, parsedResult });

    if (parsedResult.statusCode === 500) {
      openNotificationError({
        title: "Oops",
        description: "Messaggio non inviato",
      });
    } else if (parsedResult.statusCode === 200) {
      openNotification({
        title: "Inviato",
        description: "Messaggio inviato con successo",
      });
    }

    setLoading(false);
  };

  const onFinishFailed = () => {};

  return (
    <Form
      layout={"vertical"}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className={s.formContainer}>
        <div className={"formRow"}>
          <div style={{ flex: 3.8 }}>
            <div className={"formRow"}>
              <Form.Item
                rules={[{ required: true, message: "Campo richiesto" }]}
                style={{ flex: 1 }}
                name="name"
              >
                <TooltipInput placeholder={"Nome e cognome"} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Email non valida",
                  },
                ]}
                style={{ flex: 1 }}
                name="email"
              >
                <TooltipInput placeholder={"Email"} />
              </Form.Item>
            </div>

            <div className={"formRow"}>
              <Form.Item
                style={{ flex: 1 }}
                name="body"
                rules={[{ required: true, message: "Campo richiesto" }]}
              >
                <TextArea rows={8} placeholder="Descrivi il problema" />
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            size={"large"}
            loading={loading}
            className={"ctaSolid"}
          >
            Invia
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default ContactForm;
