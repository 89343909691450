import * as React from "react";
import { AziendeCorso, Corso } from "../../API";
import classNames from "classnames";
import s from "../Forms/AddAziendaForm/AddAziendaForm.module.less";
import { DeleteOutlined } from "@ant-design/icons";
import useCachedQuery from "../../hooks/useCachedQuery";

interface IProps {
  corso: Corso | AziendeCorso;
  onRemoveCorso: (c: Corso) => void;
}

const CorsoCard = ({ corso, onRemoveCorso }: IProps) => {
  const { data } = useCachedQuery(
    "getCorso",
    `get-corso${(corso as any).corsoID || corso.id}`,
    { id: (corso as any).corsoID || corso.id },
    undefined,
    !!(corso as any).corsoID
  );

  const corsoFetched = (corso as any).corsoID ? data : corso;
  return (
    <div key={corso?.id} className={classNames("formRow", s.corsoCard)}>
      <p>{corsoFetched?.titolo}</p>
      <DeleteOutlined
        onClick={() => onRemoveCorso(corsoFetched)}
        className={s.icon}
      />
    </div>
  );
};

export default CorsoCard;
