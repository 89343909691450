import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button, Spin } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import s from "./PdfViewer.module.less";
import useSmallScreen from "../../hooks/useSmallScreen";
import useElementSize from "../../hooks/useElementSize";
interface IProps {
  url: string;
}

const PdfViewer = ({ url }: IProps) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const [divRef, { width, height }] = useElementSize();

  return (
    <div>
      <div className={s.container} ref={divRef}>
        <Document
          className={s.document}
          loading={<Spin className={s.spinner} />}
          file={{
            url,
          }}
          onLoadError={(err) => console.log(err)}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page width={width} pageNumber={pageNumber} />
        </Document>
      </div>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <Button
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
        icon={<ArrowLeftOutlined />}
      />
      <Button
        disabled={numPages === pageNumber}
        onClick={() => setPageNumber(pageNumber + 1)}
        icon={<ArrowRightOutlined />}
      />
      {/** <a href={url} target={"_blank"}>
        <Button icon={<FolderViewOutlined />} onClick={() => {}}>
          Open Pdf
        </Button>
      </a> **/}
    </div>
  );
};

export default PdfViewer;
