import * as React from "react";
import { useParams } from "react-router-dom";
import { Button, PageHeader } from "antd";
import BackIcon from "../../components/BackIcon/BackIcon";
import useAuthListener from "../../hooks/useAuthListener";
import { DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import AttivitaInterna from "./AttivitaInterna";
import AttivitaEsterna from "./AttivitaEsterna";
import classNames from "classnames";

interface IProps {}

const AttivitaUtente = ({}: IProps) => {
  const { editKey } = useParams();
  const { user } = useAuthListener();
  const [internal, setInternal] = useState(false);

  const extra = user?.isSuper
    ? [
        <Button
          className={classNames("tab", internal && "active")}
          size={"large"}
          onClick={() => setInternal(true)}
        >
          Interna
        </Button>,
        <Button
          className={classNames("tab", !internal && "active")}
          size={"large"}
          onClick={() => setInternal(false)}
        >
          Esterna
        </Button>,
      ]
    : {};

  return (
    <div>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Attività utente"
        backIcon={<BackIcon />}
        extra={extra}
      />
      {internal ? <AttivitaInterna /> : <AttivitaEsterna />}
    </div>
  );
};

export default AttivitaUtente;
