import { Button, Form, Input, Tooltip } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { sendCachedRequest, sendRequest } from "../../../utils/sendRequest";
import { phoneValidator } from "../../../utils/validators";
import { Azienda, AziendeCorso, Corso } from "../../../API";
import { useParams } from "react-router-dom";
import s from "./AddAziendaForm.module.less";
import BottomBar from "../../BottomBar/BottomBar";
import CorsoDropdown from "../../CorsoDropdown/CorsoDropdown";
import CorsoCard from "../../CorsoCard/CorsoCard";
import useCachedQuery from "../../../hooks/useCachedQuery";
import useQuery from "../../../hooks/useQuery";
import TooltipInput from "../../TooltipInput/TooltipInput";
import Radio from "../../Radio/Radio";
import ProvinciaDropdown from "../../ProvinciaDropdown/ProvinciaDropdown";
import classNames from "classnames";

interface IProps {
  initialValues?: Azienda;
  onAfterFinish?: () => void;
}
const AddAziendaForm = ({ initialValues, onAfterFinish }: IProps) => {
  const [loading, setLoading] = useState(false);
  const { editKey } = useParams();
  // current connections
  const initialCorsi: AziendeCorso[] =
    initialValues?.corsi?.items || ([] as any);
  const [corsi, setCorsi] = useState<Corso[]>([]);

  const [abilitato, setAbilitato] = useState("Attiva");

  useEffect(() => {
    if (initialValues)
      setAbilitato(initialValues?.disattiva ? "Non attiva" : "Attiva");
  }, [initialValues]);

  const allCorsi = useCachedQuery("listCorsos", "list-corsos");

  const veryInitialCorsi: Corso[] = React.useMemo(() => {
    if (!allCorsi?.data?.length || !initialCorsi?.length) {
      return [];
    }
    const toRet = allCorsi.data.filter((corso: Corso) => {
      return initialCorsi.find((item) => item?.corsoID === corso.id);
    });
    return toRet;
  }, [allCorsi, initialCorsi]);

  useEffect(() => {
    setCorsi(veryInitialCorsi);
  }, [JSON.stringify(veryInitialCorsi)]);

  const connectionsIdsToAdd = React.useMemo(() => {
    // all the corsi ID that are not in initialCorsi, but are in Corsi
    if (!veryInitialCorsi?.length) {
      return corsi.map((c) => c.id);
    }

    return corsi
      .filter((c) => !veryInitialCorsi.find((co: Corso) => co?.id === c.id))
      .map((c) => c.id);
  }, [corsi, veryInitialCorsi]);

  const connectionsIdsToRemove = React.useMemo(() => {
    // all the corsi ID that are in initialCorsi, but are not in Corsi
    if (!veryInitialCorsi?.length) {
      return [];
    }
    return veryInitialCorsi
      .filter((c: Corso) => !corsi.find((co) => co!.id === c!.id))
      .map((c: Corso) => c?.id);
  }, [corsi, veryInitialCorsi]);

  const createQuery = (corsoID: string, aziendaID?: string) => {
    return sendRequest(
      "createAziendeCorso",
      {
        input: {
          aziendaID: aziendaID || initialValues?.id,
          corsoID,
        },
      },
      true,
      true
    );
  };

  const createConnections = (aziendaID: string) => {
    if (connectionsIdsToAdd?.length > 0) {
      connectionsIdsToAdd.forEach(
        async (corsoID) => await createQuery(corsoID, aziendaID)
      );
    }
  };

  const updateConnections = () => {
    const removeQuery = (connId: string) => {
      return sendRequest(
        "deleteAziendeCorso",
        {
          input: {
            id: connId,
          },
        },
        true,
        true
      );
    };

    if (connectionsIdsToAdd?.length > 0) {
      connectionsIdsToAdd.forEach(
        async (corsoID) => await createQuery(corsoID)
      );
    }
    if (connectionsIdsToRemove?.length > 0) {
      connectionsIdsToRemove.forEach(async (corsoID) => {
        const connectionId = initialCorsi.find(
          (con) => con.corsoID === corsoID
        )?.id;
        console.log({ connectionId });
        await removeQuery(connectionId!);
      });
    }
  };

  const onFinish = async (data: any) => {
    setLoading(true);
    delete data.abilitato;

    if (!!editKey) {
      await sendRequest(
        "updateAzienda",
        {
          input: {
            id: editKey,
            disattiva: abilitato === "Attiva" ? false : true,
            ...data,
          },
        },
        true
      );
      await updateConnections();
    } else {
      const azienda = await sendRequest(
        "createAzienda",
        {
          input: {
            ...data,
            disattiva: abilitato === "Attiva" ? false : true,
          },
        },
        true
      );
      await createConnections(azienda.id);
    }

    setLoading(false);
    onAfterFinish?.();
  };

  const onFinishFailed = () => {};

  const onAddCorso = (c: string) => {
    const corso: Corso = JSON.parse(c);
    setCorsi([...(corsi || []).filter((c) => c.id !== corso.id), corso]);
  };

  const onRemoveCorso = (corso: Corso) => {
    console.log({ corso, corsi });
    setCorsi(corsi.filter((c) => c.id !== corso?.id));
  };

  return (
    <Form
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      layout={"vertical"}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className={classNames(s.formContainer)}>
        <div className={"formRow"}>
          <Form.Item
            style={{ flex: 2 }}
            name="ragioneSociale"
            rules={[{ required: true, message: "Campo richiesto" }]}
          >
            <TooltipInput placeholder={"Ragione Sociale"} />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name="partitaIva"
            rules={[{ required: true, message: "Campo richiesto" }]}
          >
            <TooltipInput placeholder="Partita Iva" />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name="visura"
            rules={[{ required: true, message: "Campo richiesto" }]}
          >
            <TooltipInput placeholder="Codice fiscale" />
          </Form.Item>
        </div>
        <div className={"formRow"}>
          <Form.Item
            name="indirizzo"
            style={{ flex: 1.2 }}
            rules={[{ required: false, message: "Campo richiesto" }]}
          >
            <TooltipInput placeholder={"Indirizzo"} />
          </Form.Item>
          <Form.Item
            name="citta"
            style={{ flex: 1.2 }}
            rules={[{ required: false, message: "Campo richiesto" }]}
          >
            <TooltipInput placeholder="Città" />
          </Form.Item>
          <ProvinciaDropdown />
          <Form.Item
            name="cap"
            style={{ flex: 0.8 }}
            rules={[{ required: false, message: "Campo richiesto" }]}
          >
            <TooltipInput placeholder="CAP" />
          </Form.Item>
        </div>
        <div className={"formRow"}>
          <Form.Item
            name="email"
            style={{ flex: 1, maxWidth: 550 }}
            rules={[
              { type: "email", required: false, message: "Campo richiesto" },
            ]}
          >
            <TooltipInput placeholder="E-mail" />
          </Form.Item>
          <Form.Item
            style={{ flex: 1, maxWidth: 550 }}
            name="telefono"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TooltipInput placeholder="Telefono" />
          </Form.Item>
        </div>
      </div>
      <Radio
        options={["Attiva", "Non attiva"]}
        value={abilitato}
        setValue={setAbilitato}
      />
      <div style={{ height: 100 }} />
      <h2>Associa Corsi</h2>
      <div className={"innerContentSm"}>
        <div className={"formRow"}>
          <div style={{ flex: 1 }}>
            <CorsoDropdown onChange={onAddCorso} />
          </div>
          <div style={{ flexDirection: "column", flex: 1 }}>
            {corsi.map((corso) => (
              <CorsoCard corso={corso} onRemoveCorso={onRemoveCorso} />
            ))}
          </div>
        </div>
      </div>
      <BottomBar>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button size={"large"} onClick={() => window.history.back()}>
            Annulla
          </Button>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            size={"large"}
            type={"primary"}
            loading={loading}
          >
            {editKey ? "Salva" : "Inserisci Azienda"}
          </Button>
        </Form.Item>
      </BottomBar>
    </Form>
  );
};

export default AddAziendaForm;
