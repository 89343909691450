import * as React from "react";
import { Button, DatePicker, Select, TimePicker } from "antd";
import { AttivitaItem, Corso } from "../../../API";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { MomentInput } from "moment";

interface IProps {
  corsi?: Corso[];
  attivita: AttivitaItem & { index: number };
  updateItem: (index: number, attivita: AttivitaItem) => void;
  onDelete?: (index: number) => void;
  error?: string;
}

const AttivitaFormInput4 = ({
  corsi,
  attivita,
  updateItem,
  onDelete,
  error,
}: IProps) => {
  moment.tz.setDefault("Europe/Rome");

  return (
    <div key={attivita.index}>
      <div style={{ color: "red" }}>{error}</div>
      <div style={{ alignItems: "center" }} className={"formRow"}>
        <Select
          showSearch={true}
          style={{ width: "100%" }}
          size={"large"}
          options={(corsi || []).map((item) => ({
            label: item.titolo,
            value: item.titolo,
          }))}
          value={attivita.attivita}
          onChange={(value) =>
            updateItem(attivita.index, { ...attivita, attivita: value })
          }
          placeholder="Seleziona corso"
        />
        <DatePicker
          disabled
          format={"DD/MM/YYYY"}
          value={attivita?.data ? moment(attivita?.data) : undefined}
          style={{ width: "100%" }}
          placeholder={"Data inizio"}
        />
        {onDelete && (
          <DeleteOutlined
            onClick={() => onDelete(attivita.index)}
            style={{ color: "white", width: 20, fontSize: 22 }}
          />
        )}
      </div>
    </div>
  );
};

export default AttivitaFormInput4;
