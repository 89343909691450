import * as React from "react";
import { PageHeader, Select } from "antd";
import BackIcon from "../../components/BackIcon/BackIcon";
import useCachedQuery from "../../hooks/useCachedQuery";
import useQuery from "../../hooks/useQuery";
import { Azienda, Corso, Utente, UtenteAttivita } from "../../API";
import { useEffect, useState } from "react";
import AttivitaForm2 from "../../components/Forms/AttivitaForm/AttivitaForm2";

const AddAttivita2 = ({}) => {
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [azienda, setAzienda] = useState<string | undefined>(undefined);
  const { data } = useCachedQuery<Utente[]>("listUtentes", `get-utentes`);
  const attività = useQuery<UtenteAttivita[]>("utenteAttivitaByUserId", {
    userId: username,
  });
  const corsi = useQuery<Corso[]>("listCorsos")?.data;
  const aziende = useQuery("listAziendas")?.data;

  useEffect(() => {
    attività.refetch();
  }, [username]);

  const currentUtente: Utente = data?.find(
    (item) => item.username === username
  )!;

  const attivitaUtente = attività.data?.[0];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={"Inserisci attività utente"}
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
      />
      <Select
        style={{ marginRight: 25 }}
        showSearch
        size={"large"}
        placeholder="Seleziona un azienda"
        optionFilterProp="children"
        onChange={(value) => {
          setAzienda(value);
        }}
        filterOption={(input, option) => {
          return (
            (option?.label ?? "")
              //@ts-ignore
              .toLowerCase?.()
              .includes(input.toLowerCase())
          );
        }}
      >
        {(aziende || [])
          .sort((a: Azienda, b: Azienda) =>
            a.ragioneSociale?.localeCompare(b?.ragioneSociale || "")
          )
          .map((azienda: Azienda) => (
            <Select.Option key={azienda.id} value={azienda.id}>
              {azienda.ragioneSociale}
            </Select.Option>
          ))}
      </Select>
      <Select
        showSearch
        size={"large"}
        placeholder="Seleziona un utente"
        optionFilterProp="children"
        onChange={(value) => {
          setUsername(value);
        }}
        filterOption={(input, option) => {
          return (
            (option?.children ?? "")
              //@ts-ignore
              .toLowerCase?.()
              .includes(input.toLowerCase())
          );
        }}
      >
        {(azienda
          ? (data || []).filter((item) => item.aziendaUsersId === azienda)
          : data || []
        )
          .sort((a, b) => a.username?.localeCompare(b.username))
          .map((utente: Utente) => (
            <Select.Option key={utente.username} value={utente.username}>
              {utente.nome || utente.username}
            </Select.Option>
          ))}
      </Select>
      {attività.isLoading ? (
        <div>Loading...</div>
      ) : (
        <AttivitaForm2
          refetch={attività.refetch}
          userId={username}
          initialValues={attivitaUtente as any}
          corsi={corsi}
        />
      )}
    </div>
  );
};

export default AddAttivita2;
