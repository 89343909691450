import { Layout } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { AppHeader } from "./components/AppHeader";
import { Sidebar } from "../SideBar";
import React from "react";
import classNames from "classnames";
import useAuthListener from "../../hooks/useAuthListener";
import TabBar from "../TabBar/TabBar";

const AppLayout: React.FC = ({ children }) => {
  const { role } = useAuthListener();
  return (
    <div>
      <Sidebar />
      <Layout
        className={classNames("site-layout", role === "utente" && "utente")}
        style={{
          background: "@darkest",
          minHeight: "100vh",
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
            position: "relative",
          }}
        >
          {children}
        </Content>
      </Layout>
      {role === "utente" && <TabBar />}
    </div>
  );
};

export default AppLayout;
