import * as React from "react";
import s from "./BottomBar.module.less";

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  absolute?: boolean;
}

const BottomBar = ({ children, absolute }: IProps) => {
  return (
    <div
      className={s.bar}
      style={{ position: absolute ? "absolute" : "sticky" }}
    >
      {children}
    </div>
  );
};

export default BottomBar;
