import { API, Cache } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import awsconfig from "../aws-exports";
import { openNotification, openNotificationError } from "./openNotification";
import * as Sentry from "@sentry/react";

export const sendRequest = async (
  query: string,
  variables?: any,
  mutation?: boolean,
  disableMessage?: boolean,
  onError?: (e: any) => void,
  message?: string
) => {
  try {
    const response: any = await API.graphql({
      // @ts-ignore
      query: mutation ? mutations[query] : queries[query],
      variables,
      authToken: awsconfig.aws_appsync_apiKey,
    });
    message
      ? openNotification({
          description: "Operazione eseguita con successo!",
          title: message,
        })
      : mutation &&
        !disableMessage &&
        openNotification({
          description: "Operazione eseguita con successo!",
          title: "Operazione eseguita",
        });
    return response.data?.[query]?.items || response.data?.[query];
  } catch (e) {
    mutation ||
      (message &&
        openNotificationError({
          description: "Errore con l'operazione",
          title: "Oops",
        }));
    // @ts-ignore
    Sentry.captureException(e?.errors?.[0]);
    onError?.(e);
    console.log("ERRORRRR", e);
  }
};

export const sendCachedRequest = async (
  query: string,
  cacheKey: string,
  variables?: any,
  mutation?: boolean,
  onError?: (e: any) => void
) => {
  try {
    const response: any = await API.graphql({
      // @ts-ignore
      query: mutation ? mutations[query] : queries[query],
      variables,
      authToken: awsconfig.aws_appsync_apiKey,
    });
    mutation &&
      openNotification({
        description: "Operazione eseguita con successo!",
        title: "Operazione eseguita",
      });
    const result = response.data?.[query]?.items || response.data?.[query];
    Cache.setItem(cacheKey, result);
    return result;
  } catch (e) {
    mutation &&
      openNotificationError({
        description: "Errore con l'operazione",
        title: "Oops",
      });
    // @ts-ignore
    Sentry.captureException(e?.errors?.[0]);
    onError?.(e);
    console.log("ERRORRRR", e);
  }
};

export const sendMutation = async (
  query: string,
  variables?: any,
  disableMessage?: boolean,
  disableErrorMessage?: boolean,
  onError?: (e: any) => void
) => {
  const mutation = true;
  try {
    const response: any = await API.graphql({
      // @ts-ignore
      query: mutation ? mutations[query] : queries[query],
      variables,
      authToken: awsconfig.aws_appsync_apiKey,
    });
    mutation &&
      !disableMessage &&
      openNotification({
        description: "Operazione eseguita con successo!",
        title: "Operazione eseguita",
      });
    return response.data?.[query]?.items || response.data?.[query];
  } catch (e) {
    mutation &&
      !disableErrorMessage &&
      openNotificationError({
        description: "Errore con l'operazione",
        title: "Oops",
      });
    // @ts-ignore
    Sentry.captureException(e?.errors?.[0]);
    onError?.(e);
    console.log("ERRORRRR", e);
  }
};
