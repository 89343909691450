import * as React from "react";
import { Button, PageHeader, Select } from "antd";
import Search from "antd/es/input/Search";
import useCachedQuery from "../../hooks/useCachedQuery";
import { useParams } from "react-router-dom";
import {
  Azienda,
  AziendeCorso,
  ModelUtenteCorsoFilterInput,
  Utente,
  UtenteCorso,
} from "../../API";
import { useEffect, useState } from "react";
import { sendRequest } from "../../utils/sendRequest";
import AssociazioniTable from "../../components/Tables/AssociazioniTable";
import { useRemoveModal } from "../../utils/RemoveModalContext";
import { Simulate } from "react-dom/test-utils";
import BackIcon from "../../components/BackIcon/BackIcon";

interface IProps {}

const AssociaCorsi = ({}: IProps) => {
  const [filter, setFilter] = useState("");

  const { editKey } = useParams();
  const { openModal, setConfirmLoading, closeModal } = useRemoveModal();
  const utentiRequest = useCachedQuery<Utente[]>(
    "listUtentes",
    `list-utentes-${editKey}`,
    {
      limit: 9999,
      filter: {
        aziendaUsersId: { eq: editKey },

      },
    }
  );

  const aziendeCorso = useCachedQuery<AziendeCorso[]>(
    "listAziendeCorsos",
    `list-aziende-corsos-${editKey}`,
    {
      limit: 9999,
      filter: {
        aziendaID: { eq: editKey },
      },
    }
  );

  const utenteCorsoFilters: ModelUtenteCorsoFilterInput = React.useMemo(() => {
    if (!utentiRequest.data?.length) {
      return {};
    }
    let toRet = {
      or: [],
    };
    utentiRequest?.data.forEach((item, index) => {
      // @ts-ignore
      toRet.or.push({ utenteID: { eq: item.username } });
    });
    return toRet;
  }, [utentiRequest]);

  const utenteCorso = useCachedQuery<UtenteCorso[]>(
    "listUtenteCorsos",
    `list-utente-corsos-${editKey}`,
    {
      filter: utenteCorsoFilters,
      limit: 9999,
    },
    undefined,
    (utenteCorsoFilters?.or?.length || 0) > 0
  );

  useEffect(() => {
    if ((utenteCorsoFilters.or?.length || 0) > 0) {
      utenteCorso.refetch();
    }
  }, [JSON.stringify(utenteCorsoFilters)]);
  const utenti = (utentiRequest?.data || []).map((utente: Utente) => ({
    label: utente.username,
    value: utente.username,
  }));
  const corsi = (aziendeCorso?.data || []).map((item: AziendeCorso) => ({
    value: item.corso.id,
    label: item.corso.titolo,
  }));

  const utenteCorsosData = React.useMemo(() => {
    if (!utenteCorso?.data?.length) {
      return [];
    }
    return utenteCorso.data.filter((item: UtenteCorso) => {
      return (corsi || []).find((c: any) => c.value === item.corsoID);
    });
  }, [utenteCorso, corsi]);

  const [selectedUtenti, setSelectedUtenti] = useState<string[]>([]);
  const [selectedCorso, setSelectedCorso] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const onCreateAssociazione = async () => {
    setLoading(true);
    for (let i = 0; i < selectedUtenti.length; i++) {
      const existsAlready = utenteCorso?.data?.some(
        (item) =>
          item.corsoID === selectedCorso && item.utenteID === selectedUtenti[i]
      );
      if (!existsAlready)
        await sendRequest(
          "createUtenteCorso",
          {
            input: {
              utenteID: selectedUtenti[i],
              corsoID: selectedCorso,
            },
          },
          true,
          i != 0
        );
    }
    utenteCorso.refetch();
    setLoading(false);
  };

  const onDeleteAssociazione = async (id: string) => {
    setConfirmLoading(true);
    await sendRequest(
      "deleteUtenteCorso",
      {
        input: {
          id,
        },
      },
      true
    );
    utenteCorso.refetch();
    setConfirmLoading(false);
    closeModal();
  };

  const filteredData = React.useMemo(() => {
    if (filter?.length === 0 || !filter) {
      return utenteCorsosData;
    }
    return (utenteCorsosData || []).filter((item: UtenteCorso) => {
      return (
        item.utente.nome
          ?.toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()) ||
        item.corso.titolo
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase())
      );
    });
  }, [filter, utenteCorsosData]);

  return (
    <div>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Associa corsi"
        extra={
          <Search
            placeholder={"Cerca Utente/Corso"}
            onChange={(a: any) => {
              setFilter(a.target.value);
            }}
          />
        }
        backIcon={<BackIcon />}
      />
      <div className={"formRow"}>
        <Select
          mode="multiple"
          allowClear
          showArrow={true}
          style={{ width: "100%" }}
          size={"large"}
          options={utenti}
          value={selectedUtenti}
          onChange={setSelectedUtenti}
          placeholder="Seleziona dipendente"
        />
        <Select
          showSearch={true}
          style={{ width: "100%" }}
          size={"large"}
          options={corsi}
          value={selectedCorso}
          onChange={setSelectedCorso}
          placeholder="Seleziona corso"
        />
        <Button
          onClick={onCreateAssociazione}
          disabled={!selectedCorso.length || !selectedUtenti?.length}
          size={"large"}
          loading={loading}
          style={{ minWidth: 200, borderRadius: 2 }}
          className={"ctaSolid"}
        >
          ASSOCIA
        </Button>
      </div>
      <AssociazioniTable
        deleteAssociazione={(v) => {
          openModal(() => onDeleteAssociazione(v.id), "Associazione");
        }}
        isLoading={utenteCorso.isLoading}
        data={filteredData}
      />
    </div>
  );
};

export default AssociaCorsi;
