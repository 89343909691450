import * as React from "react";
import { Button, PageHeader, Select } from "antd";
import useCachedQuery from "../../hooks/useCachedQuery";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackIcon from "../../components/BackIcon/BackIcon";
import ViewLezioniTable from "../../components/Tables/ViewLezioniTable";
import { Corso, Lezione } from "../../API";
import useAllVideoProgress from "../../hooks/useAllVideoProgress";
import useAuthListener from "../../hooks/useAuthListener";

interface IProps {}

const AziendeUtenti = ({}: IProps) => {
  const { editKey } = useParams();

  const { data, isLoading } = useCachedQuery<Corso>(
    "getCorso",
    `get-corso-${editKey}`,
    {
      id: editKey,
    }
  );

  const lezioni: Lezione[] = data?.lezioni?.items as any;
  const titolo = data?.titolo;

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={titolo}
        onBack={() => window.history.back()}
        backIcon={<BackIcon />}
      />

      <ViewLezioniTable
        data={(lezioni || []).sort(
          (a, b) =>
            Number(a.index.split("-")[1]) - Number(b.index.split("-")[1])
        )}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AziendeUtenti;
